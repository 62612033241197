import React, { useState, useEffect } from 'react';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { IoMdArrowRoundBack } from "react-icons/io"
import { useDispatch, useSelector } from "react-redux";
import { onGetClient } from "../../Actions/ClientsAction";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { onFacebookCreate, onFacebookFetch } from "../../Actions/ResourceAction";
import AddSetup from './AddSetup';
import ModalSet from './ModalSet';


let type
const FacebookPage = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const aId = queryString.parse(location.search).aId
    const cId = queryString.parse(location.search).cId
    const clientData = useSelector(state => state.client.data)

    const [facebookAccount, setFacebookAccount] = useState([])
    const [businessManager, setBusinessManager] = useState([])
    const [customeAudience, setCustomeAudience] = useState([])
    const [facebookPage, setFacebookPage] = useState([])
    const [adAccount, setAdAccount] = useState([])
    const [adCampaign, setAdCampaign] = useState([])
    const [adSets, setAdSets] = useState([])
    const [imageData, setImageData] = useState("")

    const [activeTab, setActiveTab] = useState(false)
    const [state, setState] = useState({
        agencyId: aId,
        clientId: cId,
        facebookAccountId: false,
        facebookPageId: false,
        businessManagerId: false,
        adAccountId: false,
        adCampaignId: false,
        adSetsId: false
    })

    const [loader, setLoader] = useState({
        pageLoader: true,
        submitLoader: false,
        modalSubmit: false
    })

    const [showCampaign, setShowCampaign] = useState(false);
    const handleCloseCampaign = () => setShowCampaign(false);
    const handleShowCampaign = () => setShowCampaign(true);

    const [newCampaignName, setNewCampaignName] = useState(false);

    const handleCreateCampaignFormSubmit = () => {
        const newData = {
            name: newCampaignName,
            facebookAccountId: state.facebookAccountId,
            adAccountId: state.adAccountId.split("act_")[1],
            agencyId: state.agencyId,
            clientId: state.clientId
        }
        setLoader({
            ...loader,
            modalSubmit: true
        })
        dispatch(onFacebookCreate(newData, 'facebook-create-campaign', setAdCampaign, loader, setLoader))
    }


    const handleBackBtn = () => {
        if (aId && cId) {
            navigate(`/agency/create-client?id=${aId}&cId=${cId}`)
        }
    }

    const handleChange = (e) => {
        let { name, value } = e.target

        if ((name === "adCampaignId" || name === "adSetsId") && value === "open") {
            type = name
            handleShowCampaign()
        }
        else if (name === "adAccountId") {
            value = value.split("act_")[1]
            setState({
                ...state,
                [name]: value
            })
        }
        else {
            setState({
                ...state,
                [name]: value
            })
        }
    }

    const getClientData = () => {
        let data = {
            "id": cId,
            "agencyId": aId
        }
        setLoader({
            ...loader,
            pageLoader: true
        })
        dispatch(onGetClient(data, loader, setLoader))
    }
    useEffect(() => {
        if (aId && cId) {
            getClientData()
        }
    }, [aId, cId])

    useEffect(() => {
        // Run to fetch Facebook Business Manager, Ad Account, Ad Campaigns, Ad sets
        dispatch(onFacebookFetch(state, 'facebook-account-fetch', setFacebookAccount))
    }, [])

    useEffect(() => {
        if (state.facebookAccountId) {
            dispatch(onFacebookFetch(state, 'facebook-page-fetch', setFacebookPage))
        }
    }, [state.facebookAccountId])

    useEffect(() => {
        if (state.facebookAccountId) {
            dispatch(onFacebookFetch(state, 'facebook-business-manager-fetch', setBusinessManager))
        }
    }, [state.facebookAccountId])
    //
    useEffect(() => {
        if (state.businessManagerId) {
            dispatch(onFacebookFetch(state, 'facebook-ad-account-fetch', setAdAccount))
        }

    }, [state.businessManagerId])
    //
    useEffect(() => {
        if (state.adAccountId) {
            let data = {
                agencyId: aId,
                clientId: cId,
                facebookAccountId: state.facebookAccountId,
                adAccountId: state.adAccountId
            }
            dispatch(onFacebookFetch(data, 'facebook-fetch-custom-audience', setCustomeAudience, true))
            dispatch(onFacebookFetch(state, 'facebook-ad-campaign-fetch', setAdCampaign))
        }
    }, [state.adAccountId])
    //
    useEffect(() => {
        if (state.adCampaignId) {
            dispatch(onFacebookFetch(state, 'facebook-ad-sets-fetch', setAdSets))
        }
    }, [state.adCampaignId])



    return (
        <>
            <div className="container">
                <div className="business-wrap">

                    <div className="business-top">
                        <h2>Create Facebook Ads</h2>
                        <button className="demoLink" onClick={handleBackBtn}> <IoMdArrowRoundBack /> Go Back</button>
                    </div>

                    <div className="business-list">
                        <div className="proImage withDesc">
                            <div className="proImage-holder">
                                <img src={clientData.image} alt="" />
                            </div>
                            <div className="proImage-txt">
                                <p><strong>{clientData.name}</strong></p>
                                <p>{clientData.email}</p>
                                <p>{clientData.company}</p>
                            </div>
                        </div>
                    </div>

                    <div className="business-list">
                        <Tab.Container id="left-tabs-example">
                            <Nav variant="socialTabs">
                                <Nav.Item onClick={() => setActiveTab(false)}>
                                    <Nav.Link className={activeTab ? "" : 'active'}>Add Settings</Nav.Link>
                                </Nav.Item>
                                <Nav.Item onClick={() => setActiveTab(true)}>
                                    <Nav.Link className={activeTab ? "active" : ''}>Add Setup</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane className={activeTab ? '' : "show active"}>
                                    <div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="inpLabelWrap">
                                                    <span className="labelTxt">Facebook Account</span>
                                                    <div className="inpLabel">
                                                        <label htmlFor="">Select Your Facebook Account</label>
                                                        <select className="inpLabel-inp" name="facebookAccountId" onChange={handleChange}>
                                                            <option>Select Account</option>
                                                            {facebookAccount.map(facebook => <option value={facebook.id}>{facebook.name}</option>)}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-12">
                                                <div className="inpLabelWrap">
                                                    <span className="labelTxt">Facebook Page</span>
                                                    <div className="inpLabel">
                                                        <label htmlFor="">Select Your Facebook Page</label>
                                                        <select className="inpLabel-inp" name="facebookPageId" onChange={handleChange}>
                                                            <option value={""}>Select Account</option>
                                                            {facebookPage.map(facebook => <option value={facebook.id}>{facebook.name}</option>)}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-12">
                                                <div className="inpLabelWrap">
                                                    <span className="labelTxt">Facebook Business Manager</span>
                                                    <div className="inpLabel">
                                                        <label htmlFor="">Select Your Facebook Business Manager</label>
                                                        <select className="inpLabel-inp" name="businessManagerId" onChange={handleChange}>
                                                            <option>Select Account</option>
                                                            {businessManager.map(manager => <option value={manager.id}>{manager.name}</option>)}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="inpLabelWrap">
                                                    <span className="labelTxt">Facebook Ad Account</span>
                                                    <div className="inpLabel">
                                                        <label htmlFor="">Select Your Facebook Ad Account</label>
                                                        <select className="inpLabel-inp" name="adAccountId" onChange={handleChange}>
                                                            <option value={""}>Select Account</option>
                                                            {adAccount.map(account => <option value={account.id}>{account.name}</option>)}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-6">
                                                <div className="inpLabelWrap">
                                                    <span className="labelTxt">Facebook Ad Campaign</span>
                                                    <div className="inpLabel">
                                                        <label htmlFor="">Select Your Facebook Ad Campaign</label>
                                                        <select className="inpLabel-inp" name="adCampaignId" onChange={handleChange}>
                                                            <option value={""}>Select</option>
                                                            {adCampaign.map(campaign => <option value={campaign.id}>{campaign.name}</option>)}
                                                            <option value={"open"}>Create New</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-lg-6">
                                                <div className="inpLabelWrap">
                                                    <span className="labelTxt">Facebook Ad Sets</span>
                                                    <div className="inpLabel">
                                                        <label htmlFor="">Select Your Facebook Ad Sets</label>
                                                        <select className="inpLabel-inp" name="adSetsId" onChange={handleChange}>
                                                            <option value={""}>Select</option>
                                                            {adSets.map(adSet => <option value={adSet.id} selected={state.adSetsId === adSet.id}>{adSet.name}</option>)}
                                                            <option value={"open"} >Create New</option>

                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="btn-sec text-right">
                                            <button className="demoLink" onClick={() => setActiveTab(true)}>Next</button>
                                        </div>
                                    </div>
                                </Tab.Pane>

                                <Tab.Pane className={activeTab ? 'show active' : ""}>
                                    <AddSetup
                                        facebookAccountId={state.facebookAccountId}
                                        adAccountId={state.adAccountId}
                                        adSetId={state.adSetsId}
                                        pageId={state.facebookPageId}
                                    />
                                </Tab.Pane>

                            </Tab.Content>
                        </Tab.Container>
                    </div>

                </div>
            </div>

            <ModalSet
                type={type}
                show={showCampaign}
                handleClose={handleCloseCampaign}
                handleSubmit={handleCreateCampaignFormSubmit}
                setNewCampaignName={setNewCampaignName}
                aId={aId}
                cId={cId}
                campaignId={state.adCampaignId}
                acId={state.facebookAccountId}
                adAccountId={state.adAccountId}
                loader={loader}
                state={state}
                setState={setState}
                customeAudience={customeAudience}
                setAdSets={setAdSets}
                adSets={adSets}
            />


            {/* <Modal className="VideoModal small" show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <h4>Create Campaign</h4>
                    <form>
                        <div onClick={handleClose} className="vidClose"><img src={modalCloseIcon} alt="" /></div>
                        <div className="formSec">

                            <div className="inpLabelWrap mt-0">
                                <div className="inpLabel">
                                    <label>Campaign Name</label>
                                    <input className="inpLabel-inp" placeholder="Campaign Name Here" type="text" />
                                </div>
                            </div>
                            <div className="inpLabelWrap">
                                <div className="inpLabel">
                                    <label>Daily Budget Amount(USD) </label>
                                    <input className="inpLabel-inp" placeholder="Enter Budget Amount" type="text" />
                                </div>
                            </div>
                            <div className="inpLabelWrap">
                                <div className="inpLabel">
                                    <label>GEO Targeting Type</label>
                                    <select className="inpLabel-inp" name="" id="">
                                        <option value="">Select GEO Targeting Type</option>
                                    </select>
                                </div>
                            </div>

                            <div className="inpLabelWrap">
                                <div className="inpLabel">
                                    <label>Frequency Cap</label>
                                    <input className="inpLabel-inp" placeholder="Enter Number" type="text" />
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div className='inpLabelWrap'>
                                        <div className='d-flex'>
                                            <label className='switch flex' htmlFor="">
                                                <input type="checkbox" />
                                                <span className='slider round'></span>
                                                <span className='slOpen2'>All Countries and Territories</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='inpLabelWrap'>
                                        <div className='d-flex'>
                                            <label className='switch flex' htmlFor="">
                                                <input type="checkbox" />
                                                <span className='slider round'></span>
                                                <span className='slOpen2'>Enter Another Location</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="inpLabelWrap">
                                <div className="inpLabel">
                                    <label>Language(Optional)</label>
                                    <select className="inpLabel-inp" name="" id="">
                                        <option value="">Select Language</option>
                                    </select>
                                </div>
                            </div>


                            <button type="submit" className="btn-block inpBtn mt-3 create-modal-btn">
                                Crreate
                            </button>
                        </div>
                    </form>

                </Modal.Body>
            </Modal> */}

        </>
    )
}

export default FacebookPage;