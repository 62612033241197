import React,{useState,useEffect} from 'react'
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const Pagination = ({listData,pagination,setPagination,currentTodo,loader,listArr}) => {
    const [pageNumber,setPageNumber] = useState([])

    const handleClick = (num) => {
        setPagination({
          ...pagination,
          currentPage: num,
        });
      };
      const handleBackward = (num) => {
        if (pageNumber[0] < num) {
          setPagination({
            ...pagination,
            currentPage: num - 1,
          });
        }
      };
      const handleForward = (num) => {
        if (pageNumber[pageNumber.length - 1] > num) {
          setPagination({
            ...pagination,
            currentPage: num + 1,
          });
        }
      };
    useEffect(()=>{
        if(listData.length>0){
            let maxLength = listData.length;
            let data=[];
            if (maxLength > 10) {
                let val = maxLength / 10;
                if (val > parseInt(val)) {
                  val = val + 1;
                }
                for (let i = 1; i <= val; i++) {
                  data.push(i);
                }
                setPageNumber(data);
              } else {
                setPageNumber([1]);
              }
        }
    },[listData])
  

  return (
    <>
     {loader === true ? (
           ""
          ) : listArr.length === 0 ? (
            ""
          ) : currentTodo.length !== 0 ? (
            ""
         ) : (
            <div className="text-center mt-4 text-capitalize" >
              "No Data Found"
            </div>
          )}
    <div className="tablepage">
    <ul>
      <li
        onClick={() => handleBackward(pagination.currentPage)}
        className={pageNumber.length<=1?"not-allowed":"cursor-pointer"}
      >
        <a>
          <FaChevronLeft style={{ color: pageNumber.length<=1?"gray":"white" }} />
        </a>
      </li>

      {pageNumber.length > 0
        ? pageNumber.map((curElem, index) => {
            return (
              <li
                key={index}
                id={curElem}
                style={{ color: "white" }}
                onClick={() => handleClick(curElem)}
                className="cursor-pointer"
              >
                <a
                  style={
                    curElem === pagination.currentPage
                      ? {
                          backgroundColor: "white",
                          color: "#400C46",
                        }
                      : {}
                  }
                >
                  {curElem}
                </a>
              </li>
            );
          })
        : ""}
      <li
        onClick={() => handleForward(pagination.currentPage)}
        className={pageNumber.length<=1?"not-allowed":"cursor-pointer"}
      >
        <a>
          <FaChevronRight style={{color: pageNumber.length<=1?"gray":"white" }} />
        </a>
      </li>
    </ul>
  </div></>
  )
}

export default Pagination