import React,{useState} from 'react'

const ClientTableHeader = ({listData,setListData}) => {
    const [state,setState] = useState({
        name:"",
    })

    const handleSort=(type)=>{
        let data=[...listData]
        if (type === "name") {
            if (state.name) {
              data.sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                  return 1;
                } else {
                  return -1;
                }
              });
              setState({
                ...state,
                name: false,
              });
            } else {
              data.sort((a, b) => {
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                  return 1;
                } else {
                  return -1;
                }
              });
              setState({
                ...state,
                name: true,
              });
            }
          }
        setListData(data)
    }
    return (
        <tr>
            <th width="150"></th>
            <th className='cursor-pointer'onClick={()=>handleSort("name")}>Name</th>
            <th className='cursor-pointer'>Email</th>
            <th className='cursor-pointer'>Agency</th>
            <th className='cursor-pointer'>Mode</th>
            <th></th>
            <th></th>
        </tr>
    )
}

export default ClientTableHeader