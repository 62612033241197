import { commonAxios } from "../Global/CommonAxios"
import { setAlert } from "./AlertAction"

export const onFetchAgencyList = (setState, setLoader) => (dispatch, getState) => {
    commonAxios("list-agency", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data)
            } else {
                setState([])
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
        })
}

export const onCreateAgency = (data, setLoader, navigate) => (dispatch, getState) => {
    commonAxios("create-agency", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigate(`/agency/create-agency?id=${res.data}`)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
            setLoader(false)
        })
}

export const onDeleteAg = (data, fetchData, Swal) => (dispatch, getState) => {
    commonAxios("delete-agency", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchData()
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            Swal.close()
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            Swal.close()
        })
}

export const onGetAgencyData = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("view-agency", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "SET_AGENCY_DATA", payload: res.data[0] })
            } else {
                // dispatch(setAlert(res.msg, "danger"))
            }
            if (loader) {
                setLoader({
                    ...loader,
                    pageLoader: false
                })
            }
        }).catch((err) => {
            console.log(err)
            if (loader) {
                setLoader({
                    ...loader,
                    pageLoader: false
                })
            }
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onFetchIntegrations = (data) => (dispatch, getState) => {
    commonAxios("autoresponder-list", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "SET_INTEGRATION_LIST", payload: res.data })
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onUploadImage = (actionType, data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("upload-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: actionType, payload: res.data.path })
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                imageLoader: false
            })
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                imageLoader: false
            })
            console.log(err)
        })
}

export const onSubmitAgency = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("update-agency", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                submitLoader: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                submitLoader: false
            })
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onDuplicateAgency = (data, fetchAgencyData, setLoader) => (dispatch, getState) => {
    commonAxios("duplicate-agency", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                fetchAgencyData()
            }
            else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
        })
}

export const saveFacebook = (data, fetchIntegration) => (dispatch, getState) => {
    const body = JSON.stringify(data);
    commonAxios("save-facebook", body, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                fetchIntegration()
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"));
            console.log(err);
        })
}

export const saveGoogle = (data, fetchIntegration) => (dispatch, getState) => {
    commonAxios("save-youtube", data, dispatch, getState().auth.token )
    .then((res) => {
        if (res.status) {
            dispatch(setAlert(res.msg, "success"));
            fetchIntegration()
        } else {
            dispatch(setAlert(res.msg, "danger"));
        }
    }).catch((err) => {
        dispatch(setAlert(err.msg, "danger"));
        console.log(err);
    })
}

//Dashboard axios

export const fetchDashboard = (data, state, setState, setLoader) => (dispatch, getState) => {
    commonAxios("revenue-client", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState({
                    ...state,
                    revenue: res.data.totalEarnings,
                    client: res.data.totalClients
                })

            } else {
                setState({
                    ...state,
                    revenue: 0,
                    client: 0
                })
            }
            setLoader(false)

        })
        .catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader(false)
        })
}

//No Axios Here
export const onChangeAgency = (name, value, checked) => (dispatch) => {
    let data = { name, value }
    if (name === "mode") {
        if (checked) {
            data.value = "1"
        } else {
            data.value = "0"
        }

    }
    dispatch({ type: "ON_AGENCY_CHANGE", payload: data })
}