import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import videoThumb from '../../images/fav-new.png';
import modalCloseIcon from '../../images/modal-close.png';

const HelpVideos = () => {

    const supportData = useSelector(state => state.support);

    const [videosData, setVideosDara] = useState(supportData.videos);
    const [url, setUrl] = useState('');
    const [showModal, setShowModal] = useState(false);

    const playVideo = (url) => {
        setUrl(url);
        setShowModal(true);
    }

    const closeModal = () => {
        setUrl('');
        setShowModal(false);
    }

    useEffect(() => {
        setVideosDara(supportData.videos)
    }, [supportData.videos])

    return (
        <>
            <section className="popularVideo">
                <div className="container">
                    <div className="text-center">
                        <h2>Popular Video Tutorials</h2>
                    </div>
                    <div className="row">
                        {
                            videosData.length > 0 ?
                                videosData.map((video, index) => {
                                    return (
                                        <div className="col-xl-3 col-lg-4 col-sm-6" key={index}
                                            style={{ cursor: "pointer" }}
                                            onClick={(e) => playVideo(video.url)}>
                                            <div className="popularVideo-single">
                                                <div className="popularVideo-vid video-card-wrapper-1 dashboard-card-bg">
                                                    <img src={videoThumb} className="mb-3 vidThumb-img  img-fluid" alt="" style={{objectFit : "contain"}} />
                                                    {/* <img src={video.thumbnail} /> */}
                                                </div>
                                                <div className="popularVideo-txt">
                                                    <span>{video.title}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                : ''
                        }
                        {
                            videosData.length === 0 ?
                                <div className="col-12">
                                    <h4 className="text-center load-icon-help"><i className="fa fa-spinner fa-spin mr-2" style={{color : "#d64242"}} /></h4>
                                </div>
                                : ''
                        }
                    </div>
                </div>
            </section>

            <div className={`modal VideoModal ${showModal ? 'show-modal' : ''}`} id="myModal" aria-labelledby="myModalLabel" aria-hidden="true" tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div onClick={(e) => closeModal()} className="vidClose"><img src={modalCloseIcon} alt=""/></div>
                        <div className="modal-body">
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe id="frameSrc" className="embed-responsive-item" src={url} allowFullScreen="" title="help-videos"></iframe>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default HelpVideos;