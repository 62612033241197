import React, { useState } from 'react'
import { BsFillPlusCircleFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { onAddBenefit, onCancleBenefit, onChangeBenefit } from '../../Actions/ClientsAction';
import BenefitRows from './BenefitRows';

const BenifitSection = () => {
    const dispatch = useDispatch()
    const landingData = useSelector(state => state.client.landing)

    const handleChange = (e, index) => {
        const { name, value } = e.target
        dispatch(onChangeBenefit(name, value, index))
    }

    const addBenefit = () => {
        dispatch(onAddBenefit())
    }

    return (
        <div>
            <div className="inpLabelWrap">
                <span className="labelTxt">Benefit Heading</span>
                <div className="inpLabel">
                    <label>Benefit Heading</label>
                    <input
                        className="inpLabel-inp"
                        placeholder="Benefit Heading"
                        type="text"
                        name='heading'
                        value={landingData.benefits.heading}
                        onChange={(e) => handleChange(e)}
                    />
                </div>
            </div>

            <div className="benefit-all">
                <div className="row">
                    {landingData.benefits.benefits.length > 0 ?
                        landingData.benefits.benefits.map((curElem, index) => {
                            return (
                                <BenefitRows
                                    curElem={curElem}
                                    index={index}
                                    handleChange={handleChange}
                                />
                            )
                        }) : ""}
                </div>
            </div>

            <div className="modBox mt-3" style={{ cursor: 'pointer' }}>
                <div className="inpLabel mt-0 align-items-center d-flex justify-content-between">
                    <label>Add More Benefit</label>
                    <span className="addIcon" onClick={addBenefit}><BsFillPlusCircleFill /></span>
                </div>
            </div>


        </div >
    )
}

export default BenifitSection