import styled from "styled-components"
import invoice2 from "../../../images/invoice3_bg.png"
export const Invoice7style = styled.div`
.table {
    color: #535353;
}

.invoice-content {
    font-family: 'Poppins', sans-serif;
    color: #535353;
    font-size: 14px;
}

.invoice-content a {
    text-decoration: none;
}

.invoice-content .img-fluid {
    max-width: 100% !important;
    height: auto;
}

.invoice-content .form-control:focus {
    box-shadow: none;
}


.mb-0 {
    margin-bottom: 0;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-30 {
    margin-bottom: 30px;
}

.container {
    max-width: 1000px;
    margin: 0 auto;
}

/** BTN LG **/
.btn-lg {
    font-size: 14px;
    height: 50px;
    padding: 0 30px;
    line-height: 50px;
    border-radius: 3px;
    color: #ffffff;
    border: none;
    margin: 0 3px 3px;
    display: inline-block;
    vertical-align: middle;
    -webkit-appearance: none;
    text-transform: capitalize;
    transition: all 0.3s linear;
    z-index: 1;
    position: relative;
    overflow: hidden;
    text-align: center;
}

.btn-lg:hover {
    color: #ffffff;
}

.btn-lg:hover:after {
    transform: perspective(200px) scaleX(1.05) rotateX(0deg) translateZ(0);
    transition: transform 0.9s linear, transform 0.4s linear;
}

.btn-lg:after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: "";
    transform: perspective(200px) scaleX(0.1) rotateX(90deg) translateZ(-10px);
    transform-origin: bottom center;
    transition: transform 0.9s linear, transform 0.4s linear;
    z-index: -1;
}

.btn-check:focus+.btn,
.btn:focus {
    outline: 0;
    box-shadow: none;
}

.btn-print {
    background-image: linear-gradient(to bottom, #54544d, #1a1918);
}

.btn-print:after {
    background-image: linear-gradient(to bottom, #1a1918, #54544d);
}

.invoice-content .f-w-600 {
    font-weight: 500 !important;
}

.invoice-content .text-14 {
    font-size: 14px;
}

.invoice-content .invoice-table th:first-child,
.invoice-content .invoice-table td:first-child {
    text-align: left;
}

.invoice-content .color-white {
    color: #fff !important;
}

.invoice-content .inv-header-1 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 24px;
}

.invoice-content .inv-header-2 {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 20px;
}

.invoice-content .inv-title-1 {
    font-weight: 500;
    font-size: 16px;
}

.invoice-content .invo-addr-1 {
    font-size: 14px;
    margin-bottom: 20px;
    line-height: 23px;
}

.invoice-content .item-desc-1 {
    text-align: left;
}

.invoice-content .item-desc-1 span {
    display: block;
}

.invoice-content .item-desc-1 small {
    display: block;
}

.invoice-content .important-notes-list-1 {
    font-size: 14px !important;
    padding-left: 15px;
    margin-bottom: 15px;
}

.invoice-content .important-notes-list-1 li {
    margin-bottom: 5px;
}

.invoice-content .bank-transfer-list-1 {
    font-size: 13px !important;
    padding-left: 0px;
}

.invoice-content .important-notes {
    font-size: 12px !important;
}

.invoice-content .invoice-btn-section {
    text-align: center;
    margin-top: 27px;
}

table th {
    font-weight: 400;
}

.invoice-6 {
    background: #fff6f6;
}

.invoice-6 .mb-30 {
    margin-bottom: 30px;
}

.invoice-6 h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    color: #262525;
}

.invoice-6 .invoice-info {
    background: #f3f4f7;
    position: relative;
    padding: 50px;
    z-index: 0;
}

.invoice-6 .invoice-info:before {
    content: "";
    width: 100%;
    height: 300px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background: #d7e3ff;
}

.invoice-6 .invoice-contant {
    background: #fff;
}

.invoice-6 .invoice-contact-us {
    max-width: 230px;
    margin-left: auto;
}

.invoice-6 .invoice-contact-us ul {
    padding: 0;
    margin: 0;
    text-decoration: none;
    list-style: none;
}

.invoice-6 .logo img {
    height: 30px;
    margin-top: 42px;
}

.invoice-6 .invoice-headar {
    padding: 0 50px 40px;
}

.invoice-6 .invoice-contact-us h1 {
    font-size: 20px;
    margin-bottom: 15px;
    color: #262525;
}

.invoice-6 .invoice-contact-us ul li {
    font-size: 14px;
    line-height: 25px;
    color: #535353;
}

.invoice-6 .invoice-contact-us ul li i {
    width: 20px;
    color: #535353;
}

.invoice-6 .invoice-contact-us ul li a {
    color: #535353;
}

.invoice-6 .inv-title-1 {
    color: #005ce7;
    margin-bottom: 5px;
}

.invoice-6 .name {
    font-size: 18px;
    margin-bottom: 5px;
    text-transform: uppercase;
    color: #262525;
    font-weight: 500;
}

.invoice-6 .name.mb-10 {
    margin-bottom: 10px;
}

.invoice-6 .invoice-number-inner {
    max-width: 230px;
    margin-left: auto;
}

.invoice-6 .invoice-name {
    font-weight: 600;
    font-size: 30px;
}

.invoice-6 .table-outer {
    overflow-y: hidden;
    overflow-x: auto;
}

.invoice-6 .default-table thead th {
    position: relative;
    padding: 20px 30px;
    font-size: 15px;
    color: #005ce7;
    font-weight: 500;
    line-height: 30px;
    white-space: nowrap;
}

.invoice-6 .default-table tbody tr {
    position: relative;
    border-bottom: 1px solid #ECEDF2;
}

.invoice-6 .default-table tr td {
    position: relative;
    padding: 21px 30px;
    font-size: 14px;
    color: #535353;
    font-weight: 400;
}

.invoice-6 .default-table tr td strong {
    font-weight: 500;
}

.invoice-6 .default-table {
    position: relative;
    background: #ffffff;
    border: 0;
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
    min-width: 550px;
}

.invoice-6 .default-table thead {
    background: #F5F7FC;
    border-radius: 8px;
    color: #ffffff;
}

.invoice-6 .payment-method ul {
    list-style: none;
    padding: 0;
}

.invoice-6 .payment-method ul li strong {
    font-weight: 500;
}

.invoice-6 .invoice-top {
    padding: 50px 50px 20px;
}

.invoice-6 .order-summary {
    padding: 0 50px 50px;
}

.invoice-6 .invoice-bottom {
    padding: 0 50px 20px;
}

.invoice-6 .invoice-bottom .inv-title-1 {
    margin-bottom: 7px;
}

.invoice-6 .payment-method {
    max-width: 230px;
    margin-left: auto;
}

@media(max-width: 992px) {
    .invoice-6 {
        padding: 30px 0;
    }

    .invoice-6 .order-summary .default-table thead th {
        padding: 12px 20px;
    }

    .invoice-6 .order-summary .default-table tr td {
        padding: 12px 20px;
    }
}

@media(max-width: 768px) {
    .invoice-6 .invoice-top {
        padding: 30px 30px 0;
    }

    .invoice-6 .order-summary {
        padding: 0 30px 30px;
    }

    .invoice-2 .invoice-id .info {
        margin: 0 auto 0 0;
        padding: 0;
    }

    .invoice-6 .invoice-bottom {
        padding: 0 30px 0;
    }

    .invoice-6 .invoice-headar {
        padding: 30px;
    }

    .invoice-6 .invoice-info {
        background: #f5f7fc;
        padding: 0;
    }


    .invoice-6 .default-table tr td {
        padding: 15px 20px;
    }

    .invoice-6 .default-table thead th {
        padding: 15px 20px;
    }

    .invoice-6 .order-summary .default-table thead th {
        padding: 10px 12px;
    }

    .invoice-6 .order-summary .default-table tr td {
        padding: 10px 12px;
    }
}

@media(max-width: 580px) {
    .invoice-6 .invoice-contact-us {
        max-width: 100%;
        margin: 0;
    }

    .invoice-6 .invoice-number-inner {
        max-width: 100%;
        margin: 0;
    }

    .invoice-6 .payment-method {
        max-width: 100%;
        margin: 0 0 30px;
    }

    .invoice-6 .logo img {
        height: 25px;
        margin: 0 0 10px;
    }

    .invoice-6 .invoice-name {
        font-size: 24px;
    }
}
`;