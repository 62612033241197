import React, { useState, useEffect } from "react";
import { Invoice2style } from "./style";
import ContentEditable from "react-contenteditable";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { VscDiffAdded, VscDiffRemoved } from "react-icons/vsc";
import { IoCloseCircleOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { onInvoiceSave1, onSetInvoice, onSetInvoiceTable, onUpdateInvoiceData } from "../../../Actions/ResourceAction";
import { onFecthCards } from "../../../Actions/ResourceAction";

const Invoice_3 = () => {

  const invoice = useSelector(state => state.resource.invoice)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation()
  const invoiceId = queryString.parse(location.search).id
  const aId = queryString.parse(location.search).aId
  const cId = queryString.parse(location.search).cId

  const [loader, setLoader] = useState(false)
  const [dataVal, setDataVal] = useState(false)
  const [state, setState] = useState({
    name: "John Doe",
    address: "State, Country",
    date: " Oct 12, 2019",
    status: "Unpaid",
    id: " #111-222",
    note: "Extra note such as company or payment information...",
    city: "City, adress",
    phoneNo: "123-456-789",
    thankYou: "Thank you for your business",
    tax: "0.00",
    taxActive: true,
    currency: "$",
    taxPercent: "0%",
  });

  const [table, setTable] = useState([
    {
      registration: "123456",
      quantity: "0",
      total: "0.00",
      price: "0.00",
    },
  ]);

  const [total, setTotal] = useState({
    subTotal: 0,
    grandTotal: 0,
  });

  const [btn, setBtn] = useState({
    add: false,
    minus: null,
    taxShow: false,
  });

  const [id, setId] = useState({
    aId: aId,
    cId: cId
  })

  const handleAddrow = () => {
    setTable([
      ...table,
      {
        registration: "123456",
        quantity: "0",
        total: "0.00",
        price: "0.00",
      },
    ]);
  };

  const handleTable = (e, index, type) => {
    const { value } = e.target;
    if (index !== undefined) {
      let newData = [...table];
      newData[index] = {
        ...newData[index],
        [type]: value,
      }
      if (type === "quantity" || type === "price") {
        newData[index] = {
          ...newData[index],
          total: (
            parseFloat(newData[index].price) * newData[index].quantity
          ).toFixed(2),
        };
      } setTable(newData);
      dispatch(onSetInvoiceTable(newData));
    }
  };

  const handleRemoveRow = (ind) => {
    const updateRow = table.filter((item, id) => {
      return ind !== id;
    });
    setTable(updateRow);
  };

  const handleChange = (e, type) => {
    const { value } = e.target;
    setState({
      ...state,
      [type]: value,
    });
    dispatch(onUpdateInvoiceData(type, value,))
  };

  const addMouseHover = (id) => {
    setBtn({
      ...btn,
      add: true,
      minus: false,
    });
  };

  const addMouseLeave = () => {
    setBtn({
      ...btn,
      add: false,
      minus: false,
    });
  };

  const removeMouseHover = (id) => {
    setBtn({
      ...btn,
      add: false,
      minus: id,
    });
  };

  const removeMouseLeave = (id) => {
    setBtn({
      ...btn,
      add: false,
      minus: null,
    });
  };

  const handleTax = (val, type) => {
    setState({
      ...state,
      [type]: val,
    });
  };

  useEffect(() => {
    let grandtotal = 0,
      subtotal = 0;
    table.forEach((curElem) => {
      subtotal = subtotal + parseFloat(curElem.total);
    });
    grandtotal = subtotal + parseFloat(state.tax);
    if (!state.taxActive) {
      grandtotal = grandtotal - parseFloat(state.tax);
    }

    setTotal({
      ...total,
      subTotal: subtotal,
      grandTotal: grandtotal,
    });
  }, [table, state.taxActive, state]);

  const fetchData = () => {
    let data = {
      businessCardId: invoiceId
    }
    dispatch(onFecthCards(data, setLoader, setDataVal))
  }

  useEffect(() => {
    if (id) {
      fetchData()
    }
  }, [id])

  useEffect(() => {
    if (invoice.data === "") {
      dispatch(onSetInvoice({ ...state, table }))
    } else {
      setState(invoice.data)
      setTable(invoice.data.table)
    }
  }, [invoice])

  return (
    <>
      <Invoice2style>
        <div className="page-content ">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 p-0">
                <div className="invoice_bg">
                  <div className="row">
                    <div className="col-lg-12 text-right">
                      <h1 className="heading">INVOICE</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container px-0">
              <div className="row mt-4">
                <div className="col-12 col-lg-12">
                  <div className="row">
                    <div className="col-sm-6">
                      <div>
                        <span className="text-sm text-grey-m2 align-middle">
                          To:
                        </span>
                        <span className="text-600 text-110 text-blue align-middle">
                          <ContentEditable
                            html={state.name}
                            tagName="span"
                            onChange={(e) => handleChange(e, "name")}
                          />{" "}
                        </span>
                      </div>
                      <div className="text-grey-m2">
                        <div className="my-1">
                          <ContentEditable
                            html={state.city}
                            tagName="span"
                            onChange={(e) => handleChange(e, "city")}
                          />
                        </div>
                        <div className="my-1">
                          <ContentEditable
                            html={state.address}
                            tagName="span"
                            onChange={(e) => handleChange(e, "adress")}
                          />
                        </div>
                        <div className="my-1">
                          <i className="fa fa-phone fa-flip-horizontal text-secondary"></i>
                          <b className="text-600">
                            <ContentEditable
                              html={state.phoneNo}
                              tagName="span"
                              onChange={(e) => handleChange(e, "phoneNo")}
                            />
                          </b>
                        </div>
                      </div>
                    </div>

                    <div className="text-95 col-sm-6 align-self-start d-sm-flex justify-content-end">
                      <hr className="d-sm-none" />
                      <div className="text-grey-m2">
                        <div className="mt-1 mb-2 text-secondary-m1 text-600 text-125">
                          Invoice
                        </div>
                        <div className="my-2">
                          <i className="fa fa-circle text-blue-m2 text-xs mr-1"></i>{" "}
                          <span className="text-600 text-90">ID:</span>
                          <ContentEditable
                            html={state.id}
                            tagName="span"
                            onChange={(e) => handleChange(e, "id")}
                          />
                        </div>
                        <div className="my-2">
                          <i className="fa fa-circle text-blue-m2 text-xs mr-1"></i>{" "}
                          <span className="text-600 text-90">Issue Date:</span>
                          <ContentEditable
                            html={state.date}
                            tagName="span"
                            onChange={(e) => handleChange(e, "date")}
                          />
                        </div>
                        <div className="my-2">
                          <i className="fa fa-circle text-blue-m2 text-xs mr-1"></i>{" "}
                          <span className="text-600 text-90">Status:</span>{" "}
                          <span className="badge badge-warning badge-pill px-25">
                            <ContentEditable
                              html={state.status}
                              tagName="span"
                              onChange={(e) => handleChange(e, "satus")}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4">
                    <div
                      className="row text-600 text-white bgc-default-tp1 py-25"
                      onMouseOver={addMouseHover}
                      onMouseLeave={addMouseLeave}
                    >
                      <div className="d-none d-sm-block col-1">#</div>
                      <div className="col-9 col-sm-5">Description</div>
                      <div className="d-none d-sm-block col-4 col-sm-2">
                        Qty
                      </div>
                      <div className="d-none d-sm-block col-sm-2">
                        Unit Price
                      </div>
                      <div className="col-2">
                        Amount
                        {btn.add ? (
                          <VscDiffAdded
                            style={{
                              position: "absolute",
                              cursor: "pointer",
                              marginLeft: "30px",
                            }}
                            onClick={handleAddrow}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="text-95 text-secondary-d3">
                      {table.map((elem, index) => {
                        return (
                          <>
                            <div
                              className={`row mb-2 mb-sm-0 py-25 ${index % 2 === 0 ? "" : "bgc-default-l4"
                                } `}
                              onMouseOver={() => removeMouseHover(index)}
                              onMouseLeave={() => removeMouseLeave(index)}
                            >
                              <div className="d-none d-sm-block col-1">
                                {index + 1}
                              </div>
                              <div className="col-9 col-sm-5">
                                <ContentEditable
                                  html={elem.registration}
                                  onChange={(e) =>
                                    handleTable(e, index, "registration")
                                  }
                                />
                              </div>
                              <div className="d-none d-sm-block col-2">
                                <ContentEditable
                                  html={elem.quantity}
                                  onChange={(e) =>
                                    handleTable(e, index, "quantity")
                                  }
                                />
                              </div>
                              <div className=" text-secondary-d2 col-2  d-flex">
                                <ContentEditable
                                  html={state.currency}
                                  tagName="span"
                                  onChange={(e) => handleChange(e, "currency")}
                                />
                                <ContentEditable
                                  html={elem.price}
                                  onChange={(e) =>
                                    handleTable(e, index, "price")
                                  }
                                />
                              </div>
                              <div className="col-2 text-secondary-d2 d-flex">
                                <ContentEditable
                                  html={state.currency}
                                  tagName="span"
                                  onChange={(e) => handleChange(e, "currency")}
                                />
                                {elem.total}

                                {btn.minus === index ? (
                                  <VscDiffRemoved
                                    style={{
                                      position: "absolute",
                                      cursor: "pointer",
                                      marginLeft: "100px",
                                    }}
                                    onClick={() => handleRemoveRow(index)}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </>
                        );
                      })}

                      {/* <div className="row mb-2 mb-sm-0 py-25 bgc-default-l4">
                                                <div className="d-none d-sm-block col-1">2</div>
                                                <div className="col-9 col-sm-5">Web hosting</div>
                                                <div className="d-none d-sm-block col-2">1</div>
                                                <div className="d-none d-sm-block col-2 text-95">$15</div>
                                                <div className="col-2 text-secondary-d2">$15</div>
                                            </div>
                                            <div className="row mb-2 mb-sm-0 py-25">
                                                <div className="d-none d-sm-block col-1">3</div>
                                                <div className="col-9 col-sm-5">Software development</div>
                                                <div className="d-none d-sm-block col-2">--</div>
                                                <div className="d-none d-sm-block col-2 text-95">$1,000</div>
                                                <div className="col-2 text-secondary-d2">$1,000</div>
                                            </div>
                                            <div className="row mb-2 mb-sm-0 py-25 bgc-default-l4">
                                                <div className="d-none d-sm-block col-1">4</div>
                                                <div className="col-9 col-sm-5">Consulting</div>
                                                <div className="d-none d-sm-block col-2">1 Year</div>
                                                <div className="d-none d-sm-block col-2 text-95">$500</div>
                                                <div className="col-2 text-secondary-d2">$500</div>
                                            </div> */}
                    </div>
                    <div className="row border-b-2 brc-default-l2"></div>

                    <div className="row mt-3">
                      <div className="col-12 col-sm-7 text-grey-d2 text-95 mt-2 mt-lg-0">
                        <ContentEditable
                          html={state.note}
                          onChange={(e) => handleChange(e, "note")}
                        />
                      </div>
                      <div className="col-12 col-sm-5 text-grey text-90 order-first order-sm-last">
                        <div className="row my-2">
                          <div className="col-7 text-right">SubTotal</div>
                          <div className="col-5">
                            <span className="text-120 text-secondary-d1">
                              <ContentEditable
                                html={state.currency}
                                tagName="span"
                                onChange={(e) => handleChange(e, "currency")}
                              />
                              {total.subTotal.toFixed(2)}
                            </span>
                          </div>
                        </div>
                        {state.taxActive ? (
                          <div
                            className="row my-2"
                            onMouseEnter={() =>
                              setBtn({
                                ...btn,
                                taxShow: true,
                              })
                            }
                            onMouseLeave={() =>
                              setBtn({
                                ...btn,
                                taxShow: false,
                              })
                            }
                          >
                            <div className="col-7 text-right">
                              Tax (
                              <ContentEditable
                                html={state.taxPercent}
                                tagName="taxPercent"
                                onChange={(e) => handleChange(e, "taxPercent")}
                              />
                              )
                            </div>
                            <div className="col-5">
                              <span className="text-110 text-secondary-d1">
                                <ContentEditable
                                  html={state.currency}
                                  tagName="span"
                                  onChange={(e) => handleChange(e, "currency")}
                                />
                                <ContentEditable
                                  html={state.tax}
                                  tagName="span"
                                  onChange={(e) => handleChange(e, "tax")}
                                />
                                {btn.taxShow ? (
                                  <IoCloseCircleOutline
                                    style={{
                                      position: "absolute",
                                      cursor: "pointer",
                                      marginLeft: "10px",
                                    }}
                                    size={16}
                                    onClick={() =>
                                      handleTax(false, "taxActive")
                                    }
                                    title="Remove Tex"
                                  />
                                ) : (
                                  ""
                                )}
                              </span>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="row my-2 align-items-center bgc-primary-l3 p-2">
                          <div className="col-7 text-right">Total Amount</div>
                          <div className="col-5">
                            <span className="text-150 text-success-d3 opacity-2">
                              <ContentEditable
                                html={state.currency}
                                tagName="span"
                                onChange={(e) => handleChange(e, "currency")}
                              />
                              {total.grandTotal.toFixed(2)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="pb-2">
                      <span className="text-secondary-d1 text-105 ">
                        <ContentEditable
                          html={state.thankYou}
                          tagName="span"
                          onChange={(e) => handleChange(e, "thankYou")}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="btn-sec text-left" style={{position:"absolute",marginTop:"25px"}}>
        <button className="demoLink" onClick={handleSave}>
          {loader ? <>Saving Invoice <i className="fa fa-spinner fa-spin mx-1" /></> : "Save Invoice"}
        </button>
    </div> */}
      </Invoice2style>
    </>
  );
};
export default Invoice_3;
