import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { onCreateCampaign, onCreateBlogCampaign, onCreatePressCampaign, onLoadFbData, onCreateLead, onCreateBusinessCard } from '../../../Actions/ResourceAction';
import modalCloseIcon from '../../../images/modal-close.png';

const CreateModal = ({ handleClose, show, type, aId, cId, imageData }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false);
    const [imageLoader, setImageLoader] = useState({
        submitLoader: false
    })
    const [state, setState] = useState("");
    const [keyword, setKeyword] = useState("")

    const handleSubmit = (e) => {
        e.preventDefault()
        if (type === "blog") {
            let data = {
                blogName: state,
                agencyId: aId,
                clientId: cId,
            }
            setLoader(true)
            dispatch(onCreateBlogCampaign(data, navigate, setLoader))
        } else if (type === "press") {
            let data = {
                name: state,
                agencyId: aId,
                clientId: cId,
            }
            setLoader(true)
            dispatch(onCreatePressCampaign(data, navigate, setLoader))
        }
        else if (type === "fb" || type === "go") {
            let data = {
                name: state,
                keyword: keyword,
                agencyId: aId,
                clientId: cId,
            }
            let ep = "load-facebook-ads-data"
            if (type === "go") {
                ep = "load-google-ads-data"
            }
            setLoader(true)
            dispatch(onLoadFbData(ep, data, navigate, type, setLoader))
        }
        else if (type === "lead") {
            let data = {
                agencyId: aId,
                clientId: cId,
                name: state,
            }
            setLoader(true)
            dispatch(onCreateLead(data, navigate, setLoader))
        }
        else if (type === "image") {
            let obj = {
                version: "5.3.0",
                objects: [
                    {
                        type: "image",
                        version: "5.3.0",
                        originX: "left",
                        originY: "top",
                        left: 0,
                        top: 0,
                        fill: "rgb(0,0,0)",
                        stroke: null,
                        strokeWidth: 0,
                        strokeDashArray: null,
                        strokeLineCap: "butt",
                        strokeDashOffset: 0,
                        strokeLineJoin: "miter",
                        strokeUniform: false,
                        strokeMiterLimit: 4,
                        scaleX: 1,
                        scaleY: 1,
                        angle: 0,
                        flipX: false,
                        flipY: false,
                        opacity: 1,
                        shadow: null,
                        visible: true,
                        backgroundColor: "",
                        fillRule: "nonzero",
                        paintFirst: "fill",
                        globalCompositeOperation: "source-over",
                        skewX: 0,
                        skewY: 0,
                        cropX: 0,
                        cropY: 0,
                        src: imageData,
                        crossOrigin: null,
                        filters: []
                    }]
            }
            obj = JSON.stringify(obj)
            let data = {
                agencyId: aId,
                clientId: cId,
                name: state,
                type: "image",
                data: obj
            }
            setImageLoader({
                ...imageLoader,
                submitLoader: true
            })
            dispatch(onCreateBusinessCard(data, navigate, imageLoader, setImageLoader))
        }
        else {
            let data = {
                agencyId: aId,
                clientId: cId,
                name: state,
                type: type
            }
            setLoader(true)
            dispatch(onCreateCampaign(data, navigate, setLoader))
        }
    }

    return (
        <Modal className="VideoModal  small" scrollable={false} backdrop="static" show={show} centered>
            <Modal.Body>
                <form onSubmit={handleSubmit}>
                    <div onClick={handleClose} className="vidClose"><img src={modalCloseIcon} alt="" /></div>
                    <div className="formSec">
                        <div className="inpLabelWrap mt-0">
                            <div className="inpLabel">
                                <label>Give A Campaign Name</label>
                                <input
                                    className="inpLabel-inp"
                                    placeholder="Enter Campaign Name Here"
                                    type="text"
                                    value={state}
                                    onChange={(e) => setState(e.target.value)}
                                    required
                                />
                            </div>
                        </div>
                        {type === "fb" || type === "go" ?
                            <div className="inpLabelWrap mt-2">
                                <div className="inpLabel">
                                    <label>Keyword</label>
                                    <input
                                        className="inpLabel-inp"
                                        placeholder="Enter Keyword Here"
                                        type="text"
                                        value={keyword}
                                        onChange={(e) => setKeyword(e.target.value)}
                                        required
                                    />
                                </div>
                            </div> : ""}

                        <button type="submit" className="btn-block inpBtn mt-3 create-modal-btn">
                            {type === "image" ?
                                imageLoader.submitLoader ?
                                    <> Next <i className="fa fa-spinner fa-spin mx-1" /> </>
                                    : "Next"
                                :
                                loader
                                    ?
                                    <> Next <i className="fa fa-spinner fa-spin mx-1" /> </>
                                    : "Next"
                            }
                        </button>
                    </div>
                </form>

            </Modal.Body>
        </Modal>

    );
}

export default CreateModal;
