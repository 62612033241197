import React, { useEffect, useState } from 'react'
import { FiArrowRight, FiSearch } from "react-icons/fi"

import AgencyModal from "./AgencyModal";
import { onFetchAgencyList } from "../../Actions/AgencyAction";
import { useDispatch } from "react-redux";
import AgencyTableHeader from "./AgencyTableHeader";
import AgencyRowData from "./AgencyRowData";
import Pagination from './Pagination';

const AgencyList = () => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [loader, setLoader] = useState(false);
    const [listArr, setListArr] = useState([])
    const [listData, setListData] = useState([])
    const [filter, setFilter] = useState({
        search: "",
        mode: ""
    })

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemonPage: 10
    });


    const fetchAgencyList = () => {
        setLoader(true)
        dispatch(onFetchAgencyList(setListArr, setLoader))
    }

    const handleSelect = (e) => {
        const { value } = e.target;
        if (value === "") {
            setFilter({ ...filter, mode: "" })

        } else if (value === "1") {
            setFilter({ ...filter, mode: "1" })

        } else if (value === "0") {
            setFilter({ ...filter, mode: "0" })
        }
    }

    const indexofLastTodo = pagination.currentPage * pagination.totalItemonPage;
    const indexofFirstTodo = indexofLastTodo - pagination.totalItemonPage;
    const currentTodo =
        (filter.search === "" && filter.mode !== "") ||
            (filter.search !== "" && filter.mode === "") ||
            (filter.search !== "" && filter.mode !== "")
            ? listData
            : listData.slice(indexofFirstTodo, indexofLastTodo);

    useEffect(() => {
        if (filter.search === "" && filter.mode === "") {
            return setListData(listArr)
        } else {
            const val = listArr
                .filter((curElem) => {
                    if (filter.mode === "") {
                        return curElem;
                    } else {
                        let data = curElem.mode === filter.mode;
                        return data;
                    }
                })
                .filter((curElem) => {
                    let data = curElem.name.toLowerCase().includes(filter.search.toLowerCase());
                    if (data) {
                        return curElem;
                    }
                })
            setListData(val)
        }
    }, [listArr, filter])

    useEffect(() => {
        fetchAgencyList()
    }, [])
    return (
        <>
            <div className="container">
                <div className="business-wrap">

                    <div className="business-top">
                        <h2>Your Agencies</h2>
                        <span className="demoLink mt-0" onClick={handleShow}>Create New Agency <FiArrowRight /> </span>
                    </div>

                    <div className="business-list">
                        <div className="business-search">
                            <div className="business-search-inp">
                                <span className="searchIcon"><FiSearch /></span>
                                <input className="inpSearch withIcon"
                                    value={filter.search}
                                    onChange={(e) => setFilter({ ...filter, search: e.target.value })}
                                    type="text"
                                    name="search"
                                    placeholder="Search Agency" />
                            </div>
                            <div className="business-search-inp ml-2">
                                <select name="mode" id="" onChange={handleSelect} className="inpSearch">
                                    <option value="">All</option>
                                    <option value="1">Active</option>
                                    <option value="0">Inactive</option>
                                </select>
                            </div>
                        </div>
                        <div className="bListing">
                            <table className="table businessTable">
                                <thead>
                                    <AgencyTableHeader
                                        listData={listData}
                                        setListData={setListData} />
                                </thead>
                                <tbody>
                                    {currentTodo.length > 0 ?
                                        currentTodo.map((curElem, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <AgencyRowData
                                                        curElem={curElem}
                                                        fetchAgencyList={fetchAgencyList}

                                                    />
                                                </React.Fragment>
                                            )
                                        })
                                        : ""
                                    }
                                </tbody>
                            </table>

                            <div className="text-center mt-1">
                                {listArr.length === 0 ?
                                    loader ?
                                        <i className="fa fa-spinner fa-spin" style={{ fontSize: "25px", color: "#d03e3e" }} />
                                        :
                                        `"No agency created yet!"` :
                                    ""}
                            </div>
                            <Pagination
                                listData={listData}
                                pagination={pagination}
                                setPagination={setPagination}
                                currentTodo={currentTodo}
                                listArr={listArr}
                                loader={loader}
                            />

                        </div>
                    </div>
                </div>
            </div>
            <AgencyModal
                show={show}
                handleClose={handleClose}
            />
        </>
    )
}

export default AgencyList