import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { onChangeVideo } from '../../Actions/ClientsAction'

const VideoSection = () => {
    const dispatch = useDispatch()
    const landingData = useSelector(state => state.client.landing)
    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(onChangeVideo(name, value))
    }

    return (
        <div>
            <div className="inpLabelWrap">
                <span className="labelTxt">Video Section Heading</span>
                <div className="inpLabel">
                    <label htmlFor="heading">Video Section Heading</label>
                    <input
                        className="inpLabel-inp"
                        placeholder="Video Section Heading"
                        type="text"
                        name='heading'
                        value={landingData.videos.heading}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="inpLabelWrap">
                <span className="labelTxt">Video Source</span>
                <div className="inpLabel">
                    <label htmlFor="source">Video Source</label>
                    <select
                        className="inpLabel-inp"
                        name='source'
                        value={landingData.videos.source}
                        onChange={handleChange}
                    >
                        <option value="">Select Video Source</option>
                        <option value="vimeoUrl">Vimeo Url</option>
                        <option value="youtubeUrl">Youtube Url</option>
                        <option value="customeUrl">Custome Url</option>
                    </select>
                </div>
            </div>
            {landingData.videos.source === "" ? "" :
                <div className="inpLabelWrap">
                    <span className="labelTxt">{landingData.videos.source === "vimeoUrl" ? "Vimeo" : landingData.videos.source === "youtubeUrl" ? "Youtube" : landingData.videos.source === "customeUrl" ? "Custome" : ""}  Link</span>
                    <div className="inpLabel">
                        <label htmlFor="url">{landingData.videos.source === "vimeoUrl" ? "Vimeo" : landingData.videos.source === "youtubeUrl" ? "Youtube" : landingData.videos.source === "customeUrl" ? "Custome" : ""} Link</label>
                        <input
                            className="inpLabel-inp"
                            placeholder={`${landingData.videos.source === "vimeoUrl" ? "Vimeo" : landingData.videos.source === "youtubeUrl" ? "Youtube" : landingData.videos.source === "customeUrl" ? "Custome" : ""} Link`}
                            type="text"
                            name='url'
                            value={landingData.videos.url}
                            onChange={handleChange}
                        />
                    </div>
                </div>}
        </div>
    )
}

export default VideoSection