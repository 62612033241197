import React, { useState } from 'react'
import ImagesModal from './Modals/ImagesModal';
import defaultImage from "../../images/default_image.jpg"
import { FiUpload } from 'react-icons/fi'
import { RxCross2 } from 'react-icons/rx';
import { onCancleMember, onChangeTeam } from '../../Actions/ClientsAction';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';

const TeamSectionRows = ({ curElem, index, handleChange }) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const handleCancel = (index) => {
        Swal.fire({
            title: "Are You Sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it"
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(onCancleMember(index))
            }
        })

    }
    const handleAddMemberImage = (data) => {
        dispatch(onChangeTeam("image", data.url, index))
    }
    return (
        <div className="col-lg-4 mb-4">
            <div className="benefit-single">
                <h6>Member {index + 1}</h6>
                <div className="inpLabelWrap">
                    <span className="labelTxt">Image</span>
                    <div className="inpLabel">
                        <span onClick={handleShow} className="fullLink cursor-pointer"></span>
                        <span className='uploaded-img'><img src={curElem.image !== "" ? curElem.image : defaultImage} alt="" /></span>
                        <input
                            className="inpLabel-inp"
                            placeholder=""
                            type="text"
                            name='image'
                        />
                        <span className="inpBtn-icon">
                            <FiUpload /> <span className="pl-1">Choose</span>
                        </span>
                    </div>
                </div>
                <div
                    className='image-delete-button'
                    style={{ top: -7, right: 12, color: "#000000", cursor: "pointer", fontSize: "20px" }}
                    onClick={() => handleCancel(index)}
                >
                    <RxCross2 />
                </div>
                <div className="inpLabelWrap">
                    <span className="labelTxt">Name</span>
                    <div className="inpLabel">
                        <label htmlFor="">Name</label>
                        <input
                            className="inpLabel-inp"
                            placeholder="Enter Name"
                            type="text"
                            value={curElem.name}
                            name="name"
                            onChange={(e) => handleChange(e, index)}
                        />
                    </div>
                </div>
                <div className="inpLabelWrap">
                    <span className="labelTxt">Role</span>
                    <div className="inpLabel">
                        <label htmlFor="">Role</label>
                        <input
                            className="inpLabel-inp"
                            placeholder="Enter Role"
                            type="text"
                            value={curElem.role}
                            name="role"
                            onChange={(e) => handleChange(e, index)}
                        />
                    </div>
                </div>
                <div className="inpLabelWrap">
                    <span className="labelTxt">Description</span>
                    <div className="inpLabel">
                        <label htmlFor="" style={{ width: "100%", borderTopLeftRadius: "10px", borderTopRightRadius: "10px", background: "#fff", borderTop: "1px solid #ffbebe", borderLeft: "1px solid #ffbebe", borderRight: "1px solid #ffbebe" }}>Enter Description</label>
                        <textarea
                            className="inpLabel-inp"
                            type="text"
                            value={curElem.description}
                            name="description"
                            placeholder='Enter Description'
                            onChange={(e) => handleChange(e, index)}
                        />
                    </div>
                </div>
            </div>

            <ImagesModal
                show={show}
                handleClose={handleClose}
                callbackFun={handleAddMemberImage}
                imageIndex={index}
            />
        </div>
    )
}

export default TeamSectionRows