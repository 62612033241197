import React from "react";
import mac from "../../images/imge2.png"
import mac2 from "../../images/imge1.png"
import mac3 from "../../images/imge3.png"
import { useSelector } from "react-redux";
import { appName } from "../../Global/Global";


const DashboardAbout = () => {
    const rebrand = useSelector(state => state.rebrand.data)
    return (
        <>
            <section className="featureSec">
                <div className="container-fluid">
                    <div className="featureSec-wrap">
                        <div className="featureSec-single">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="featureSec-left">
                                        <h6>ULTIMATE SOLUTION TO DRIVE PERPETUAL LEADS</h6>
                                        <h2>Auto-create entire lead generation campaigns</h2>
                                        <p>Say goodbye to the days of spending hours upon hours building campaigns from scratch, trying to figure out complicated coding and copywriting. {rebrand ? rebrand.name : appName} drives leads for yourself and your clients, regardless of the niche you're in.</p>
                                        {/* <div className="textL">See how you can use {rebrand ? rebrand.name : appName} to <span>create your first Invoice</span></div> */}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="featureSec-right"><img alt="" src={mac} /></div>
                                </div>
                            </div>
                        </div>
                        <div className="featureSec-single">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="featureSec-left">
                                        <h6>AUTOMATIC AD CREATION</h6>
                                        <h2>Bringing you hands-free traffic and leads overnight</h2>
                                        <p>Get autogenerated ads that perfectly match your lead generation pages for maximum conversions. {rebrand ? rebrand.name : appName} analyzes your page content and creates ads that complement it, ensuring that your message is delivered in the most effective way possible.</p>
                                        {/* <div className="textL">Watch our <span>video editing tutorial</span> on how to save time while editing your videos</div> */}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="featureSec-right"><img alt="" src={mac2} /></div>
                                </div>
                            </div>
                        </div>
                        <div className="featureSec-single">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="featureSec-left">
                                        <h6>INSTANT VIDEO LEAD GENERATION WEBSITE</h6>
                                        <h2>Building gorgeous video lead pages in record time</h2>
                                        <p>Create stunning video lead pages in any niche you want, without any technical skills. Customize your pages with your branding, messaging, and call-to-action. With wide selection of templates, you can choose the perfect design for your campaign.</p>
                                        {/* <div className="textL">Find out how the <span>Team Members Access</span> feature works</div> */}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="featureSec-right"><img alt="" src={mac3} /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DashboardAbout;