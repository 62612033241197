import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import queryString from 'query-string'
import { useEffect } from "react";
import { setAlert } from "../../Actions/AlertAction";
import { onGetCampaign, onSelectTopic, onSubmitTopic } from "../../Actions/ResourceAction";
import { onGetClient } from "../../Actions/ClientsAction";
import { IoMdArrowRoundBack } from "react-icons/io"

const Topic = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const auth = useSelector(state => state.auth)
    const campaign = useSelector(state => state.resource.videoCamp)
    const videoId = queryString.parse(location.search).id
    const aId = queryString.parse(location.search).aId
    const cId = queryString.parse(location.search).cId
    const clientData = useSelector(state => state.client.data)

    const [topic, setTopic] = useState("")
    const [aiSuggestion, setAiSuggestion] = useState([])
    const [loader, setLoader] = useState({
        chooseLoader: false,
        getTopicLoader: false,
        pageLoader: true,
        index: -1
    })

    const handleChange = (e) => {
        if (e.target.value.length < 101) {
            setTopic(e.target.value)
        } else {
            dispatch(setAlert("Max limit is 100 characters only", "danger"))
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setAiSuggestion([])
        setLoader({
            ...loader,
            getTopicLoader: true
        })
        let data = {
            videoId: videoId,
            keyword: topic
        }
        dispatch(onSubmitTopic(data, setAiSuggestion, loader, setLoader))
    }

    const handleBackBtn = () => {
        if (aId && cId) {
            navigate(`/agency/create-client?id=${aId}&cId=${cId}`)
        }
    }

    const handleUse = (curElem, index) => {
        let data = {
            videoId: videoId,
            title: curElem
        }
        setLoader({
            ...loader,
            chooseLoader: true,
            index: index
        })
        dispatch(onSelectTopic(data, navigate, loader, setLoader))
    }

    const fetchCampaign = () => {
        let data = {
            videoId: videoId
        }
        dispatch(onGetCampaign(data, loader, setLoader))
    }

    const getClientData = () => {
        let data = {
            "id": cId,
            "agencyId": aId
        }
        setLoader({
            ...loader,
            pageLoader: true
        })
        dispatch(onGetClient(data, loader, setLoader))
    }

    useEffect(() => {
        if (aId && cId) {
            getClientData()
        }
    }, [aId, cId])

    useEffect(() => {
        if (videoId) {
            fetchCampaign()
        }
    }, [videoId])

    return (
        loader.pageLoader ?
            <div className="loader-sec">
                <div className="loader" />
            </div>
            : <>
                <section className="container">
                    <div className="business-top">
                        <h2>Create Youtube Video</h2>
                        <button className="demoLink" onClick={handleBackBtn}> <IoMdArrowRoundBack /> Go Back</button>
                    </div>
                    <div className="business-list">
                        <div className="proImage withDesc">
                            <div className="proImage-holder">
                                <img src={clientData.image} alt="" />
                            </div>
                            <div className="proImage-txt">
                                <p><strong>{clientData.name}</strong></p>
                                <p>{clientData.email}</p>
                                <p>{clientData.company}</p>
                            </div>
                        </div>
                    </div>
                    <div className="create-course-top">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-8 text-capitalize">
                                    <h2>
                                        {campaign.name}
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="create-course-mid alt">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-xl-8 text-center" >
                                    <form className="row" onSubmit={handleSubmit}>
                                        <div className="col-md-12">
                                            <h2 className="text-center" style={{ color: "#dc4747" }}>Topic Research</h2>
                                        </div>
                                        <div className="col-md-8 mt-4">
                                            <input
                                                className="courseInp"
                                                placeholder="Enter The Keyword"
                                                required
                                                name="topic"
                                                value={topic}
                                                maxLength={100}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-4 text-md-start text-sm-center p-0">
                                            <button
                                                type="submit"
                                                className="demoLink-topic"
                                                disabled={auth.user.is_client_account === "1" ? true : false}
                                            >{loader.getTopicLoader ? <> Searching <i className="fa fa-spinner fa-spin mr-2" /> </> : "Search"}</button>
                                        </div>
                                        <div className="col-md-12 text-center mt-4">
                                            <div className="create-or-line"> <span>OR</span> </div>
                                        </div>
                                    </form>
                                    {aiSuggestion.length > 0 ?
                                        <div className="recomnd" style={{ color: "#d74343" }}>
                                            <h3>Suggestion</h3>
                                            <ul>
                                                {
                                                    aiSuggestion.map((curElem, index) => {
                                                        let str = curElem.replace(/^[1-9][0-9]*\.\s|^[1-9][0-9]*\)\s/g, '')

                                                        return (
                                                            <li key={index}>
                                                                <div className="recomnd-single d-flex justify-content-between align-items-center">
                                                                    <p
                                                                        style={{
                                                                            textAlign: "left",
                                                                            paddingRight: '23px',
                                                                            margin: "auto 0",
                                                                        }}
                                                                    >
                                                                        {str}
                                                                    </p>
                                                                    <button
                                                                        type="button"
                                                                        className={`${curElem.isSelected === "1" ? "demoLink-Ai-search" : "demoLink"} mt-0 ms-1`}
                                                                        onClick={() => handleUse(str, index)}
                                                                        disabled={loader.chooseLoader}
                                                                    // style={{ background: loader.chooseLoader ? "gray" : "" }}
                                                                    >
                                                                        {loader.chooseLoader && (+index === +loader.index) ? <>Using <i className="fa fa-spinner fa-spin mx-1" /></> : "Use"}
                                                                    </button>
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                            {/* {loader.getTopicLoader ?
                                                <i className="fa fa-spinner fa-spin" style={{ fontSize: 25 }} /> : ""
                                            } */}
                                        </div>
                                        : ""}
                                </div>
                            </div>
                        </div>
                    </div>

                </section >

                {/* <Footer /> */}
            </>
    )
}

export default Topic;