import React, { useEffect, useState } from 'react'
import { Modal, Nav, Tab } from 'react-bootstrap'
import { BsCheckCircle } from 'react-icons/bs';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { onfetchImages } from '../../../Actions/ClientsAction';
import modalCloseIcon from '../../../images/modal-close.png';
import UploadImage from './UploadImage';

const ImagesModal = (props) => {
    const dispatch = useDispatch()
    const [imageList, setImageList] = useState([])
    const [selected, setSelected] = useState({
        data: {},
        index: -1
    })
    const [msg, setMsg] = useState("")
    const [image, setImage] = useState({
        keyword: "",
        page_number: 1,
        type: "image"
    })
    const [loader, setLoader] = useState({
        saveLoader: false,
        fetchLoader: false
    })

    const handleSearch = (val) => {
        setImage({
            ...image,
            keyword: val
        })
        if (imageList.length > 0) {
            setImageList([])
        }
        if (msg !== "") {
            setMsg("")
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        fetchImages()
    }

    const fetchImages = (page = 1) => {
        let data = { ...image }
        data.page_number = page
        setLoader({
            ...loader,
            fetchLoader: true
        })
        setImage({
            ...image,
            page_number: page,
        })
        dispatch(onfetchImages(data, imageList, setImageList, setMsg, loader, setLoader))
    }

    const onChooseImage = (curElem, index) => {
        setSelected({
            ...selected,
            data: curElem,
            index: index
        })
    }

    const handleSave = () => {
        setLoader({
            ...loader,
            saveLoader: true
        })
        setTimeout(() => {
            if (props.iconIndex !== undefined) {
                props.callbackFun(selected.data, props.iconIndex)
            } else {
                props.callbackFun(selected.data)
            }
            props.handleClose()
            setLoader({
                ...loader,
                saveLoader: false
            })
        }, 500);

    }

    useEffect(() => {
        return () => {
            setImageList([])
            setImage({
                ...image,
                keyword: "",
                page_number: 1,
                type: "image"
            })
        }
    }, [props.handleClose])

    return (
        <Modal className="VideoModal forImg" show={props.show} centered>
            <Modal.Body>
                <div onClick={props.handleClose} className="vidClose"><img alt="" src={modalCloseIcon} /></div>
                <div className="imgUp">
                    <Tab.Container defaultActiveKey="first">
                        <Nav variant="h">
                            <Nav.Item>
                                <Nav.Link eventKey="first">Library</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="second">Upload</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <div className="imgUp-main">
                                    <form className="img-search" onSubmit={handleSubmit}>
                                        <input
                                            type="text"
                                            placeholder="Search Image"
                                            value={image.keyword}
                                            onChange={(e) => handleSearch(e.target.value)}
                                        />
                                    </form>
                                    <InfiniteScroll
                                        dataLength={imageList.length} //This is important field to render the next data
                                        next={() => fetchImages(image.page_number + 1)}
                                        hasMore={true}
                                        scrollableTarget={`scrollableImageModal`}
                                    >
                                        <div className="img-search-scroll" style={{ minHeight: "350px" }} id='scrollableImageModal'>
                                            <ul >
                                                {imageList.length > 0 ?
                                                    imageList.map((curElem, index) => {
                                                        return (
                                                            <li key={index}>
                                                                <div
                                                                    className="imgUp-single cursor-pointer"
                                                                    style={{ border: selected.index === index ? "1px solid #d84444" : "" }}
                                                                    onClick={() => onChooseImage(curElem, index)}
                                                                >
                                                                    <img src={curElem.thumbnail} alt={` ${image.keyword} Image ${index}`} />
                                                                    <div className="imgStat"><BsCheckCircle /></div>
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                    : <div className='text-center pt-3' style={{ margin: "auto" }}>
                                                        {msg}
                                                    </div>}
                                            </ul>
                                            {loader.fetchLoader ?
                                                <div className='text-center mt-3'>
                                                    <i className="fa fa-spinner fa-spin" style={{ fontSize: "30px", color: "#ff6363" }} />
                                                </div>
                                                : ""}

                                        </div>
                                    </InfiniteScroll>

                                </div>
                                <div className="btnTab">
                                    <button className="create-modal-btn" onClick={handleSave}> {loader.saveLoader ? <>Using <i className="fa fa-spinner fa-spin mx-1" /></> : "Use"}</button>
                                    <button className="create-modal-btn ml-2" onClick={props.handleClose}>Cancel</button>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <UploadImage
                                    handleClose={props.handleClose}
                                    callbackFun={props.callbackFun}
                                    iconIndex={props.iconIndex}
                                    aId={props.aId}
                                    cId={props.cId}
                                />
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ImagesModal