import produce from "immer";
const initialState = {
    data: {
        id: "",
        name: "",
        email: "",
        description: "",
        address: "",
        logo: "",
        mode: ""
    },
    integration: {
        facebook: [],
        youtube: [],
        getResponse: [],
        mailchimp: [],
        aweber: [],
        mailvio: [],
    }

}

export const AgencyReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_AGENCY_DATA":
            return produce(state, (draft) => {
                draft.data = action.payload
            })

        case "ON_AGENCY_CHANGE":
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.payload.name]: action.payload.value
                }
            }
        case "ON_SET_LOGO":
            return produce(state, (draft) => {
                draft.data.logo = action.payload
            })

        case "SET_INTEGRATION_LIST":
            return produce(state, (draft) => {
                draft.integration = action.payload
            })
        default:
            return state
    }

}