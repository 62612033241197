import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { onChangeFooter } from '../../Actions/ClientsAction'

const FooterSection = () => {
    const dispatch = useDispatch()
    const landingData = useSelector(state => state.client.landing)

    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(onChangeFooter(name, value))
    }
    return (
        <div>
            <div className="row">
                <div className="col-6">
                    <div className="inpLabelWrap">
                        <span className="labelTxt">Terms Of Service Text</span>
                        <div className="inpLabel">
                            <label htmlFor="tosText">Terms Of Service Text</label>
                            <input
                                value={landingData.footer.tosText}
                                name="tosText"
                                onChange={handleChange}
                                className="inpLabel-inp"
                                placeholder="Enter Terms Of Service Text"
                                type="text"
                            />
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="inpLabelWrap">
                        <span className="labelTxt">Privacy Policy Text</span>
                        <div className="inpLabel">
                            <label htmlFor="privacyText">Privacy Policy Text</label>
                            <input
                                value={landingData.footer.privacyText}
                                name="privacyText"
                                onChange={handleChange}
                                className="inpLabel-inp"
                                placeholder="Enter Privacy Policy Text"
                                type="text"
                            />
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="inpLabelWrap">
                        <span className="labelTxt">Terms Of Service URL</span>
                        <div className="inpLabel">
                            <label htmlFor="tosUrl">Terms Of Service URL</label>
                            <input
                                value={landingData.footer.tosUrl}
                                name="tosUrl"
                                onChange={handleChange}
                                className="inpLabel-inp"
                                placeholder="Enter Term Of Service URL with http:// or https://"
                                type="text"
                            />
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="inpLabelWrap">
                        <span className="labelTxt">Privacy Policy URL</span>
                        <div className="inpLabel">
                            <label htmlFor="privacyUrl">Privacy Policy URL</label>
                            <input
                                value={landingData.footer.privacyUrl}
                                name="privacyUrl"
                                onChange={handleChange}
                                className="inpLabel-inp"
                                placeholder="Enter Term Of Service URL with http:// or https://"
                                type="text"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="inpLabelWrap">
                <span className="labelTxt">Copyright Text</span>
                <div className="inpLabel">
                    <label htmlFor="copyrightText">Copyright Text</label>
                    <input
                        value={landingData.footer.copyrightText}
                        name="copyrightText"
                        onChange={handleChange}
                        className="inpLabel-inp"
                        placeholder="Enter Copyright Text"
                        type="text"
                    />
                </div>
            </div>
        </div>
    )
}

export default FooterSection