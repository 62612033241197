import produce from "immer"

const initialState = {
    videoCamp: false,
    invoice: false
}

export const ResourceReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_VIDEO_CAMPAIGN_INFO":
            return produce(state, (draft) => {
                draft.videoCamp = action.payload
            })

        case "SET_INVOICE_DATA":
            return produce(state, (draft) => {
                let obj = { ...action.payload }
                if (obj.data !== "") {
                    obj.data = JSON.parse(obj.data)
                }
                draft.invoice = obj
            })

        case "SET_DATA":
            return produce(state, (draft) => {
                draft.invoice.data = action.payload
            })

        // case "SET_DATA_INVOICE":
        //     return produce(state,(draft)=>{
        //         draft.invoice.data = action.payload
        //     })

        case "ON_UPDATE_ROW":
            return produce(state, (draft) => {
                draft.invoice.data.invoiceRow = action.payload
            })

        case "ON_UPDATE_TABLE":
            return produce(state, (draft) => {
                draft.invoice.data.table = action.payload
            })

        case "ON_UPDATE_INVOICE_DATA":
            return produce(state, (draft) => {
                draft.invoice = {
                    ...draft.invoice,
                    data: {
                        ...draft.invoice.data,
                        [action.payload.name]: action.payload.value
                    }
                }
            })

        case "UNMOUNT_INVOICE":
            return produce(state, (draft) => {
                draft.invoice = false
            })

        // case "ADD_HEADING_DATA":
        //     return produce(state, (draft) => {
        //         draft.invoice.data.headingText = {
        //             ...draft.invoice.data.headingText,
        //             [action.payload.type] : action.payload.value
        //         }
        //     })

        // case "ADD_ROWS_DATA":
        //     return produce(state, (draft) => {
        //         draft.invoice.data = action.payload
        //     })

        // case "ADD_PAY_INFORMATION":
        //     return produce(state, (draft) => {
        //         draft.invoice.data = action.payload
        //     })        

        default:
            return state
    }
}