import React, { useState } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import facebookIcon from "../../images/logo-facebook.svg"
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import SweetAlertTwo from "../SweetAlertTwo";
import { onDeleteAutoRes } from "../../Actions/ClientsAction";
import { saveFacebook } from "../../Actions/AgencyAction";

const ConnectFacebook = (props) => {

    const dispatch = useDispatch();
    let location = useLocation()
    const aId = queryString.parse(location.search).id
    const cId = queryString.parse(location.search).cId
    const auth = useSelector(state => state.auth);
    const [loader, setLoader] = useState(false);


    // Sweetalert
    const [info, setInfo] = useState({
        id: -1,
        type: "",
        enable: false
    })
    const deleteIntegration = (id) => {
        setInfo({
            ...info,
            id: id,
            enable: true
        })
    }
    const handleCancle = () => {
        setInfo({
            ...info,
            id: -1,
            enable: false
        })
    }

    const deleteAutoRes = (Swal) => {
        let data = {
            id: info.id,
        }
        setInfo({
            ...info,
            id: -1,
            enable: false
        })
        dispatch(onDeleteAutoRes(data, props.fetchIntegration, Swal))
    }

    const [details, setDetails] = useState({
        name: false,
        userId: false,
        accessToken: false,
        openPopup: false,
        buttonText: "Connect",
        email: false,
        pages: [],
    });


    const responseFacebook = async (response) => {
        setLoader(true);
        const data = {
            user_id: auth.user.id,
            title: response.name,
            token: response.accessToken,
            fb_id: response.userID,
            category: response.userID,
            agencyId: aId,
            clientId: cId,
        };
        await dispatch(saveFacebook(data, props.fetchIntegration));
        setDetails({ ...details, openPopup: false });
        setLoader(false);
    }

    return (
        <>
            <div className="connection-box mt-3" id="facebook">

                <div className="connection-box-main">
                    <div className={`connection-img ${props.data.length > 0 ? '' : 'disable-img'}`}>
                        <img src={facebookIcon} width="56px" alt="facebook" />
                    </div>

                    <div className="connection-txt">
                        {
                            props.data.length > 0 ?
                                props.data.map((item, index) => {
                                    return (
                                        <div>
                                            <div key={index} className="facebook-connect-account text-white d-flex justify-content-between  align-items-center">
                                                <div className="facebook-content">
                                                    <h6>Facebook</h6>
                                                    <p className="m-0">{item?.name} - Added on {item.created}</p>
                                                </div>
                                                <div className="facebook-delete">
                                                    <button type="button" onClick={(e) => deleteIntegration(item.id)} className="btn-change7">Delete</button>
                                                </div>
                                            </div>
                                            <div className="facebook-connect-account d-flex justify-content-center  mt-4">
                                                <FacebookLogin
                                                    appId="1207633949896301" //APP ID NOT CREATED YET
                                                    fields="name,email"
                                                    scope="ads_management,business_management,pages_manage_ads,pages_read_engagement,pages_show_list"
                                                    callback={(e) => responseFacebook(e)}
                                                    render={renderProps => (
                                                        <button onClick={renderProps.onClick} className="btn-change7">Connect New Account</button>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <div className="noConnect">
                                    <div className="youbtube-connection text-white d-flex align-items-center  justify-content-between">
                                        <div className="youtube-content">
                                            <h6>Facebook</h6>
                                            <p className="m-0">Not Connected</p>
                                        </div>
                                    </div>
                                    <div className="facebook-connect-account">
                                        <FacebookLogin
                                            appId="1207633949896301" //APP ID NOT CREATED YET
                                            fields="name,email"
                                            scope="ads_management,business_management,pages_manage_ads,pages_read_engagement,pages_show_list"
                                            callback={(e) => responseFacebook(e)}
                                            render={renderProps => (
                                                <button onClick={renderProps.onClick} className="btn-change7">Connect</button>
                                            )}
                                        />
                                    </div>
                                </div>
                        }
                    </div>
                </div>


                <div className="row d-none">
                    <div className="col-md-1 d-flex justify-content-center">
                        <div className={`connection-img ${props.data.length > 0 ? '' : 'disable-img'}`}>
                            <img src={facebookIcon} width="56px" alt="facebook" />
                        </div>
                    </div>
                    <div className="col-md-11">
                        {
                            props.data.length > 0 ?
                                props.data.map((item, index) => {
                                    return (
                                        <div key={index} className="facebook-connect-account text-white d-flex justify-content-between  align-items-center">
                                            <div className="facebook-content">
                                                <h6>Facebook</h6>
                                                <p className="m-0">{item?.username} - Added on {item.created}</p>
                                            </div>
                                            <div className="facebook-delete">
                                                <button type="button" onClick={(e) => deleteIntegration(item.id)} className="btn-change7">Delete</button>
                                            </div>
                                        </div>
                                    )
                                })
                                : <div className="youbtube-connection text-white d-flex align-items-center  justify-content-between">
                                    <div className="youtube-content">
                                        <h6>Facebook</h6>
                                        <p className="m-0">Not Connected</p>
                                    </div>
                                </div>
                        }

                        <div className="facebook-connect-account d-flex justify-content-center  mt-4">
                            <FacebookLogin
                                appId="1207633949896301" //APP ID NOT CREATED YET
                                fields="name,email"
                                scope="ads_management,business_management,pages_manage_ads,pages_read_engagement,pages_show_list"
                                callback={(e) => responseFacebook(e)}
                                render={renderProps => (
                                    <button onClick={renderProps.onClick} className="btn-change7">Connect</button>
                                )}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <SweetAlertTwo
                show={info.enable}
                title="Are You Sure?"
                text="You won't be able to revert this!"
                icon="warning"
                showCancelButton={true}
                confirmButtonColor='#3085d6'
                cancelButtonColor='#d33'
                confirmButtonText='Yes, delete it!'
                confirmTitle='Deleting...'
                confirmText="This won't be revert!"
                confirmIcon='success'
                handlePerform={deleteAutoRes}
                onCancel={handleCancle}
            />
        </>
    )
}

export default ConnectFacebook;