import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import { onFecthUploadImage, onUploadImageLibrary } from '../../Actions/ClientsAction'

const UploadSearch = ({ addImage, canvas, aId, cId }) => {
    const dispatch = useDispatch()
    const [imageList, setImageList] = useState([])
    const [selected, setSelected] = useState(false)
    const [loader, setLoader] = useState({
        imageLoader: false,
    })

    const fecthImageList = () => {
        let data = {
            type: "images",
            agencyId: aId,
            clientId: cId
        }
        dispatch(onFecthUploadImage(data, setImageList))
    }
    const onUploadFile = (e) => {
        let imageType = ['image/png', 'image/jpg', 'image/jpeg']
        const fileData = e.target.files[0]
        if (imageType.includes(fileData.type)) {
            if (fileData.size < 5000000) {
                setLoader({
                    ...loader,
                    imageLoader: true
                })
                const formData = new FormData()
                formData.append('upload_type', "images")
                formData.append('file', fileData)
                formData.append('agencyId', aId)
                formData.append('clientId', cId)
                dispatch(onUploadImageLibrary(formData, fecthImageList, loader, setLoader))
            } else {
                Swal.fire({
                    icon: "error",
                    title: 'Oops...',
                    text: "Max allowed size is 5MB!",
                    confirmButtonColor: "#d03e3e"
                })
            }
        } else {
            Swal.fire({
                icon: "error",
                title: 'Oops...',
                text: "This file type is not valid. Please use JPG, JPEG or PNG file only!",
                confirmButtonColor: "#d03e3e"
            })
        }
    }

    const onImageClick = (url, index) => {
        setSelected(index)
        console.log(url)
        addImage(canvas, url)
    }


    useEffect(() => {
        fecthImageList()
    }, [])


    return (
        <>
            <div className="uplaod-img mt-3" style={{ cursor: "pointer" }}>
                <div className="upload_text">
                    {loader.imageLoader ? <i style={{ fontSize: "20px", color: "#fff" }} className="fa fa-spinner fa-spin mr-2" /> : <><i className="fa fa-cloud-upload mr-2" /> Upload an image</>}
                </div>
                <input
                    readOnly
                    onChange={(e) => onUploadFile(e)}
                    id="upload"
                    className={`img-upload-input ${loader.imageLoader ? 'hide-social' : ''}`}
                    type="file"
                    accept=".png, .jpg, .jpeg, .PNG, .JPG, .JPEG"
                />
            </div>
            <hr style={{ color: "#767474", opacity: "1" }} />
            {
                imageList.length > 0 ?
                    <div className="row mt-2">
                        {
                            imageList.map((item, index) => {
                                return (
                                    <div className="col-md-4" key={index} style={{ marginBottom: "10px" }}>
                                        <div className="img-part cursor-pointer" onClick={() => onImageClick(item.url, index)}>
                                            <img width="100%" src={item.url} className="img-fluid" alt="" />
                                            {
                                                selected === index ?
                                                    <span className="checked-icon" style={{
                                                        color: "#ff6363",
                                                        top: "20px",
                                                        left: "50px",
                                                        fontSize: 25,
                                                        position: "absolute"
                                                    }}>
                                                        <i className="far fa-check-circle" />
                                                    </span> : ''
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    : <p className="text-center no_upload">You do not have any uploads</p>
            }
        </>
    )
}

export default UploadSearch