import styled from "styled-components"
import invoice2 from "../../../images/invoice3_bg.png"
export const Invoice5style = styled.div`
  
.invoice-3 {
    background: #f3f2f2;
    padding: 30px 0;
}

.invoice-3 h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    color: #353535;
}

.container {
    max-width: 1680px;
    margin: 0 auto;
}

.invoice h1 {
    color: #fff !important;
    font-size: 50px;
}

.invoice-3 .mb-30 {
    margin-bottom: 30px;
}

.invoice-3 .invoice-info {
    background: #fff;
    position: relative;
    z-index: 0;
}

.invoice-3 .inv-header-1 {
    font-weight: 600;
    font-size: 20px;
    color: #262525;
}

.invoice-3 .inv-title-1 {
    color: #262525;
}

.invoice-3 .order-summary h4 {
    font-size: 20px;
    margin: 0 0 20px;
    font-weight: 500;
    color: #262525;
}

.invoice-3 .order-summary .table-outer {
    overflow-y: hidden;
    overflow-x: auto;
}

.invoice-3 .order-summary .default-table thead th {
    position: relative;
    padding: 20px 30px;
    font-size: 15px;
    color: #353535;
    font-weight: 500;
    line-height: 30px;
    white-space: nowrap;
}

.invoice-3 .order-summary .default-table tr td {
    position: relative;
    padding: 21px 30px;
    font-size: 14px;
    color: #535353;
    font-weight: 400;
}

.invoice-3 .payment-info {
    max-width: 260px;
    margin-left: auto;
}

.invoice-3 .order-summary .default-table tr td strong {
    font-weight: 500;
}

.invoice-3 .order-summary .default-table {
    background: #fff;
    border: 0;
    width: 100%;
}

.invoice-3 .order-summary tbody,
td,
tfoot,
th,
thead,
tr {
    border: solid 1px #f3f2f2;
}

.invoice-3 p {
    color: #535353;
}

.invoice-3 strong {
    text-transform: initial !important;
}

.invoice-3 .logo img {
    height: 25px;
}

.invoice-3 .invoice-name p {
    margin-bottom: 0;
}

.invoice-3 .invoice-center {
    padding: 50px;
    background: #f7f7f7;
}

.invoice-3 .invoice-headar {
    padding: 50px;
    border-bottom: solid 1px #d8d8d8;
    background: linear-gradient(89deg, #d12700 12.33%, #f31818 33.75%, #a309c9 60.02%, #b10c0c 89.93%);
}

.invoice-3 .invoice-top {
    padding: 50px 0;
}

.invoice-3 .invoice-bottom {
    padding: 50px 50px 20px;
}

@media(max-width: 992px) {
    .invoice-3 {
        padding: 30px 0;
    }
}

@media(max-width: 768px) {
    .invoice-3 .invoice-top {
        padding: 30px 30px 0;
    }

    .invoice-3 .invoice-center {
        padding: 30px;
    }

    .invoice-3 .invoice-name {
        text-align: left;
        float: left;
    }

    .invoice-3 .invoice-bottom {
        padding: 30px 30px 0;
    }

    .invoice-3 .invoice-headar {
        padding: 30px;
    }

    .invoice-3 .order-summary .default-table thead th {
        padding: 10px 12px;
    }

    .invoice-3 .order-summary .default-table tr td {
        padding: 10px 12px;
    }

    .invoice-3 {
        padding: 15px 0;
    }
}

@media(max-width: 580px) {
    .invoice-3 .text-end {
        text-align: left !important;
    }

    .invoice-3 .payment-info {
        margin: 0 auto 30px 0;
        width: 100%;
    }

    .invoice-3 .logo img {
        margin-bottom: 10px;
    }
}

`;