import React, { useEffect, useState } from "react";
import { FiEdit, FiTrash2, FiExternalLink } from "react-icons/fi";
import { AiOutlineUserAdd } from "react-icons/ai"
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { onDeleteResources } from "../../../Actions/ClientsAction";
import SweetAlertTwo from "../../SweetAlertTwo";
import { onCheckStatus } from "../../../Actions/VideoAction";
import { GoDesktopDownload } from 'react-icons/go'
import ResourceListTable from "./ResourceListTable";
import UserObtained from "./UserObtained";

let rendCheck
const ListRows = (props) => {
  
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [state, setState] = useState(false)
  const [user, setUser] = useState(false)
  const [checkData, setCheckData] = useState({
    renderProgress: props.status,
    renderStatus: ""
  })
  const [info, setInfo] = useState({
    id: -1,
    type: "",
    enable: false,
  });
  const handleDelete = () => {
    setInfo({
      ...info,
      id: props.id,
      type: props.type,
      enable: true,
    });
  };

  const handleDownload = () => {
    let a = document.createElement('a')
    a.href = props.videoUrlData
    a.type = "download"
    a.click()
  }

  const handleCancle = () => {
    setInfo({
      ...info,
      id: -1,
      type: "",
      enable: false,
    });
  };

  const handleUser = (id, name) => {
    props.userData(true)
    props.userIdData(id)
    props.userName(name)
  }

  const deleteResources = (Swal) => {
    let data = {
      id: info.id,
      type: info.type,
    };
    setInfo({
      ...info,
      id: -1,
      type: "",
      enable: false,
    });
    dispatch(onDeleteResources(data, props.fetchResourceList, Swal));
  };

  const handleOpenLanding = () => {
    window.open(props.landingUrl, "_blank");
  };

  const checkStatus = () => {
    let data = {
      videoScriptId: props.scriptId,
    }
    dispatch(onCheckStatus(data, setState, checkData, rendCheck, setCheckData, clearInterval, props.fetchResourceList))

  }

  useEffect(() => {
    if (props.type === "youtube" || props.type === "adds") {
      if (+state === 6 || +state === 7 || +state === 9 || +state === 5) {
        rendCheck = setInterval(() => {
          checkStatus()
        }, 5000)
      }
    }
    return () => {
      clearInterval(rendCheck)
    }
  }, [state])

  useEffect(() => {
    if (props.type === "youtube" || props.type === "adds") {
      if (+props.status === 2 || +props.status === 3 || +props.status === 0 || +props.status === 4) {
        setState(+props.status)
      } else {
        checkStatus()
      }
    }
  }, [props.status])



  return (
    <tr>
      <td>
        <div className="proThumb">
          <img src={props.logo} alt="" />
        </div>
      </td>
      <td>{props.name}</td>
      <td className="text-capitalize">
        {props.type === "blogArticles"
          ? "Blog Article"
          : props.type === "landingPage"
            ? "Landing Page"
            : props.type === "pressRelease"
              ? "Press Release"
              : props.type === "businessCard"
                ? "Business Card"
                : props.type === "letterhead"
                  ? "Letter Head"
                  : props.type === "LeadMagnet"
                    ? "Lead Magnet"
                    : props.type === "social-media-covers"
                      ? "Social Media Cover"
                      : props.type === "invoice"
                        ? "invoice"
                        : props.type}
      </td>
      <td>
        <span className={`pMode ${+props.status ? "live" : "sandbox"} `} style={props.type === "youtube" || props.type === "adds" ? { background: +state === 5 ? "#4169E1" : +state === 2 ? "green" : +state === 6 ? "#DAA520" : +state === 4 || +state === 0 ? "gray" : "#e58697" } : {}}>
          {props.type === "youtube" || props.type === "adds"
            ? +state === 0
              ? "User Approval Pending"
              : +state === 2
                ? "Render Successful"
                : +state === 3
                  ? "Render Failed"
                  : +state === 4
                    ? "Awaiting Customization"
                    : +state === 5
                      ? "Downloading Resources"
                      : +state === 6
                        ? `Rendering ${checkData.renderProgress}%`
                        : +state === 7
                          ? "Added To Local Queue"
                          : +state === 9
                            ? "Added To Render Queue"
                            : ""
            : +props.status
              ? "Active"
              : "Inactive"}
        </span>
      </td>
      <td>
        {auth.user.isClient === "0" ? (
          <>
            {props.type === "youtube" || props.type === "adds" ? (
              <Link
                className="roundBox"
                to={
                  +state === 0 ? props.scriptId ? `/agency/video-script?id=${props.scriptId}` : `/agency/create-topic?aId=${props.aId}&cId=${props.cId}&id=${props.id}` : `/agency/editor?id=${props.id}`
                }
              >
                <FiEdit title="Edit" />
              </Link>
            ) : props.type === "Facebook Ads" ||
              props.type === "Google Ads" ||
              props.type === "LeadMagnet" ||
              props.type === "pressRelease" ||
              props.type === "businessCard" ||
              props.type === "letterhead" ||
              props.type === "envelope" ||
              props.type === "invoice" ||
              props.type === "image" ||
              props.type === "social-media-covers" ||
              props.type === "blogArticles"
              ? (
                <Link
                  className="roundBox"
                  to={`/agency/${props.type === "Facebook Ads"
                    ? "create-facebook"
                    : props.type === "LeadMagnet"
                      ? "create-lead-magnet"
                      : props.type === "invoice"
                        ? "invoice-editor"
                        : props.type === "pressRelease"
                          ? "create-press"
                          : props.type === "businessCard" ||
                            props.type === "letterhead" ||
                            props.type === "envelope" ||
                            props.type === "image" ||
                            props.type === "social-media-covers" ? "image-editor" :
                            props.type === "blogArticles" ? "create-blog"
                              : "create-google"
                    }?aId=${props.aId}&cId=${props.cId}&id=${props.id}`}
                >
                  <FiEdit title="Edit" />
                </Link>
              ) : (
                <Link
                  className="roundBox"
                  to={`/agency/edit-landing-page?id=${props.id}`}
                >
                  <FiEdit title="Edit" />
                </Link>
              )}


            <span className="roundBox" onClick={handleDelete}>
              <FiTrash2 title="Delete" />
            </span>

            {props.type === "landingPage" ? <span className="roundBox" onClick={() => handleUser(props.id, props.name)}>
              <AiOutlineUserAdd title="User" />
            </span> : ""}
          </>
        ) : (
          ""
        )}
        {props.type === "landingPage" ? (
          <span className="roundBox" onClick={handleOpenLanding}>
            <FiExternalLink title="Preview" />
          </span>
        ) : (
          ""
        )}
        {(props.type === "youtube" || props.type === "adds") && +state === 2 ?
          <span className="roundBox" onClick={handleDownload}>
            <GoDesktopDownload title="Download" />
          </span> : ""}
      </td>

      <SweetAlertTwo
        show={info.enable}
        title="Are You Sure?"
        text="You won't be able to revert this!"
        icon="warning"
        showCancelButton={true}
        confirmButtonColor="#3085d6"
        cancelButtonColor="#d33"
        confirmButtonText="Yes, delete it!"
        confirmTitle="Deleting..."
        confirmText="This won't be revert!"
        confirmIcon="success"
        handlePerform={deleteResources}
        onCancel={handleCancle}
      />
    </tr >
  );
};

export default ListRows;
