import React, { useState, useEffect } from "react";
import { AiOutlineCloudUpload } from "react-icons/ai"
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import queryString from "query-string";
import { onChangeAgency, onGetAgencyData, onSubmitAgency, onUploadImage } from "../../Actions/AgencyAction";
import Swal from "sweetalert2";

const CreateAgency = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const aId = queryString.parse(location.search).id
    const agency = useSelector(state => state.agency.data)

    const [loader, setLoader] = useState({
        pageLoader: true,
        imageLoader: false,
        submitLoader: false
    })
    const fetchAgencyData = () => {
        let data = {
            id: aId
        }
        dispatch(onGetAgencyData(data, loader, setLoader))
    }

    const handleChange = (e) => {
        const { name, value, checked } = e.target
        dispatch(onChangeAgency(name, value, checked))
    }
    const handleImage = (e) => {
        let imageType = ['image/png', 'image/jpg', 'image/jpeg']
        const fileData = e.target.files[0]
        if (imageType.includes(fileData.type)) {
            if (fileData.size < 5000000) {
                setLoader({
                    ...loader,
                    imageLoader: true
                })
                const formData = new FormData()
                formData.append('upload_type', "logo");
                formData.append('file', fileData);
                dispatch(onUploadImage("ON_SET_LOGO", formData, loader, setLoader))
            } else {
                Swal.fire({
                    icon: "error",
                    title: 'Oops...',
                    text: "Max allowed size is 5MB!",
                    confirmButtonColor: "#d03e3e"
                })
            }
        } else {
            Swal.fire({
                icon: "error",
                title: 'Oops...',
                text: "This file type is not valid. Please use JPG, JPEG or PNG file only!",
                confirmButtonColor: "#d03e3e"
            })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let data = { ...agency }
        setLoader({
            ...loader,
            submitLoader: true
        })
        dispatch(onSubmitAgency(data, loader, setLoader))
    }

    useEffect(() => {
        if (aId) {
            fetchAgencyData()
        }
    }, [aId])

    return (
        loader.pageLoader ?
            <div className="loader-sec">
                <div className="loader" />
            </div> :
            <>
                <div className="container">
                    <div className="business-wrap">

                        <div className="productNav nav mb-4">
                            <div className="nav-item"><Link className="nav-link" to="/agency"><i class="fa-sharp fa-solid fa-building me-2"></i>All Agencies</Link></div>
                            <div className="nav-item"><Link className="nav-link active" to={`/agency/create-agency?id=${aId}`}><i class="fa-regular fa-building me-2"></i>{agency.name}</Link></div>
                            <div className="nav-item"><Link className="nav-link" to={`/agency/clients?id=${aId}`}><i class="fa-solid fa-users me-2"></i>All Clients</Link></div>
                        </div>

                        <div className="business-top">
                            <h2>Update Agency</h2>
                            {/* <a className="demoLink mt-0" href="">Create Staff <FiArrowRight/> </a> */}
                        </div>
                        <form className="business-list" onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="inpLabelWrap mt-0">
                                        <span className="labelTxt">Name</span>
                                        <div className="inpLabel">
                                            <label htmlFor="name">Name</label>
                                            <input
                                                className="inpLabel-inp"
                                                placeholder="Add Name"
                                                type="text"
                                                name="name"
                                                value={agency.name}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="inpLabelWrap mt-0">
                                        <span className="labelTxt">Email</span>
                                        <div className="inpLabel">
                                            <label htmlFor="email">Email</label>
                                            <input
                                                className="inpLabel-inp"
                                                placeholder="Add Email"
                                                type="email"
                                                name="email"
                                                value={agency.email}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-md-6">
                                    <div className="inpLabelWrap">
                                        <span className="labelTxt">Description</span>
                                        <div className="inpLabel">
                                            <label htmlFor="description">Add Description</label>
                                            <textarea
                                                className="inpLabel-inp"
                                                name="description"
                                                type="text"
                                                placeholder="Description"
                                                value={agency.description}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="inpLabelWrap">
                                        <span className="labelTxt">Address</span>
                                        <div className="inpLabel">
                                            <label htmlFor="address">Add Address</label>
                                            <textarea
                                                className="inpLabel-inp"
                                                type="text"
                                                name="address"
                                                placeholder="Address"
                                                value={agency.address}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="inpLabel">
                                        <label htmlFor="">Upload Logo</label>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="style-upload-box alt">
                                                    <input
                                                        className="custom-file-input"
                                                        type="file"
                                                        onChange={handleImage}
                                                    />
                                                    <div className="">
                                                        <AiOutlineCloudUpload />
                                                        <h6>Upload Your Logo</h6>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-md-6">

                                                <div className="imgPrev m-auto " style={{ position: "relative" }}>
                                                    {loader.imageLoader ?
                                                        <i className="fa fa-spinner fa-spin mx-1" style={{
                                                            fontSize: "25px", color: "#d03e3e", position: "absolute", top: 'calc(50% - 10%)',
                                                            left: 'calc(50% - 13%)'
                                                        }} />
                                                        :
                                                        <img src={agency.logo} alt="" />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modBox mt-4">
                                <div className="inpLabel mt-0 align-items-center d-flex justify-content-between">
                                    <label htmlFor="">Mode</label>
                                    <div className="d-flex">
                                        <label className="switch flex">
                                            <input
                                                type="checkbox"
                                                name='mode'
                                                onChange={handleChange}
                                                checked={agency.mode === "1" ? true : false}
                                            />
                                            <span className="slider round"></span>
                                            <span className="slClose">Inactive</span>
                                            <span className="slOpen">Active</span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="btn-sec text-right">
                                <button className="demoLink" type="submit">{loader.submitLoader ? <> Updating Agency <i className="fa fa-spinner fa-spin mx-1" />  </> : "Update Agency"} </button>
                            </div>

                        </form>

                    </div>
                </div>
            </>
    )
}

export default CreateAgency;