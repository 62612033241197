import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { BsCheckCircle } from 'react-icons/bs';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { onfetchImages } from '../../../Actions/ClientsAction';
import modalCloseIcon from '../../../images/modal-close.png';

const IconsModal = (props) => {
    const dispatch = useDispatch()
    const [iconList, setIconList] = useState([])
    const [msg, setMsg] = useState("")
    const [loader, setLoader] = useState({
        saveLoader: false,
        fetchLoader: false
    })
    const [search, setSearch] = useState({
        searchKey: "",
        index: -1,
        page: 1,
        data: {}
    })


    const fetchImage = (page = 1) => {
        setLoader({
            ...loader,
            fetchLoader: true
        });
        setSearch({
            ...search,
            page: page
        })
        let data = {
            keyword: search.searchKey,
            type: "emoji",
            page_number: page
        }
        dispatch(onfetchImages(data, iconList, setIconList, setMsg, loader, setLoader))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        fetchImage()
    }

    const handleChange = (e) => {
        if (iconList.length > 0) {
            setIconList([])
        }
        if (msg !== "") {
            setMsg("")
        }
        setSearch({
            ...search,
            searchKey: e.target.value
        })
    }

    const onChooseImage = (curElem, index) => {
        setSearch({
            ...search,
            data: curElem,
            index: index
        })
    }

    const handleSave = () => {
        setLoader({
            ...loader,
            saveLoader: true
        })
        setTimeout(() => {
            props.callbackFun(search.data, props.iconIndex)
            props.handleClose()
            setLoader({
                ...loader,
                saveLoader: false
            })
        }, 500);

    }

    useEffect(() => {
        return () => {
            setIconList([])
            setSearch({
                ...search,
                searchKey: "",
                page: 1,
                data: {}
            })
            setMsg("")
        }
    }, [props.handleClose])


    return (
        <Modal className="VideoModal forImg" show={props.show} centered>
            <Modal.Body>
                <div onClick={props.handleClose} className="vidClose"><img alt="" src={modalCloseIcon} /></div>
                <div className="imgUp">
                    <div className='inpLabel m-0'>
                        <label style={{fontSize: 15}}>Icons</label>
                    </div>
                    <div className="imgUp-main mt-1">
                        <form className="img-search" onSubmit={handleSubmit}>
                            <input
                                type="text"
                                placeholder="Search Icon"
                                value={search.searchKey}
                                onChange={(e) => handleChange(e)}
                            />
                        </form>
                        <InfiniteScroll
                            dataLength={iconList.length} //This is important field to render the next data
                            next={() => fetchImage(search.page + 1)}
                            hasMore={true}
                            scrollableTarget={`scrollableIconModal`}
                        >
                            <div className="img-search-scroll" style={{ minHeight: "350px", maxHeight : "390px" }} id='scrollableIconModal'>
                                <ul className="iconList">
                                    {iconList.length > 0 ?
                                        iconList.map((curElem, index) => {
                                            return (
                                                <li key={index}>
                                                    <div
                                                        className="imgUp-single cursor-pointer"
                                                        style={{ border: search.index === index ? "1px solid #d84444" : "" }}
                                                        onClick={() => onChooseImage(curElem, index)}
                                                    >
                                                        <img src={curElem.local_url} alt={curElem.name} />
                                                        <div className="imgStat"><BsCheckCircle /></div>
                                                    </div>
                                                </li>
                                            )
                                        }) :
                                        <div className='text-center pt-3' style={{ margin: "auto" }}>
                                            {msg}
                                        </div>
                                    }
                                </ul>
                                {loader.fetchLoader ?
                                    <div className='text-center mt-3'>
                                        <i className="fa fa-spinner fa-spin" style={{ fontSize: "30px", color: "#ff6363" }} />
                                    </div>
                                    : ""}
                            </div>
                        </InfiniteScroll>
                    </div>
                    <div className="btnTab">
                        <button className="create-modal-btn" onClick={handleSave}> {loader.saveLoader ? <>Saving <i className="fa fa-spinner fa-spin mx-1" /></> : "Save"}</button>
                        <button className="create-modal-btn ml-2" onClick={props.handleClose}>Cancel</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default IconsModal