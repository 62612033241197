import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { onCreateAgency } from '../../Actions/AgencyAction';
import modalCloseIcon from '../../images/modal-close.png';

const AgencyModal = ({ show, handleClose }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false)
    const [state, setState] = useState({
        name: "",
        email: ""
    })

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        dispatch(onCreateAgency(state, setLoader, navigate))
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }
    return (
        <Modal className="VideoModal small" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <form onSubmit={handleSubmit}>
                    <div onClick={handleClose} className="vidClose"><img src={modalCloseIcon} alt="" /></div>
                    <div className="formSec">
                        <div className="inpLabelWrap mt-0">
                            <div className="inpLabel">
                                <label>Agency Name</label>
                                <input
                                    className="inpLabel-inp"
                                    placeholder="Agency Name Here"
                                    type="text"
                                    name="name"
                                    value={state.name}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>

                        <div className="inpLabelWrap">
                            <div className="inpLabel">
                                <label>Agency Email</label>
                                <input
                                    className="inpLabel-inp"
                                    name="email"
                                    type="email"
                                    onChange={handleChange}
                                    value={state.email}
                                    placeholder="Agency Email Here"
                                    required
                                />
                            </div>
                        </div>
                        {/* to="/create-agency" */}
                        <button type="submit" className="btn-block inpBtn mt-3 create-modal-btn">
                            {loader ? <>Creating Agency <i className="fa fa-spinner fa-spin mx-1" /></> : "Create Agency"}
                        </button>
                    </div>
                </form>

            </Modal.Body>
        </Modal>
    )
}

export default AgencyModal