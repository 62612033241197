import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { onChangeDays } from '../../Actions/ClientsAction'

const getDummyArr = (num) => {
    let arr = []
    for (let i = 0; i < num; i++) {
        arr[i] = {}
    }
    return arr
}

const DaysOpen = () => {
    const dispatch = useDispatch()
    const landingData = useSelector(state => state.client.landing)

    const enableDay = (e) => {
        let { name, value } = e.target
        dispatch(onChangeDays(name, value))
    }
    const [hours, setHours] = useState([])
    useEffect(() => {
        if (+landingData.contact.workingHours === 24) {
            setHours(getDummyArr(24))
        }
        else {
            setHours(getDummyArr(12))
        }
    }, [landingData.contact.workingHours])

    return (

        <>
            <div className="openHr-single">
                <div className="openHr-single-right">

                    <div className="timeFrom">
                        <div>FROM DAY</div>
                        <div
                            className="timeFrom-box"
                        >
                            <select
                                name="fromDay"
                                value={landingData.contact.daysOpen.fromDay}
                                onChange={(e) => enableDay(e)}
                                style={{ width: "100%" }}
                            >
                                <option value="">Select Day</option>
                                <option value='Sunday'>Sunday</option>
                                <option value='Monday'>Monday</option>
                                <option value='Tuesday'>Tuesday</option>
                                <option value='Wednesday'>Wednesday</option>
                                <option value='Thursday'>Thursday</option>
                                <option value='Friday'>Friday</option>
                                <option value='Saturday'>Saturday</option>

                            </select>

                        </div>
                    </div>
                    <div className="timeFrom">
                        <div>TO DAY</div>
                        <div
                            className="timeFrom-box"
                        >
                            <select
                                name="toDay"
                                value={landingData.contact.daysOpen.toDay}
                                onChange={(e) => enableDay(e)}
                                style={{ width: "100%" }}
                            >
                                <option value="">Select Day</option>
                                <option value='Sunday'>Sunday</option>
                                <option value='Monday'>Monday</option>
                                <option value='Tuesday'>Tuesday</option>
                                <option value='Wednesday'>Wednesday</option>
                                <option value='Thursday'>Thursday</option>
                                <option value='Friday'>Friday</option>
                                <option value='Saturday'>Saturday</option>

                            </select>

                        </div>
                    </div>
                </div>
            </div>
            <div className="openHr-single">

                <div className="openHr-single-right">
                    <div className="timeFrom">
                        <div>FROM TIME</div>
                        <div
                            className="timeFrom-box"
                        >
                            <select
                                name="fromTime"
                                value={landingData.contact.daysOpen.fromTime}
                                onChange={(e) => enableDay(e)}
                                style={{ width: +landingData.contact.workingHours === 24 ? "100%" : "50%" }}
                            >
                                {hours.length > 0 ?
                                    hours.map((curElem, index) => {
                                        return (
                                            <option key={index} value={`${index + 1}`}>{index + 1}:00</option>
                                        )
                                    }) : ""
                                }
                            </select>
                            {+landingData.contact.workingHours === 24 ? "" :
                                <select
                                    name="fromMeridiem"
                                    value={landingData.contact.daysOpen.fromMeridiem}
                                    onChange={(e) => enableDay(e)}
                                    style={{ width: "60%" }}
                                >
                                    <option value="AM">AM</option>
                                    <option value="PM">PM</option>
                                </select>}
                        </div>
                    </div>
                    <div className="timeFrom">
                        <div>TO TIME</div>
                        <div
                            className="timeFrom-box"
                        >
                            <select
                                name="toTime"
                                value={landingData.contact.daysOpen.toTime}
                                onChange={(e) => enableDay(e)}
                                style={{ width: +landingData.contact.workingHours === 24 ? "100%" : "50%" }}
                            >
                                {hours.length > 0 ?
                                    hours.map((curElem, index) => {
                                        return (
                                            <option key={index} value={`${index + 1}`}>{index + 1}:00</option>
                                        )
                                    }) : ""
                                }
                            </select>
                            {+landingData.contact.workingHours === 24 ? "" :
                                <select
                                    name="toMeridiem"
                                    value={landingData.contact.daysOpen.toMeridiem}
                                    onChange={(e) => enableDay(e)}
                                    style={{ width: "60%" }}
                                >
                                    <option value="AM">AM</option>
                                    <option value="PM">PM</option>
                                </select>}
                        </div>

                    </div>
                </div >
            </div >
        </>

    )
}

export default DaysOpen