import React from 'react'
import { BsFillPlusCircleFill } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux';
import { onAddMember, onChangeTeam } from '../../Actions/ClientsAction';
import TeamSectionRows from './TeamSectionRows';

const TeamSection = () => {
    const dispatch = useDispatch()
    const landingData = useSelector(state => state.client.landing)


    const handleChange = (e, index) => {
        const { name, value } = e.target
        dispatch(onChangeTeam(name, value, index))
    }



    const addMember = () => {
        dispatch(onAddMember())
    }


    return (
        <div >
            <div className="inpLabelWrap">
                <span className="labelTxt">Team Heading</span>
                <div className="inpLabel">
                    <label htmlFor="heading">Team Heading</label>
                    <input
                        className="inpLabel-inp"
                        placeholder="Enter Team Heading"
                        type="text"
                        name='heading'
                        value={landingData.team.heading}
                        onChange={handleChange}
                    />
                </div>
            </div>

            <div className="benefit-all">
                <div className="row">
                    {landingData.team.members.length > 0 ?
                        landingData.team.members.map((curElem, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <TeamSectionRows
                                        curElem={curElem}
                                        index={index}
                                        handleChange={handleChange}
                                    />
                                </React.Fragment>
                            )
                        }) : ""
                    }
                </div>
            </div>

            <div className="modBox mt-3" style={{ cursor: 'pointer' }}>
                <div className="inpLabel mt-0 align-items-center d-flex justify-content-between">
                    <label>Add More Member</label>
                    <span className="addIcon" onClick={addMember}><BsFillPlusCircleFill /></span>
                </div>
            </div>


        </div >
    )
}

export default TeamSection