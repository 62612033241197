import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import { Outlet } from "react-router-dom";

const Agency = () => {
    return (
        <>
            <TitleBar title="Agency" />
            <Navbar />
            <section className="siteWrap">
                <Outlet />
            </section>

            <Footer />
        </>
    )
}

export default Agency;