import React, { useEffect, useState } from "react";
import ConnectReelapps from "./ConnectReelapps";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import ConnectFacebook from "./ConnectFacebook";
import ConnectGoogle from "./ConnectGoogle";
import mailChimp_logo from "../../images/responder/responder-1.png"
import getResponse_logo from "../../images/responder/responder-3.png"
import awebar_logo from '../../images/responder/responder-2.png'
import Mailvio_logo from '../../images/responder/responder-16.png'
import { onFetchIntegrations } from "../../Actions/AgencyAction";
import SweetAlertTwo from "../SweetAlertTwo";
import { onDeleteAutoRes } from "../../Actions/ClientsAction";

const Integration = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const aId = queryString.parse(location.search).id
    const cId = queryString.parse(location.search).cId
    const inteData = useSelector(state => state.agency.integration)

    const [info, setInfo] = useState({
        id: -1,
        type: "",
        enable: false
    })
    const deleteIntegration = (id) => {
        setInfo({
            ...info,
            id: id,
            enable: true
        })
    }
    const handleCancle = () => {
        setInfo({
            ...info,
            id: -1,
            enable: false
        })
    }

    const deleteAutoRes = (Swal) => {
        let data = {
            id: info.id,
        }
        setInfo({
            ...info,
            id: -1,
            enable: false
        })
        dispatch(onDeleteAutoRes(data, fetchIntegration, Swal))
    }

    const fetchIntegration = () => {
        let data = {
            agencyId: aId,
            clientId: cId
        }
        dispatch(onFetchIntegrations(data))
    }

    useEffect(() => {
        if (aId && cId) {
            fetchIntegration()
        }
    }, [aId, cId])


    return (
        <>
            <div className="integration_wrapper">
                <div className="container">
                    <div className="row">
                        <section className="connection">
                            <div className="container">

                                <ConnectFacebook
                                    data={inteData.facebook}
                                    aId={aId}
                                    fetchIntegration={fetchIntegration}
                                />
                                <ConnectGoogle
                                    data={inteData.youtube}
                                    aId={aId}
                                    fetchIntegration={fetchIntegration}
                                />

                                <div className="business-top my-4">
                                    <h2 style={{ fontSize: "18px" }}>My Autoresponders</h2>
                                </div>
                                <ConnectReelapps
                                    icon={getResponse_logo}
                                    name="GetResponse"
                                    type="gr"
                                    data={inteData.getResponse}
                                    fetchIntegration={fetchIntegration}
                                />
                                <ConnectReelapps
                                    icon={awebar_logo}
                                    name="Aweber"
                                    type="aw"
                                    data={inteData.aweber}
                                    fetchIntegration={fetchIntegration}
                                />

                                <ConnectReelapps
                                    icon={mailChimp_logo}
                                    name="MailChimp"
                                    type="mc"
                                    data={inteData.mailchimp}
                                    fetchIntegration={fetchIntegration}
                                />
                                <ConnectReelapps
                                    icon={Mailvio_logo}
                                    name="Mailvio"
                                    type="mv"
                                    data={inteData.mailvio}
                                    fetchIntegration={fetchIntegration}
                                />
                            </div>
                        </section>

                    </div>
                </div>

            </div >
            <SweetAlertTwo
                show={info.enable}
                title="Are You Sure?"
                text="You won't be able to revert this!"
                icon="warning"
                showCancelButton={true}
                confirmButtonColor='#3085d6'
                cancelButtonColor='#d33'
                confirmButtonText='Yes, delete it!'
                confirmTitle='Deleting...'
                confirmText="This won't be revert!"
                confirmIcon='success'
                handlePerform={deleteAutoRes}
                onCancel={handleCancle}
            />
        </>
    )
}

export default Integration;