import styled from "styled-components"
import invoice2 from "../../../images/invoice3_bg.png"
export const Invoice6style = styled.div`
.table {
    color: #535353;
}

.invoice-content {
    font-family: 'Poppins', sans-serif;
    color: #535353;
    font-size: 14px;
}

.invoice-content a {
    text-decoration: none;
}

.invoice-content .img-fluid {
    max-width: 100% !important;
    height: auto;
}

.invoice-content .form-control:focus {
    box-shadow: none;
}

.container {
    max-width: 1680px;
    margin: 0 auto;
}

.invoice-content h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: 'Poppins', sans-serif;
    color: #535353;
}

.mb-0 {
    margin-bottom: 0;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-30 {
    margin-bottom: 30px;
}



/** BTN LG **/
.btn-lg {
    font-size: 14px;
    height: 50px;
    padding: 0 30px;
    line-height: 50px;
    border-radius: 3px;
    color: #ffffff;
    border: none;
    margin: 0 3px 3px;
    display: inline-block;
    vertical-align: middle;
    -webkit-appearance: none;
    text-transform: capitalize;
    transition: all 0.3s linear;
    z-index: 1;
    position: relative;
    overflow: hidden;
    text-align: center;
}

.btn-lg:hover {
    color: #ffffff;
}

.btn-lg:hover:after {
    transform: perspective(200px) scaleX(1.05) rotateX(0deg) translateZ(0);
    transition: transform 0.9s linear, transform 0.4s linear;
}

.btn-lg:after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: "";
    transform: perspective(200px) scaleX(0.1) rotateX(90deg) translateZ(-10px);
    transform-origin: bottom center;
    transition: transform 0.9s linear, transform 0.4s linear;
    z-index: -1;
}

.btn-check:focus+.btn,
.btn:focus {
    outline: 0;
    box-shadow: none;
}

.btn-print {
    background-image: linear-gradient(to bottom, #54544d, #1a1918);
}

.btn-print:after {
    background-image: linear-gradient(to bottom, #1a1918, #54544d);
}

.invoice-content .f-w-600 {
    font-weight: 500 !important;
}

.invoice-content .text-14 {
    font-size: 14px;
}

.invoice-content .invoice-table th:first-child,
.invoice-content .invoice-table td:first-child {
    text-align: left;
}

.invoice-content .color-white {
    color: #fff !important;
}

.invoice-content .inv-header-1 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 24px;
}

.invoice-content .inv-header-2 {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 20px;
}

.invoice-content .inv-title-1 {
    font-weight: 500;
    font-size: 16px;
}

.invoice-content .invo-addr-1 {
    font-size: 14px;
    margin-bottom: 20px;
    line-height: 23px;
}

.invoice-content .item-desc-1 {
    text-align: left;
}

.invoice-content .item-desc-1 span {
    display: block;
}

.invoice-content .item-desc-1 small {
    display: block;
}

.invoice-content .important-notes-list-1 {
    font-size: 14px !important;
    padding-left: 15px;
    margin-bottom: 15px;
}

.invoice-content .important-notes-list-1 li {
    margin-bottom: 5px;
}

.invoice-content .bank-transfer-list-1 {
    font-size: 13px !important;
    padding-left: 0px;
}

.invoice-content .important-notes {
    font-size: 12px !important;
}

.invoice-content .invoice-btn-section {
    text-align: center;
    margin-top: 27px;
}

table th {
    font-weight: 400;
}

.btn-download {
    background: #3965e3;
}

.btn-download:after {
    background: #325cd5;
}

.btn-print {
    background: #3a3939;
}

.btn-print:after {
    background: #1d1c1c;
}

.invoice-4 {
    background: #fff6f6;
}

.invoice-4 .mb-30 {
    margin-bottom: 30px;
}

.invoice-4 h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    color: #535353;
}

.invoice-4 .invoice-inner {
    background: #fff;
}

.invoice-4 .item-desc-1 span {
    font-size: 14px;
    font-weight: 500;
    color: #535353;
}

.invoice-4 .invoice-titel {
    padding: 20px 50px 15px;
    position: relative;
    z-index: 0;
}

.invoice-top {
    background-image: linear-gradient(to right bottom, #d16bb7, #b687db, #94a1f1, #76b6f8, #6cc8f4, #59c8f5, #40c7f6, #0bc7f6, #00b5ff, #009fff, #0084ff, #735ffb);
}

.invoice-4 .invoice-titel:before {
    content: "";
    width: 350px;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    border-radius: 0 30px 30px 0;
    background: #d03838;
}

.invoice-4 .invoice-titel:after {
    content: "";
    width: 350px;
    height: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
    border-radius: 30px 0 0 30px;
    background: #d03838;
}

.invoice-4 .bank-transfer-list-1 li strong {
    font-weight: 500;
}

.invoice-4 .fw-bold {
    font-weight: 500 !important;
}

.invoice-4 .item-desc-1 small {
    font-size: 13px;
    color: #535353;
}

.invoice-4 .invoice-top .logo img {
    height: 25px;
}

.invoice-4 .invoice-top {
    padding: 50px 50px 40px;
}

.invoice-4 .invoice-top .invoice-bg h1 {
    font-weight: 600;
    margin-bottom: 0;
    text-transform: uppercase;
    font-size: 60px;
    color: #ffff;
}

.invoice-4 .inv-title-1 {
    color: #262525;
}

.invoice-4 .invoice-info {
    padding: 50px 50px 20px;
}

.invoice-4 .invoice-info p {
    margin-bottom: 0;
}

.invoice-4 .invoice-titel h3 {
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 16px;
    color: #262525;
}

.invoice-4 .order-summary {
    padding: 0 50px 50px;
}

.invoice-4 .order-summary .table {
    margin-bottom: 0;
}

.invoice-4 .table td,
.invoice-4 .table th {
    padding: 13px 20px!important;
    vertical-align: top;
    border-top: 1px solid #e9ecef;
    border-right: 1px solid #e9ecef;
    text-align: center;
}

.invoice-4 .table> :not(caption)>*>* {
    box-shadow: none;
}

.invoice-4 .table tr,
.invoice-4 .table tr strong {
    font-size: 15px;
    text-transform: initial !important;
}

.invoice-4 .table>thead {
    vertical-align: bottom;
    font-weight: 500;
}

.invoice-4 .invoice-informeshon {
    padding: 0 50px 20px;
}

.invoice-4 .text-muted {
    color: #535353 !important;
}

.invoice-4 .invoice-informeshon p {
    font-size: 14px;
}

.invoice-4 .inv-title-1 {
    margin-bottom: 7px;
}

.invoice-4 thead th {
    font-weight: 500;
}

.invoice-4 .invoice-contact {
    padding: 25px 50px;
    z-index: -1;
    background: #e9e9e9;
}

.invoice-4 .bank-transfer-list-1 {
    list-style: none;
    padding: 0;
    font-size: 14px !important;
}

.invoice-4 .invoice-contact a {
    margin-right: 20px;
    color: #535353;
    font-size: 14px;
}

.invoice-4 .mr-0 {
    margin-right: 0;
}

@media (max-width: 992px) {
    .invoice-4 {
        padding: 30px 0;
    }
}

@media (max-width: 768px) {
    .invoice-4 .invoice-top {
        padding: 30px 30px 25px;
    }

    .invoice-4 .order-summary {
        padding: 0 30px 30px;
    }

    .invoice-4 .invoice-informeshon {
        padding: 0 30px;
    }

    .invoice-4 .invoice-contact {
        padding: 20px 30px;
    }

    .invoice-4 .invoice-top .logo img {
        height: 20px;
        margin-bottom: 5px;
    }

    .invoice-4 .invoice-top .invoice h1 {
        font-size: 24px;
    }

    .invoice-4 .invoice-titel {
        background: #e9e9e9;
        padding: 20px 30px 15px;
    }

    .invoice-4 .invoice-titel:before {
        display: none;
    }

    .invoice-4 .invoice-titel:after {
        display: none;
    }

    .invoice-4 .invoice-titel h3 {
        font-size: 16px;
    }

    .invoice-4 .invoice-info {
        padding: 30px 30px 0;
    }
}

@media (max-width: 580px) {
    .invoice-4 .invoice-top .invoice {
        float: left;
    }

    .invoice-4 .invoice-content .important-notes-list-1 {
        margin-bottom: 25px;
    }

    .invoice-4 .text-end {
        text-align: left !important;
    }

    .invoice-4 .invoice-name {
        margin-top: 20px;
        margin-bottom: 30px;
    }

    .invoice-4 .d-none-580 {
        display: none !important;
    }
}
`;