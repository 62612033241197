
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { setAlert } from '../../../Actions/AlertAction';
import modalCloseIcon from '../../../images/modal-close.png';
import { onSubmitLanding } from "../../../Actions/ClientsAction";

const KeywordModal = ({ state, setState, show, handleClose }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false);

    const handleChange = (e) => {
        setState({ ...state, keyword: e.target.value })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if (state.templateId !== -1) {
            setLoader({
                ...loader,
                submitLoader: true
            })
            dispatch(onSubmitLanding(state, loader, setLoader, navigate))
        } else {
            dispatch(setAlert("Please select industry.", "danger"))
        }
    }

    return (
        <Modal className="VideoModal small" show={show} centered>
            <Modal.Body>
                <form onSubmit={handleSubmit}>
                    <div onClick={handleClose} className="vidClose"><img src={modalCloseIcon} alt="" /></div>
                    <div className="formSec">
                        <div className="inpLabelWrap mt-0">
                            <div className="inpLabel">
                                <label>Enter The Keyword</label>
                                <input
                                    className="inpLabel-inp"
                                    placeholder="Enter Your keyword"
                                    type="text"
                                    value={state.keyword}
                                    onChange={(e) => handleChange(e)}
                                    required
                                />
                            </div>
                        </div>
                        <div className="alert alert-info mt-4">
                            <strong>NOTE:</strong> Please note that the AI can take upto 5 minutes for content to be generated. Please wait and do not go back or close this window.
                        </div>
                        <button type="submit" className="btn-block inpBtn mt-3 create-modal-btn">
                            {loader ? <> Next <i className="fa fa-spinner fa-spin mx-1" /> </> : "Next"}
                        </button>
                    </div>
                </form>

            </Modal.Body>
        </Modal>
    )
}

export default KeywordModal
