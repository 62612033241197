import React,{useState} from 'react'

const AgencyTableHeader = ({listData,setListData}) => {

    const [state,setState] = useState({
        name:"",
        clients:"",
    })

    const handleSort=(type)=>{
        let data=[...listData]
        if (type === "name") {
            if (state.name) {
              data.sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                  return 1;
                } else {
                  return -1;
                }
              });
              setState({
                ...state,
                name: false,
              });
            } else {
              data.sort((a, b) => {
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                  return 1;
                } else {
                  return -1;
                }
              });
              setState({
                ...state,
                name: true,
              });
            }
          } else if(type==="clients"){
            if(state.clients){
                data.sort((a, b) => {
                    if (a.totalClient < b.totalClient) {
                      return 1;
                    } else {
                      return -1;
                    }
                  });
                  setState({
                    ...state,
                    clients: false,
                  });
                } else {
                  data.sort((a, b) => {
                    if (a.totalClient > b.totalClient) {
                      return 1;
                    } else {
                      return -1;
                    }
                  });
                  setState({
                    ...state,
                    clients: true,
                  });
              } 
            }
        setListData(data)
    }

    return (
        <tr>
            <th width="150"></th>
            <th className='cursor-pointer'onClick={()=>handleSort("name")}>Name</th>
            <th className='cursor-pointer'>Email</th>
            <th className='cursor-pointer'>Mode</th>
            <th className='cursor-pointer'onClick={()=>handleSort("clients")}># Of Clients</th>
            <th></th>
            <th></th>
        </tr>
    )
}

export default AgencyTableHeader