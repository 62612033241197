import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import modalCloseIcon from '../../images/modal-close.png';
import { onFetchData, onSubmitAdset } from '../../Actions/ResourceAction';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();
const ModalSet = (props) => {
    const dispatch = useDispatch()
    const date = new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString().split('.')[0]

    const [countryList, setCountryList] = useState([])
    const [cityList, setCityList] = useState([])
    const [interestList, setInterestList] = useState([])
    const [loader, setLoader] = useState(false)

    const [key, setKey] = useState({
        country: "",
        city: "",
        interest: ""
    })

    const [state, setState] = useState({
        agencyId: props.aId,
        clientId: props.cId,
        facebookAccountId: props.acId,
        adAccountId: props.adAccountId,
        name: "",
        budgetType: "",
        budget: "",
        bidAmount: "",
        campaignId: props.campaignId,
        startTime: date,
        publishNow: false,
        ageMin: "",
        ageMax: "",
        gender: [],
        city: [],
        cities: [],
        country: [],
        countries: [],
        cutomeAudianceType: "",
        customeAudiance: [],
        customeAudiances: [],
        detailTargetType: "",
        interest: [],
        interests: []
    })


    const handleChange = (e) => {
        let { name, value, checked } = e.target
        if (name === "publishNow") {
            value = checked
        }
        setState({
            ...state,
            [name]: value
        })
    }

    const fetchData = (e, val) => {
        e.preventDefault()
        let data = {
            query: val === "country" ? key.country : val === "interest" ? key.interest : key.city,
            facebookAccountId: props.acId,
            clientId: props.cId,
            agencyId: props.aId,
            type: val === "interest" ? "interest" : "adgeolocation",
            value: val
        }

        let fun = setCityList
        if (val === "country") {
            fun = setCountryList
        } else if (val === "interest") {
            fun = setInterestList
        }
        dispatch(onFetchData(data, fun))
    }

    const handleKey = (e) => {
        const { name, value } = e.target
        setKey({
            ...key,
            [name]: value
        })
    }

    const handleSubmit = () => {
        setLoader(true)
        let data = { ...state }
        data.countries = state.country.map((curElem) => {
            return curElem.value
        })
        data.cities = state.city.map((curElem) => {
            return {
                key: curElem.value,
                radius: 10,
                distance_unit: "mile"
            }
        })
        data.interests = data.interest.map((curElem) => {
            return {
                id: curElem.id,
                name: curElem.label
            }
        })
        data.customeAudiances = data.customeAudiance.map((curElem) => {
            return {
                id: curElem.id,
                name: curElem.label
            }
        })
        dispatch(onSubmitAdset(data, setLoader, props.handleClose, props.state, props.setState, props.adSets, props.setAdSets))
    }

    // MultiSeletc stuff

    const onSelect = (name, e) => {
        setState({
            ...state,
            [name]: e
        })
    }

    useEffect(() => {
        setState({
            ...state,
            facebookAccountId: props.acId,
            adAccountId: props.adAccountId,
            campaignId: props.campaignId,
        })
    }, [props.state])

    return (
        <Modal className="VideoModal small" show={props.show} centered>
            <Modal.Body >
                <div className='container' style={props.type === "adCampaignId" ? {} : { overflowY: "auto", height: "650px", }}>
                    <h4>{props.type === "adCampaignId" ? "Create New Campaign" : "Create Ad Set"}</h4>
                    <div>
                        <div onClick={props.handleClose} className="vidClose"><img src={modalCloseIcon} alt="" /></div>
                        <div className="formSec">
                            {props.type === "adCampaignId" ?
                                <div className="inpLabelWrap mt-0">
                                    <div className="inpLabel">
                                        <label>Campaign Name</label>
                                        <input className="inpLabel-inp" placeholder="Campaign Name Here" type="text" name="campaignName" onChange={(e) => props.setNewCampaignName(e.target.value)} />
                                    </div>
                                </div> :
                                <>
                                    <div className='row '>

                                        <div className="inpLabelWrap col-md-12">
                                            <div className="inpLabel">
                                                <label>Name</label>
                                                <input className="inpLabel-inp" placeholder="Name" type="text" name="name" onChange={(e) => handleChange(e)} />
                                            </div>
                                        </div>

                                        <div className='col-md-12'>
                                            <span className="labelTxt  mt-3" style={{
                                                display: "block",
                                                fontSize: "13px",
                                                fontWeight: "bold",
                                                color: "#ff6363"
                                            }}>Budget</span>
                                        </div>
                                        <div className="inpLabelWrap col-md-4 ">
                                            {/* <span className="labelTxt">Budget Type</span> */}
                                            <div className="inpLabel">
                                                <label htmlFor="">Budget Type</label>
                                                <select className="inpLabel-inp" name="budgetType" onChange={handleChange}>
                                                    <option value={""}>Select</option>
                                                    <option value={"daily"}>Daily Budget</option>
                                                    <option value={"lifetime"}>Lifetime Budget</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="inpLabelWrap  col-md-4 ">
                                            <div className="inpLabel">
                                                <label>Budget</label>
                                                <input
                                                    className="inpLabel-inp"
                                                    placeholder="Budget"
                                                    type="number"
                                                    name="budget"
                                                    min={0}
                                                    value={state.budget}
                                                    onChange={(e) => handleChange(e)}
                                                />
                                            </div>
                                        </div>
                                        <div className="inpLabelWrap  col-md-4 ">
                                            <div className="inpLabel">
                                                <label>Bid Amount</label>
                                                <input
                                                    className="inpLabel-inp"
                                                    placeholder="Bid Amount"
                                                    type="number"
                                                    name="bidAmount"
                                                    min={0}
                                                    onChange={(e) => handleChange(e)}
                                                />
                                            </div>
                                        </div>

                                        <div className='col-md-12'>
                                            <span className="labelTxt  mt-3" style={{
                                                display: "block",
                                                fontSize: "13px",
                                                fontWeight: "bold",
                                                color: "#ff6363"
                                            }}>Age Group</span>
                                        </div>
                                        <div className="inpLabelWrap  col-md-6 ">
                                            <div className="inpLabel">
                                                <label >Min</label>
                                                <input
                                                    className="inpLabel-inp"
                                                    type="number"
                                                    min={18}
                                                    placeholder="Enter Minimum Age"
                                                    name="ageMin"
                                                    value={state.ageMin}
                                                    onChange={(e) => handleChange(e)}
                                                />
                                            </div>
                                        </div>
                                        <div className="inpLabelWrap  col-md-6 ">
                                            <div className="inpLabel">
                                                <label >Max</label>
                                                <input
                                                    className="inpLabel-inp "
                                                    placeholder="Enter Maxium Age"
                                                    type="number"
                                                    name="ageMax"
                                                    value={state.ageMax}
                                                    onChange={(e) => handleChange(e)}
                                                />
                                            </div>
                                        </div>

                                        <div className="inpLabelWrap  col-md-6 ">
                                            <div className="inpLabel">
                                                <label>Select A Gender</label>
                                                <select className="inpLabel-inp" name="gender" onChange={handleChange}>
                                                    <option value={""}>Default</option>
                                                    <option value={"1"}>Male</option>
                                                    <option value={"2"}>Female</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="inpLabelWrap  col-md-6 ">
                                            <div className="inpLabel">
                                                <label>Start Time</label>
                                                <input
                                                    onChange={(e) => handleChange(e)}
                                                    value={state.startTime}
                                                    className="inpLabel-inp text-uppercase"
                                                    placeholder='DD-MM-YYYY HH:MM'
                                                    name="startTime"
                                                    type="datetime-local"
                                                />

                                            </div>
                                        </div>

                                        <div className="inpLabelWrap  col-md-6">

                                        </div>
                                        <div className="inpLabelWrap  col-md-6">

                                        

                                            <div className="inpLabel d-flex align-items-center">
                                                <label className='custom-check'>
                                                <input
                                                    onChange={(e) => handleChange(e)}
                                                    checked={state.publishNow}
                                                    name="publishNow"
                                                    type="checkbox"
                                                    style={{ height: "15px" }}
                                                />
                                                    <span className='checkmark'></span>
                                                </label>
                                                <span className='pl-4 ml-2 mt-1'>Publish Now</span>
                                            </div>
                                        </div>


                                        <div className='col-md-12'>
                                            <span className="labelTxt  mt-3" style={{
                                                display: "block",
                                                fontSize: "13px",
                                                fontWeight: "bold",
                                                color: "#ff6363"
                                            }}>Geolocation</span>
                                        </div>

                                        <div className="inpLabelWrap  col-md-6 ">
                                            <div className="inpLabel">
                                                <label>Country</label>

                                                <form onSubmit={(e) => fetchData(e, "country")}>
                                                    <input
                                                        onChange={(e) => handleKey(e)}
                                                        value={key.country}
                                                        name="country"
                                                        placeholder='Country'
                                                        className="inpLabel-inp "
                                                        type="text"
                                                        required
                                                    />
                                                    <p style={{ fontSize: 13, paddingLeft: 5 }}> Note: Please hit enter after inserting country</p>
                                                </form>
                                                {countryList.length > 0 ?
                                                    <Select
                                                        // className="react-select-container"
                                                        closeMenuOnSelect={false}
                                                        components={animatedComponents}
                                                        defaultValue={state.country}
                                                        isMulti
                                                        options={countryList}
                                                        onChange={(e) => onSelect("country", e, "countries")}
                                                    />

                                                    : ""}
                                            </div>
                                        </div>

                                        <div className="inpLabelWrap  col-md-6 ">
                                            <div className="inpLabel">

                                                <label>City</label>
                                                <form onSubmit={(e) => fetchData(e, "city")}>
                                                    <input
                                                        onChange={(e) => handleKey(e)}
                                                        value={key.city}
                                                        name="city"
                                                        placeholder='City'
                                                        className="inpLabel-inp "
                                                        type="text"
                                                        required
                                                    />
                                                    <p style={{ fontSize: 13, paddingLeft: 5 }}> Note: Please hit enter after inserting city</p>
                                                </form>
                                                {cityList.length > 0 ?
                                                    <Select
                                                        // className="react-select-container"
                                                        closeMenuOnSelect={false}
                                                        components={animatedComponents}
                                                        defaultValue={state.city}
                                                        isMulti
                                                        options={cityList}
                                                        onChange={(e) => onSelect("city", e, "cities")}
                                                    />

                                                    : ""}
                                            </div>
                                        </div>

                                        <div className='col-md-12'>
                                            <span className="labelTxt  mt-3" style={{
                                                display: "block",
                                                fontSize: "13px",
                                                fontWeight: "bold",
                                                color: "#ff6363"
                                            }}>Custome Audiance (Optional)</span>
                                        </div>
                                        <div className="inpLabelWrap  col-md-6 ">
                                            <div className="inpLabel ">
                                                <select
                                                    className="inpLabel-inp pt-0"
                                                    name="cutomeAudianceType"
                                                    onChange={handleChange}
                                                    value={state.cutomeAudianceType}
                                                >
                                                    <option value={""}>Targeting Type</option>
                                                    <option value={"include"}>Include</option>
                                                    <option value={"exclude"}>Exclude</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="inpLabelWrap  col-md-6 ">
                                            <div className="inpLabel ">
                                                <Select
                                                    // className="react-select-container"
                                                    closeMenuOnSelect={false}
                                                    components={animatedComponents}
                                                    defaultValue={props.customeAudience}
                                                    isMulti
                                                    options={props.customeAudience}
                                                    onChange={(e) => onSelect(e, "customeAudiance")}
                                                />

                                            </div>
                                        </div>

                                        <div className='col-md-12'>
                                            <span className="labelTxt  mt-3" style={{
                                                display: "block",
                                                fontSize: "13px",
                                                fontWeight: "bold",
                                                color: "#ff6363"
                                            }}>Detailed Targeting (Optional)</span>
                                        </div>
                                        <div className="inpLabelWrap  col-md-6 ">
                                            <div className="inpLabel pt-0">
                                                <select
                                                    className="inpLabel-inp pt-0"
                                                    name="detailTargetType"
                                                    onChange={handleChange}
                                                    value={state.detailTargetType}
                                                >
                                                    <option value={""}>Targeting Type</option>
                                                    <option value={"include"}>Include</option>
                                                    <option value={"exclude"}>Exclude</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="inpLabelWrap col-md-6 ">
                                                <form  className="inpLabel" onSubmit={(e) => fetchData(e, "interest")}>
                                                    <label>Interest</label>
                                                    <input
                                                        onChange={(e) => handleKey(e)}
                                                        value={key.interest}
                                                        name="interest"
                                                        placeholder='Interest'
                                                        className="inpLabel-inp"
                                                        type="text"
                                                    />
                                                    <p style={{ fontSize: 13, paddingLeft: 5 }}> Note: Please hit enter after inserting interest</p>
                                                </form>
                                            {interestList.length > 0 ?
                                                <Select
                                                    // className="react-select-container"
                                                    closeMenuOnSelect={false}
                                                    components={animatedComponents}
                                                    defaultValue={state.interest}
                                                    isMulti
                                                    options={interestList}
                                                    onChange={(e) => onSelect("interest", e)}
                                                /> : ""}
                                        </div>
                                    </div>
                                </>
                            }
                            <button
                                onClick={() => {
                                    props.type === "adCampaignId" ?
                                        props.handleSubmit() :
                                        handleSubmit()
                                }}
                                className="btn-block inpBtn mt-3 create-modal-btn"
                            >
                                {
                                    props.type === "adCampaignId" ?
                                        props.loader.modalSubmit ?
                                            <>Creating <i className="fa fa-spinner fa-spin mx-1" /></> : "Create" : ""

                                }
                                {
                                    props.type !== "adCampaignId" ?
                                        loader ?
                                            <>Creating <i className="fa fa-spinner fa-spin mx-1" /></> : "Create" : ""

                                }
                            </button>
                        </div>
                    </div>
                </div>

            </Modal.Body>
        </Modal >
    )
}

export default ModalSet