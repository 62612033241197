import { setAlert } from "./AlertAction";
import { commonAxios } from "../Global/CommonAxios";

export const addSocialAccounts = (data) => (dispatch) => {
    dispatch({ type: 'ADD_SOCIAL_ACCOUNTS', payload: data })
}

export const fetchSocialAccounts = () => (dispatch, getState) => {
    commonAxios("fetch-social-accounts", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(addSocialAccounts(res.data));
            }
        }).catch((error) => {
            console.log(error)
        })
}




// export const onConnectMailvio =(data,setLoader)=>(dispatch,getState)=>{
//     commonAxios("autoresponder-auth-url",data,dispatch,getState().auth.token)
//     .then((res)=>{
//         if(res.status){
//             dispatch(setAlert(res.msg, "success"));
//             let obj = {
//                 agencyId: data.agencyId,
//                 clientId: data.clientId
//             }
//             dispatch(checkResponse( obj))
            
//         }else {
//             dispatch(setAlert(res.msg, "danger"));
//         }
//         setLoader(false)
//     }).catch((err) => {
//         console.log(err);
//         setLoader(false)
//     })
// }