import React, { useEffect, useState } from "react"
import { Invoice4style } from "./style"
import ContentEditable from "react-contenteditable"
import { VscDiffAdded, VscDiffRemoved } from 'react-icons/vsc'
import { IoCloseCircleOutline } from "react-icons/io5"
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { onInvoiceSave1, onSetInvoice, onSetInvoiceRow, onUpdateInvoiceData} from "../../../Actions/ResourceAction";
import { onFecthCards } from "../../../Actions/ResourceAction";

const Invoice_5 = () => {
  const dispatch = useDispatch();
  const location = useLocation()
  const invoiceId = queryString.parse(location.search).id
  const aId = queryString.parse(location.search).aId
  const cId = queryString.parse(location.search).cId

  const invoice =  useSelector(state => state.resource.invoice) 
    const [loader,setLoader] = useState(false)
    const [state, setState] = useState({
        invoiceNumber: "#45613",
        invoiceData: "24 Jan 2022",
        toName: "Jhon Smith",
        toAddress: "Theme Vessel <br> info@themevessel.com <br> 21-12 Green Street, Meherpur, Bangladesh <br>",
        fromName: "Animas Roky",
        fromAddress: "Apexo Inc <br> billing@apexo.com <br>169 Teroghoria, Bangladesh <br>",
        invoiceRow: [{
            itemDesc: "Item",
            price: "0.00",
            quantity: "0",
            amount: "0.00"
        }],
        tax: "0.00",
        taxActive: true,
        accountNumber: "00 123 647 840",
        accountName: "Jhon Doe",
        branchName: "xyz",
        term: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy has",
        footerMobile: "+00 123 647 840",
        footerEmail: "info@themevessel.com",
        footerAddress: "169 Grantham, England",
        InfoKey1: "Account No",
        InfoKey2: "Account Name",
        InfoKey3: "Branch Name",
        curIcon: "$",
    })

    const [total, setTotal] = useState({
        subTotal: 0,
        grandTotal: 0
    })
    const [show, setShow] = useState({
        add: false,
        remove: false,
        taxShow: false,
        index: -1
    })


    const handleChange = (val, name, index) => {
        if (index !== undefined) {

            let rowArr = [...state.invoiceRow]
            rowArr[index] = {
                ...rowArr[index],
                [name]: val,
            }
            if (name === "quantity" || name === "price") {
                rowArr[index] = {
                    ...rowArr[index],
                    amount: (parseFloat(rowArr[index].price) * +rowArr[index].quantity).toFixed(2),
                }
            }
            dispatch(onSetInvoiceRow(rowArr))
            setState({
                ...state,
                invoiceRow: rowArr
            })

        } else {
            setState({
                ...state,
                [name]: val
            })
            dispatch(onUpdateInvoiceData(name, val))
        }
    }


    const handleAdd = () => {
        let obj = {
            itemDesc: "Item",
            price: "0.00",
            quantity: "0",
            amount: "0.00"
        }
        setState({
            ...state,
            invoiceRow: [...state.invoiceRow, obj]
        })
    }

    const handleRemove = (index) => {
        let data = [...state.invoiceRow]
        data.splice(index, 1)
        setState({
            ...state,
            invoiceRow: data
        })

    }


    useEffect(() => {
        let subtotal = 0, grandtotal = 0

        state.invoiceRow.forEach((curElem) => {
            subtotal = subtotal + parseFloat(curElem.amount)
        })
        grandtotal = subtotal + parseFloat(state.tax)
        if(!state.taxActive){
            grandtotal = grandtotal - parseFloat(state.tax)
        }
        setTotal({
            ...total,
            subTotal: subtotal,
            grandTotal: grandtotal
        })

    }, [state, state.taxActive])

    const fetchData = () => {
        let data = {
            businessCardId: invoiceId
        }
        dispatch(onFecthCards(data, setLoader))
    }
    
    useEffect(() => {
      if (invoiceId) {
          fetchData()
      }
    }, [invoiceId])

    useEffect(() => {
    if(invoice.data === ""){
     dispatch(onSetInvoice(state))
    }else{
        setState(invoice.data)
    }
    },[invoice])

    return (
            <Invoice4style>
                <div className="invoice-2 invoice-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="invoice-inner clearfix">
                                    <div className="invoice-info clearfix" id="invoice_wrapper">
                                        <div className="invoice-headar">
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="invoice-logo">
                                                        <div className="logo">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="invoice-id">
                                                        <div className="info">
                                                            <h1 className="inv-header-1">Invoice</h1>
                                                            <p className="mb-1">Invoice Number:
                                                                <span>
                                                                    <ContentEditable
                                                                        html={state.invoiceNumber}
                                                                        onChange={(e) => handleChange(e.target.value, "invoiceNumber")}
                                                                        tagName="span"
                                                                    />
                                                                </span>
                                                            </p>
                                                            <p className="mb-0">Invoice Date: <span>
                                                                <ContentEditable
                                                                    html={state.invoiceData}
                                                                    onChange={(e) => handleChange(e.target.value, "invoiceData")}
                                                                    tagName="span"
                                                                />
                                                            </span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="invoice-top">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <div className="invoice-number mb-30">
                                                                    <h4 className="inv-title-1">Invoice To</h4>
                                                                    <h2 className="name">
                                                                        <ContentEditable
                                                                            html={state.toName}
                                                                            onChange={(e) => handleChange(e.target.value, "toName")}
                                                                            tagName="span"
                                                                        />
                                                                    </h2>
                                                                    <p className="invo-addr-1">
                                                                        <ContentEditable
                                                                            html={state.toAddress}
                                                                            onChange={(e) => handleChange(e.target.value, "toAddress")}
                                                                            tagName="span"
                                                                        />
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="invoice-number mb-30">
                                                                    <div className="invoice-number-inner">
                                                                        <h4 className="inv-title-1">Invoice From</h4>
                                                                        <h2 className="name">
                                                                            <ContentEditable
                                                                                html={state.fromName}
                                                                                onChange={(e) => handleChange(e.target.value, "fromName")}
                                                                                tagName="span"
                                                                            />
                                                                        </h2>
                                                                        <p className="invo-addr-1">
                                                                            <ContentEditable
                                                                                html={state.fromAddress}
                                                                                onChange={(e) => handleChange(e.target.value, "fromAddress")}
                                                                                tagName="span"
                                                                            />
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="invoice-center">
                                                        <div className="table-responsive">
                                                            <table className="table mb-0 table-striped invoice-table">
                                                                <thead
                                                                    className="bg-active"
                                                                    style={{ position: "relative" }}
                                                                    onMouseEnter={() => setShow({
                                                                        ...show,
                                                                        add: true,
                                                                        remove: false
                                                                    })}
                                                                    onMouseLeave={() => setShow({
                                                                        ...show,
                                                                        add: false
                                                                    })}
                                                                >
                                                                    <tr className="tr">
                                                                        <th>No.</th>
                                                                        <th className="pl0 text-start">Item Description</th>
                                                                        <th className="text-center">Price</th>
                                                                        <th className="text-center">Quantity</th>
                                                                        <th className="text-end">Amount
                                                                            {show.add ?
                                                                                <VscDiffAdded
                                                                                    style={{ position: "absolute", top: 17, right: 5, cursor: "pointer" }}
                                                                                    onClick={handleAdd}
                                                                                    title="Add"
                                                                                /> : ""}
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {state.invoiceRow.length > 0 ?
                                                                        state.invoiceRow.map((curElem, index) => {
                                                                            return (
                                                                                <>
                                                                                    <tr
                                                                                        className="tr"
                                                                                        key={index}
                                                                                        style={{ position: "relative" }}
                                                                                        onMouseEnter={() => setShow({
                                                                                            ...show,
                                                                                            remove: true,
                                                                                            index: index,
                                                                                            add: false
                                                                                        })}
                                                                                        onMouseLeave={() => setShow({
                                                                                            ...show,
                                                                                            remove: false,
                                                                                            index: -1
                                                                                        })}
                                                                                    >
                                                                                        <td>
                                                                                            <div className="item-desc-1">
                                                                                                <span>{index + 1}</span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td className="pl0">
                                                                                            <ContentEditable
                                                                                                html={curElem.itemDesc}
                                                                                                onChange={(e) => handleChange(e.target.value, "itemDesc", index)}
                                                                                                tagName="span"
                                                                                            />
                                                                                        </td>
                                                                                        <td className="text-center">
                                                                                            <ContentEditable
                                                                                                html={state.curIcon}
                                                                                                onChange={(e) => handleChange(e.target.value, "curIcon")}
                                                                                                tagName="span"
                                                                                            />
                                                                                            <ContentEditable
                                                                                                html={curElem.price}
                                                                                                onChange={(e) => handleChange(e.target.value, "price", index)}
                                                                                                tagName="span"
                                                                                            />
                                                                                        </td>
                                                                                        <td className="text-center">
                                                                                            <ContentEditable
                                                                                                html={curElem.quantity}
                                                                                                onChange={(e) => handleChange(e.target.value, "quantity", index)}
                                                                                                tagName="span"
                                                                                            />
                                                                                        </td>
                                                                                        <td className="text-end">
                                                                                            <ContentEditable
                                                                                                html={state.curIcon}
                                                                                                onChange={(e) => handleChange(e.target.value, "curIcon")}
                                                                                                tagName="span"
                                                                                            />
                                                                                            {curElem.amount}

                                                                                            {show.remove && index === show.index ?
                                                                                                <VscDiffRemoved
                                                                                                    style={{ position: "absolute", top: 15, right: 4, cursor: "pointer" }}
                                                                                                    onClick={() => handleRemove(index)}
                                                                                                    title="Remove"
                                                                                                /> : ""}
                                                                                        </td>

                                                                                    </tr>
                                                                                </>
                                                                            )
                                                                        })
                                                                        : ""}
                                                                    <tr className="tr2">
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td className="text-center">SubTotal</td>
                                                                        <td className="text-end">
                                                                            <ContentEditable
                                                                                html={state.curIcon}
                                                                                onChange={(e) => handleChange(e.target.value, "curIcon")}
                                                                                tagName="span"
                                                                            />
                                                                            {total.subTotal.toFixed(2)}</td>
                                                                    </tr>
                                                                    {state.taxActive ?
                                                                        <tr className="tr2" style={{ position: "relative" }}
                                                                            onMouseEnter={() => setShow({
                                                                                ...show,
                                                                                taxShow: true,
                                                                            })}
                                                                            onMouseLeave={() => setShow({
                                                                                ...show,
                                                                                taxShow: false
                                                                            })}
                                                                        >
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td className="text-center">Tax</td>
                                                                            <td className="text-end" >
                                                                                <ContentEditable
                                                                                    html={state.curIcon}
                                                                                    onChange={(e) => handleChange(e.target.value, "curIcon")}
                                                                                    tagName="span"
                                                                                />

                                                                                <ContentEditable
                                                                                    html={state.tax}
                                                                                    onChange={(e) => handleChange(e.target.value, "tax")}
                                                                                    tagName="span"
                                                                                />
                                                                                {show.taxShow ?
                                                                                    <IoCloseCircleOutline
                                                                                        style={{ position: "absolute", top: 14, right: 4, cursor: "pointer" }}
                                                                                        size={16}
                                                                                        onClick={() => handleChange(false, "taxActive")}
                                                                                        title="Remove Tex"
                                                                                    /> : ""}
                                                                            </td>
                                                                        </tr> : ""}
                                                                    <tr className="tr2">
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td className="text-center f-w-600 active-color">Grand Total
                                                                        </td>
                                                                        <td className="f-w-600 text-end active-color">
                                                                            <ContentEditable
                                                                                html={state.curIcon}
                                                                                onChange={(e) => handleChange(e.target.value, "curIcon")}
                                                                                tagName="span"
                                                                            />
                                                                            {parseFloat(total.grandTotal).toFixed(2)}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="invoice-bottom">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-5 col-sm-5">
                                                                <div className="payment-method mb-30">
                                                                    <h3 className="inv-title-1">Payment Method</h3>
                                                                    <ul className="payment-method-list-1 text-14">
                                                                        <li><strong>
                                                                            <ContentEditable
                                                                                html={state.InfoKey1}
                                                                                onChange={(e) => handleChange(e.target.value, "InfoKey1")}
                                                                                tagName="span"
                                                                            />
                                                                            :</strong>
                                                                            <ContentEditable
                                                                                html={state.accountNumber}
                                                                                onChange={(e) => handleChange(e.target.value, "accountNumber")}
                                                                                tagName="span"
                                                                            />

                                                                        </li>
                                                                        <li><strong>
                                                                            <ContentEditable
                                                                                html={state.InfoKey2}
                                                                                onChange={(e) => handleChange(e.target.value, "InfoKey2")}
                                                                                tagName="span"
                                                                            />

                                                                            :</strong>
                                                                            <ContentEditable
                                                                                html={state.accountName}
                                                                                onChange={(e) => handleChange(e.target.value, "accountName")}
                                                                                tagName="span"
                                                                            /> </li>
                                                                        <li><strong>
                                                                            <ContentEditable
                                                                                html={state.InfoKey3}
                                                                                onChange={(e) => handleChange(e.target.value, "InfoKey3")}
                                                                                tagName="span"
                                                                            />
                                                                            :</strong>
                                                                            <ContentEditable
                                                                                html={state.branchName}
                                                                                onChange={(e) => handleChange(e.target.value, "branchName")}
                                                                                tagName="span"
                                                                            />
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-7 col-sm-7">
                                                                <div className="terms-conditions mb-30">
                                                                    <h3 className="inv-title-1">Terms & Conditions</h3>
                                                                    <p>
                                                                        <ContentEditable
                                                                            html={state.term}
                                                                            onChange={(e) => handleChange(e.target.value, "term")}
                                                                            tagName="span"
                                                                        />
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="invoice-contact clearfix">
                                                        <div className="row g-0">
                                                            <div className="col-sm-12">
                                                                <div className="contact-info clearfix">
                                                                    <a href="tel:+55-4XX-634-7071" className="d-flex"><i
                                                                        className="fa fa-phone"></i>
                                                                        <ContentEditable
                                                                            html={state.footerMobile}
                                                                            onChange={(e) => handleChange(e.target.value, "footerMobile")}
                                                                            tagName="span"
                                                                        />
                                                                    </a>
                                                                    <a href="mailto:info@themevessel.com" className="d-flex"><i
                                                                        className="fa fa-envelope"></i>
                                                                        <ContentEditable
                                                                            html={state.footerEmail}
                                                                            onChange={(e) => handleChange(e.target.value, "footerEmail")}
                                                                            tagName="span"
                                                                        />
                                                                    </a>
                                                                    <a href="tel:info@themevessel.com"
                                                                        className="mr-0 d-flex d-none-580"><i
                                                                            className="fa fa-map-marker"></i>
                                                                        <ContentEditable
                                                                            html={state.footerAddress}
                                                                            onChange={(e) => handleChange(e.target.value, "footerAddress")}
                                                                            tagName="span"
                                                                        />
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <div className="btn-sec text-left" style={{position:"absolute",marginTop:"25px"}}>
           {/* <button className="demoLink" onClick={handleSave}>
          {loader ? <>Saving Invoice <i className="fa fa-spinner fa-spin mx-1" /></> : "Save Invoice"}
        </button> */}
    </div>
            </Invoice4style>
    )
}
export default Invoice_5;