import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { onFetch, onGetClient } from "../../Actions/ClientsAction";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import queryString from "query-string";
import Invoice_1 from "./T1/Invoice_1";
import Invoice_2 from "./T2/Invoice_2";
import Invoice_3 from "./T3/Invoice_3";
import Invoice_4 from "./T4/Invoice_4";
import Invoice_5 from "./T5/Invoice_5";
import Invoice_6 from "./T6/Invoice_6";
import Invoice_7 from "./T7/Invoice_7";
import Invoice_8 from "./T8/Invoice_8";
import { IoMdArrowRoundBack } from "react-icons/io"
import { onFecthCards, onInvoiceSave1, unmountInvoice } from "../../Actions/ResourceAction";

const InvoiceEditor = () => {
    const componentRef = useRef()
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const id = queryString.parse(location.search).id
    const aId = queryString.parse(location.search).aId
    const cId = queryString.parse(location.search).cId

    const clientData = useSelector(state => state.client.data)
    const invoice = useSelector(state => state.resource.invoice)

    const [loader, setLoader] = useState({
        pageLoader: true,
        submitLoader: false,
        saveLoader: false
    })

    const [isSave, setIsSave] = useState(false)

    const handleBackBtn = () => {
        if (aId && cId) {
            navigate(`/agency/create-client?id=${aId}&cId=${cId}`)
        } else {
            navigate(`/agency/create-client?id=${invoice.agencyId}&cId=${invoice.clientId}`)
        }
    }

    const handleDownload = () => {
        let elem = document.querySelector("#invoice-to-download")
        html2canvas(elem).then((canvas) => {
            let imgData = canvas.toDataURL("image/jpeg", 1);
            const doc = new jsPDF("p", "mm", "a4")
            let pageWidth = doc.internal.pageSize.getWidth();
            let pageHeight = doc.internal.pageSize.getHeight();
            let imageWidth = canvas.width;
            let imageHeight = canvas.height;
            var ratio = imageWidth / imageHeight >= pageWidth / pageHeight ? pageWidth / imageWidth : pageHeight / imageHeight;
            doc.addImage(imgData, 'JPEG', 0, 0, imageWidth * ratio, imageHeight * ratio);
            doc.save("invoice.pdf")
        })
    }


    const getClientData = () => {
        let data = {
            "id": cId,
            "agencyId": aId
        }
        setLoader({
            ...loader,
            pageLoader: true
        })
        dispatch(onGetClient(data, loader, setLoader))
    }

    const fetchData = () => {
        let data = {
            businessCardId: id
        }
        dispatch(onFecthCards(data, setLoader))
    }

    const handleSave = () => {
        setLoader({
            ...loader,
            saveLoader:true
        })
        let data = {
            businessCardId: id,
            data: JSON.stringify(invoice.data)
        }

        dispatch(onInvoiceSave1(data, setLoader,loader, navigate, aId, cId))

    }


    useEffect(() => {
        if (id) {
            fetchData()
        }
    }, [id])


    useEffect(() => {
        if (aId && cId) {
            getClientData()
        }
    }, [aId, cId])


    useEffect(() => {
        return () => {
            dispatch(unmountInvoice())
        }
    }, [])

    return (
        !id ?
            <div className="loader-sec">
                <div className="loader" />
            </div> :
            <>
                <div className="container">
                    <div className="business-wrap">
                        <div className="business-top">
                            <h2>Edit Invoice</h2>
                            <button className="demoLink" onClick={handleBackBtn}> <IoMdArrowRoundBack /> Go Back</button>
                        </div>

                        <div className="business-list">
                            <div className="proImage withDesc">
                                <div className="proImage-holder">
                                    <img src={clientData.image} alt="" />
                                </div>
                                <div className="proImage-txt">
                                    <p><strong>{clientData.name}</strong></p>
                                    <p>{clientData.email}</p>
                                    <p>{clientData.company}</p>
                                </div>
                            </div>
                        </div>

                        <div className="business-list" >
                            <div ref={componentRef} style={{ background: "#fff" }} id="invoice-to-download">
                                {
                                    +invoice.templateId - 20 === 1 ?
                                        <Invoice_1
                                        /> :
                                        +invoice.templateId - 20 === 2 ? <Invoice_2 /> :
                                            +invoice.templateId - 20 === 3 ? <Invoice_3 /> :
                                                +invoice.templateId - 20 === 4 ? <Invoice_4 /> :
                                                    +invoice.templateId - 20 === 5 ? <Invoice_5 /> :
                                                        +invoice.templateId - 20 === 6 ? <Invoice_6 /> :
                                                            +invoice.templateId - 20 === 7 ? <Invoice_7 /> :
                                                                +invoice.templateId - 20 === 8 ? <Invoice_8 /> :
                                                                <div className="loader-sec">
                                                                <div className="loader" />
                                                            </div>
                                }
                            </div>
                        </div>
                    
                            <div className="text-end">
                                <button onClick={handleDownload} className="demoLink mt-2">
                                    Download
                                </button>
                                <button className="demoLink" onClick={handleSave}>
                                    {loader.saveLoader ? <>Saving Invoice <i className="fa fa-spinner fa-spin mx-1" /></> : "Save Invoice"}
                                </button>
                            </div>

                       
                        {/* <ReactToPrint
                            trigger={() => <button>Print this out!</button>}
                            content={() => componentRef.current}
                        /> */}

                    </div>
                </div>
            </>
    )
}

export default InvoiceEditor