import React, { useState } from 'react'
import { RxCross2 } from 'react-icons/rx'
import { useDispatch } from 'react-redux';
import { onCancleBenefit, onChangeBenefit } from '../../Actions/ClientsAction';
import IconsModal from './Modals/IconsModal'
import defaultImage from "../../images/default_image.jpg"
import ImagesModal from './Modals/ImagesModal';
import Swal from 'sweetalert2';

const BenefitRows = ({ curElem, index, handleChange }) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleCancel = (index) => {
        Swal.fire({
            title: "Are You Sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it"
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(onCancleBenefit(index))
            }
        })
    }

    const handleAddIcon = (data, index) => {
        dispatch(onChangeBenefit('icon', data.url, index))
    }
    return (
        <>
            <div className="col-lg-4 mb-4">
                <div className="benefit-single">
                    <h6>Benefit {index + 1}</h6>
                    <div className="inpLabelWrap">
                        <span className="labelTxt">Heading</span>
                        <div className="inpLabel">
                            <label>Enter Benefit Heading</label>
                            <input
                                className="inpLabel-inp"
                                placeholder="Heading"
                                type="text"
                                name='heading'
                                value={curElem.heading}
                                onChange={(e) => handleChange(e, index)}
                            />
                        </div>
                    </div>
                    <div
                        className='image-delete-button'
                        style={{ top: -7, color: "#000000", cursor: "pointer" }}
                        onClick={() => handleCancel(index)}
                    >
                        <RxCross2 />
                    </div>
                    <div className="inpLabelWrap">
                        <span className="labelTxt">Choose Icon</span>
                        <div style={{ position: 'relative' }} onClick={handleShow}>
                            <span className='uploaded-img'><img src={curElem.icon !== "" ? curElem.icon : defaultImage} alt="" /></span>
                            <span className="chooseIcon  cursor-pointer">Choose Icon</span>
                        </div>


                    </div>
                    <div className="inpLabelWrap">
                        <span className="labelTxt">Description</span>
                        <div className="inpLabel">
                            <label htmlFor="description" style={{ width: "100%", borderTopLeftRadius: "10px", borderTopRightRadius: "10px", background: "#fff", borderTop: "1px solid #ffbebe", borderLeft: "1px solid #ffbebe", borderRight: "1px solid #ffbebe" }}>Enter Description</label>
                            <textarea
                                className="inpLabel-inp"
                                name="description"
                                placeholder='Enter Description'
                                type="text"
                                value={curElem.description}
                                onChange={(e) => handleChange(e, index)}
                            />
                        </div>
                    </div>
                </div>
                <ImagesModal
                    show={show}
                    handleClose={handleClose}
                    callbackFun={handleAddIcon}
                    iconIndex={index}
                />
                {/* <IconsModal
                    show={show}
                    handleClose={handleClose}
                    callbackFun={handleAddIcon}
                    iconIndex={index}
                /> */}
            </div>

        </>
    )
}

export default BenefitRows