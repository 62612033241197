import React, { useState } from 'react'
import { BsFillPlusCircleFill } from 'react-icons/bs'
import { FiUpload } from 'react-icons/fi'
import { RxCross2 } from 'react-icons/rx'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import { onUploadImage } from '../../Actions/AgencyAction'
import { onAddPara, onCancleParagraph, onChangeAbout, onChangeAboutImage } from '../../Actions/ClientsAction'
import defaultImage from "../../images/default_image.jpg"
import ImagesModal from './Modals/ImagesModal'

const AboutSection = () => {

    const dispatch = useDispatch()
    const landingData = useSelector(state => state.client.landing)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [loader, setLoader] = useState({
        imageLoader: false
    })

    const handleChange = (e, index) => {
        const { name, value } = e.target
        dispatch(onChangeAbout(name, value, index))
    }

    const addParagraph = () => {
        dispatch(onAddPara())
    }
    const handleCancel = (index) => {
        Swal.fire({
            title: "Are You Sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it"
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(onCancleParagraph(index))
            }
        })

    }

    const handleAddIcon = (data) => {
        dispatch(onChangeAboutImage(data.url))
    }


    return (
        <div>
            <div className="row">
                <div className="col-lg-6">
                    <div className="inpLabelWrap">
                        <span className="labelTxt">About Heading</span>
                        <div className="inpLabel">
                            <label htmlFor="heading">About Heading</label>
                            <input
                                className="inpLabel-inp"
                                placeholder="Enter Section Heading"
                                type="text"
                                name='heading'
                                value={landingData.about.heading}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="inpLabelWrap">
                        {/* <span className="labelTxt">Image</span> */}
                        <div className="inpLabel ">
                            <div className="inpLabelWrap">
                                <span className="labelTxt">Image</span>
                                <div className="inpLabel">
                                    <span onClick={handleShow} className="fullLink cursor-pointer"></span>
                                    <span className='uploaded-img'><img src={landingData.about.image !== "" ? landingData.about.image : defaultImage} alt="" /></span>
                                    <input
                                        className="inpLabel-inp"
                                        placeholder=""
                                        type="text"
                                    />
                                    <span className="inpBtn-icon">
                                        <FiUpload /> <span className="pl-1">Choose</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                {landingData.about.paragraphs.length > 0 ?
                    landingData.about.paragraphs.map((curElem, index) => {
                        return (
                            <div className="col-lg-6">
                                <div className="inpLabelWrap">
                                    <span className="labelTxt">Paragraph {index + 1}</span>
                                    <div className="inpLabel">
                                        <label style={{width : "100%", borderTopLeftRadius: "10px", borderTopRightRadius: "10px", background: "#fff", borderTop: "1px solid #ffbebe", borderLeft: "1px solid #ffbebe", borderRight: "1px solid #ffbebe" }}>Paragraph {index + 1}</label>
                                        <textarea
                                            className="inpLabel-inp"
                                            name="text"
                                            type="text"
                                            value={curElem.text}
                                            onChange={(e) => handleChange(e, index)}
                                            placeholder="Enter Paragraph Text"
                                        />
                                    </div>
                                    <div
                                        className='image-delete-button'
                                        style={{ top: 16, right: 12, color: "#ff6363", cursor: "pointer", fontSize: "14px" }}
                                        onClick={() => handleCancel(index)}
                                    >
                                        <RxCross2 />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    : ""}
            </div>

            <div className="modBox mt-3" style={{ cursor: 'pointer' }}>
                <div className="inpLabel mt-0 align-items-center d-flex justify-content-between">
                    <label>Add More Paragraph</label>
                    <span className="addIcon" onClick={addParagraph}><BsFillPlusCircleFill /></span>
                </div>
            </div>

            <ImagesModal
                show={show}
                handleClose={handleClose}
                callbackFun={handleAddIcon}
            />
        </div>
    )
}

export default AboutSection