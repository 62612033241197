import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import hexRgb from "hex-rgb";
import { SketchPicker } from "react-color";
import rgbHex from "rgb-hex";
import {
  updateFontFamily,
  removeFontBgColor,
  updateFontBgColor,
  updateFontColor,
  updateFontSize,
  updateTextAlign,
  updateTextBold,
  updateTextItalic, updateTextLineHeight,
  updateTextLower,
  updateTextStrike,
  updateTextUnderline,
  updateTextUpper,
  applyToAllSlide,
  textLayerUpdate,
  textLayerAdd,
  onFetchFont
} from '../../../../Actions/VideoAction';

const Text = ({ slideData }) => {
  const dispatch = useDispatch();

  const selectedSlide = slideData.find(({ isSelected }) => isSelected === "1")
  const selectedSlideIndex = slideData.findIndex(({ isSelected }) => isSelected === "1")

  const [selectedLayer, setSelectedLayer] = useState(false)
  const [selectedLayerIndex, setSelectedLayerIndex] = useState(-1)
  const [fonts, setFonts] = useState([])
  const [slideAll, setSlideAll] = useState(false);

  const [layerText, setLayerText] = useState("")
  const [textColor, setTextColor] = useState({
    enable: false,
    r: 0,
    g: 0,
    b: 0
  });
  const [showBgColor, setShowBgColor] = useState({
    enable: false,
    r: 0,
    g: 0,
    b: 0
  });

  const [styleState, setStyleState] = useState({
    fontColor: false,
    fontFamily: "",
    backgroundColor: "",
    fontSize: "36",
    textAlign: "center",
    fontWeight: false,
    fontStyle: false,
    textTransform: "lowercase",
    textDecoration: "none",
    lineHeight: 1,
    opacity: 1,
  })


  const fontNotSet = ["Helvetica", "Bebas", "palatino linotype", "Subjectivity", "LEMON MILK", "Gill Sans Ultra Bold", "Gilroy", "MS Reference Sans Serif", "MS Serif"
    , "Microsoft JhengHei", "Times New Roman", "MS UI Gothic", "Bookman Old Style", "Franklin Gothic Book", "Gill Sans MT", "Eras Light ITC", "Microsoft Sans Serif", "Microsoft YaHei UI", "Helvetica Now Display", "Gadugi", "Tw Cen MT"
    , "Ebrima", "Subjectivity Serif", "Clarendon", "Segoe UI Emoji", "Fashion Fetish Heavy", "Bastian Sidney", "Palatino Linotype", "Integral CF", "Steelfish", "Monument Extended", "Metropolis", "SignPainter HouseScript", "ChunkFive", "Franklin Gothic", "Agency FB", "Marchelina Script", "Franklin Gothic Medium", "Nexa Bold", "Spartwell", "Spartwell"
    , "Akira Expanded", "Calibri", "Zeyada", "Droid Serif", "Droid Sans Mono", "Droid Sans"]




  useEffect(() => {
    if (slideData) {
      let val = slideData[selectedSlideIndex].layers.find(({ isSelected }) => isSelected === true)
      let valIndex = slideData[selectedSlideIndex].layers.findIndex(({ isSelected }) => isSelected === true)
      setSelectedLayer(val)
      setSelectedLayerIndex(valIndex)
    }
  }, [slideData, selectedSlideIndex])

  useEffect(() => {
    if (selectedLayer) {
      if (selectedLayer.type === "text") {
        setLayerText(selectedLayer.text)
      }
    }
  }, [selectedLayer])


  useEffect(() => {
    if (selectedLayer !== false && selectedLayer !== undefined) {
      if (selectedLayer.isSelected === true) {
        setStyleState((selectedLayer.style))
      }
    }
  })


  useEffect(() => {
    if (styleState.fontColor !== false && styleState.fontColor !== "") {
      const val = hexRgb(styleState.fontColor)
      setTextColor({
        ...textColor,
        r: val.red,
        g: val.green,
        b: val.blue
      })
    }
    else {
      setTextColor({
        ...textColor,
        r: 0,
        g: 0,
        b: 0
      })
    }
  }, [styleState.fontColor])


  useEffect(() => {
    if (styleState.backgroundColor !== false && styleState.backgroundColor !== "") {
      setShowBgColor({
        ...showBgColor,
        r: styleState.backgroundColor.r,
        g: styleState.backgroundColor.g,
        b: styleState.backgroundColor.b
      })
    }
    else {
      setShowBgColor({
        ...showBgColor,
        r: 0,
        g: 0,
        b: 0
      })
    }
  }, [styleState.backgroundColor])

  const applyChanges = () => {
    if (slideAll === false) {
      setSlideAll(true);
      dispatch(applyToAllSlide(styleState));
    }
  }

  const onInputChange = (e, type) => {
    setSlideAll(false);
    if (type === 'size') {
      dispatch(updateFontSize(e.target.value, selectedSlideIndex, selectedLayerIndex));
    } else if (type === 'font_family') {
      dispatch(updateFontFamily(e.target.value, selectedSlideIndex, selectedLayerIndex));
    }

  }

  const onInputColor = (e) => {
    setSlideAll(false);
    let rgbaColor = e.hex
    setTextColor({
      ...textColor,
      r: e.rgb.r,
      g: e.rgb.g,
      b: e.rgb.b
    })
    dispatch(updateFontColor(rgbaColor, selectedSlideIndex, selectedLayerIndex));
  }

  const onInputBgColor = (e) => {
    let rgbaColor = e.rgb
    rgbaColor = `rgba(${e.rgb.r},  ${e.rgb.g}, ${e.rgb.b}, ${e.rgb.a})`
    setSlideAll(false);
    setShowBgColor({
      ...showBgColor,
      r: e.rgb.r,
      g: e.rgb.g,
      b: e.rgb.b
    })
    dispatch(updateFontBgColor(rgbaColor, selectedSlideIndex, selectedLayerIndex));
  }

  // Getting hex color RGB number 
  const getHexNumbers = (color, type) => {
    let rgbArray = color.split("(")
    rgbArray = rgbArray[1].split(",")
    let splitB = rgbArray[2].split(")")

    const r = rgbArray[0]
    const g = rgbArray[1]
    const b = splitB[0]
    if (type === 'TEXT') {
      setTextColor({
        ...textColor,
        r: r,
        g: g,
        b: b
      })
    }
    else {
      setShowBgColor({
        ...showBgColor,
        r: r,
        g: g,
        b: b
      })
    }
  }
  const onChangeColor = (color) => {
    setSlideAll(false);
    getHexNumbers(color, 'TEXT')
    let hex = '#' + rgbHex(color);
    dispatch(updateFontColor(hex, selectedSlideIndex, selectedLayerIndex));
  }

  const onChangeBgColor = (color) => {
    setSlideAll(false);
    getHexNumbers(color, 'BG')
    let hex = '#' + rgbHex(color);
    dispatch(updateFontBgColor(hex, selectedSlideIndex, selectedLayerIndex));
  }

  const removeBgColor = () => {
    setSlideAll(false);
    setShowBgColor({
      ...showBgColor,
      r: 0,
      g: 0,
      b: 0
    })
    dispatch(removeFontBgColor(selectedSlideIndex, selectedLayerIndex));
  }

  const onTextAlign = (align) => {
    setSlideAll(false);
    dispatch(updateTextAlign(align, selectedSlideIndex, selectedLayerIndex, selectedSlide.type));
  }

  const onChangeLineHeight = (e) => {
    setSlideAll(false);
    dispatch(updateTextLineHeight(e.target.value, selectedSlideIndex, selectedLayerIndex, selectedSlide.type))
  }

  const onTextStyle = (type) => {
    setSlideAll(false);
    if (type === 'bold') {
      if (styleState.fontWeight === false) {
        dispatch(updateTextBold("bold", selectedSlideIndex, selectedLayerIndex));
      } else {
        dispatch(updateTextBold(false, selectedSlideIndex, selectedLayerIndex));
      }
    } else if (type === 'italic') {
      if (styleState.fontStyle === false) {
        dispatch(updateTextItalic("italic", selectedSlideIndex, selectedLayerIndex));
      } else {
        dispatch(updateTextItalic(false, selectedSlideIndex, selectedLayerIndex));
      }
    } else if (type === 'uppercase') {
      if (styleState.textTransform === "uppercase") {
        dispatch(updateTextUpper("none", selectedSlideIndex, selectedLayerIndex));
      } else {
        dispatch(updateTextUpper("uppercase", selectedSlideIndex, selectedLayerIndex));
      }
    } else if (type === 'lowercase') {
      if (styleState.textTransform === "lowercase") {
        dispatch(updateTextLower("none", selectedSlideIndex, selectedLayerIndex));
      } else {
        dispatch(updateTextLower("lowercase", selectedSlideIndex, selectedLayerIndex));
      }
    } else if (type === 'underline') {
      if (styleState.textDecoration === "underline") {
        dispatch(updateTextUnderline("none", selectedSlideIndex, selectedLayerIndex));
      } else {
        dispatch(updateTextUnderline("underline", selectedSlideIndex, selectedLayerIndex));
      }
    } else if (type === 'strike') {
      if (styleState.textDecoration === "line-through") {
        dispatch(updateTextStrike("none", selectedSlideIndex, selectedLayerIndex));
      } else {
        dispatch(updateTextStrike("line-through", selectedSlideIndex, selectedLayerIndex));
      }
    }
  }


  useEffect(() => {
    dispatch(onFetchFont(setFonts, fontNotSet))
  }, [])


  const onLayerTextChange = (text) => {
    setLayerText(text)
    dispatch(textLayerUpdate(text, selectedSlideIndex, selectedLayerIndex))
  }
  const addTextLayer = () => {
    dispatch(textLayerAdd(selectedSlideIndex))
  }



  return (
    <div className="tabInner">
      <div className="textarea-block-wrap mb-4">
        <div className="textarea-block-head d-flex justify-content-between align-items-center">
          <h2>Text</h2>
          <button
            className='textarea-btn'
            onClick={addTextLayer}
          >
            Add New Text Layer
          </button>
        </div>
        {selectedLayer ?
          selectedLayer.type === "text" ?
            <div className="textarea-wrapper pt-3 ">
              <div class="mb-3">
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  value={layerText}
                  onChange={(e) => onLayerTextChange(e.target.value)}
                />
              </div>
            </div> : "" : ""}

      </div>
      {selectedLayer ?
        selectedLayer.type === "text" ?
          <div className="txt-format">
            <h2>Font Style</h2>
            <div className="fontStyle">
              <div className="fontStyle-block">
                <div className="row">
                  <div className="col-8">
                    <select
                      id="sel-font"
                      name="font_family"
                      className='cursor-pointer text-hover-effect'
                      onChange={(e) => onInputChange(e, 'font_family')}
                    >
                      <option value="" selected>Select Font</option>
                      {
                        fonts.length > 0 ?
                          fonts.map((font, index) => {
                            return (
                              <option style={{ fontFamily: font }}
                                value={font} key={index}>
                                {font}
                              </option>
                            )
                          })
                          : ''
                      }

                    </select>
                  </div>
                  <div className="col-4">
                    <input
                      id="f-size"
                      type="number"
                      className="form-control edit-input-font-size text-hover-effect"
                      name="f-size"
                      min="10"
                      value={styleState.fontSize}
                      onChange={(e) => onInputChange(e, 'size')}
                    />
                  </div>
                </div>
              </div>
              <div className="fontStyle-block">
                <div className="row">
                  <div className="col-2">
                    <div
                      className={`${styleState.fontWeight === "bold" ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect `}
                      onClick={() => onTextStyle('bold')}
                      data-toggle="tooltip"
                      title="Text Bold"
                    ><span className="font-weight-bold">T</span>
                    </div>

                  </div>
                  <div className="col-2">

                    <div
                      className={`${styleState.fontStyle === "italic" ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                      onClick={() => onTextStyle('italic')}
                      data-toggle="tooltip"
                      title="Text Italic"
                    ><span className="font-italic">T</span>
                    </div>

                  </div>
                  <div className="col-2">

                    <div
                      className={`${styleState.textTransform === "uppercase" ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                      onClick={() => onTextStyle('uppercase')}
                      data-toggle="tooltip"
                      title="Text Uppercase"
                    ><span>TT</span></div>
                  </div>

                  <div className="col-2">

                    <div
                      className={`${styleState.textTransform === "lowercase" ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                      onClick={() => onTextStyle('lowercase')}
                      data-toggle="tooltip"
                      title="Text Lowercase"
                    ><span>tt</span></div>
                  </div>
                  <div className="col-2">
                    <div
                      className={`${styleState.textDecoration === "underline" ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                      onClick={() => onTextStyle('underline')}
                      data-toggle="tooltip"
                      title="Text Underline"
                    ><span className="text-decoration-underline">T</span></div>
                  </div>
                  <div className="col-2">
                    <div
                      className={`${styleState.textDecoration === "line-through" ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                      onClick={() => onTextStyle('strike')}
                      data-toggle="tooltip"
                      title="Text Strike"
                    ><span className="text-decoration-line-through">T</span></div>
                  </div>
                </div>
              </div>
              <div className="fontStyle-block">
                <div className="row">
                  <div className="col-2">

                    <div
                      className={`${styleState.textAlign === 'left' ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                      onClick={() => onTextAlign('left')}
                      data-toggle="tooltip"
                      title="Text Left"
                    ><i className="fa fa-align-left" aria-hidden="true"></i></div>
                  </div>

                  <div className="col-2">

                    <div
                      className={`${styleState.textAlign === 'center' ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                      onClick={() => onTextAlign('center')}
                      data-toggle="tooltip"
                      title="Text Center"
                    ><i className="fa fa-align-center" aria-hidden="true"></i></div>
                  </div>

                  <div className="col-2">

                    <div
                      className={`${styleState.textAlign === 'right' ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                      onClick={() => onTextAlign('right')}
                      data-toggle="tooltip"
                      title="Text Right"
                    ><i className="fa fa-align-right" aria-hidden="true"></i></div>
                  </div>

                  <div className="col-2">

                    <div
                      className={`${styleState.textAlign === 'justify' ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                      onClick={() => onTextAlign('justify')}
                      data-toggle="tooltip"
                      title="Text Justify"
                    ><i className="fa fa-align-justify" aria-hidden="true"></i></div>
                  </div>

                  <div className="col-4">

                    <div
                      className={'form-format'}
                      data-toggle="tooltip"
                      title="Text Line Height"
                    >
                      <input
                        id="t-lineheight"
                        className="edit-input-lineheight text-hover-effect"
                        type="number"
                        name="Lineheight"
                        value={styleState.lineHeight}
                        min="1"
                        step="0.1"
                        onChange={(e) => onChangeLineHeight(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="fontStyle-block-wrap">
              <h2>Font Color</h2>
              <div className="fontStyle-block">
                <div className="row">
                  <div className="col-6">
                    <div className="inpWrap">
                      <input
                        className="pl-5"
                        type="text"
                        value={styleState.fontColor}
                        readOnly
                      />
                      <span
                        className="colorValue cursor-pointer"
                        id="t-color-btn"
                        style={{ background: `${styleState.fontColor !== false ? styleState.fontColor : '#fff'}` }}
                        onClick={() => setTextColor({
                          ...textColor,
                          enable: true
                        })}
                      ></span>
                      <span className="label">HEX</span>
                      {textColor.enable ?
                        <>
                          <div className="swatch-cover" onClick={(e) => setTextColor({
                            ...textColor,
                            enable: false
                          })} />
                          <SketchPicker color={styleState.fontColor} onChange={(e) => onInputColor(e)} />
                        </> : null}
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="inpWrap">
                      <input className="text-center" type="text" value={textColor.r} readOnly />
                      <span className="label">R</span>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="inpWrap">
                      <input className="text-center" type="text" value={textColor.g} readOnly />
                      <span className="label">G</span>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="inpWrap">
                      <input className="text-center" type="text" value={textColor.b} readOnly />
                      <span className="label">B</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="color-palette">
                <ul>
                  <li>
                    <div
                      style={{ background: "#FFF" }}
                      className="color-palette-single cursor-pointer"
                      onClick={() => onChangeColor("rgb(255, 255, 255)")}>
                    </div>
                  </li>

                  <li>
                    <div
                      style={{ background: "#E1E1E1" }}
                      className="color-palette-single cursor-pointer"
                      onClick={() => onChangeColor("rgb(225, 225, 225)")}>
                    </div>
                  </li>

                  <li>
                    <div
                      style={{ background: "#D7D7D7" }}
                      className="color-palette-single cursor-pointer"
                      onClick={() => onChangeColor("rgb(215, 215, 215)")}>
                    </div>
                  </li>

                  <li>
                    <div
                      style={{ background: "#CCCCCC" }}
                      className="color-palette-single cursor-pointer"
                      onClick={() => onChangeColor("rgb(204, 204, 204)")}>
                    </div>
                  </li>

                  <li>
                    <div
                      style={{ background: "#C2C2C2" }}
                      className="color-palette-single cursor-pointer"
                      onClick={() => onChangeColor("rgb(194, 194, 194)")}>
                    </div>
                  </li>

                  <li>
                    <div
                      style={{ background: "#B7B7B7" }}
                      className="color-palette-single cursor-pointer"
                      onClick={() => onChangeColor("rgb(183, 183, 183)")}>
                    </div>
                  </li>

                  <li>
                    <div
                      style={{ background: "#A1A1A1" }}
                      className="color-palette-single cursor-pointer"
                      onClick={() => onChangeColor("rgb(161, 161, 161)")}>
                    </div>
                  </li>

                  <li>
                    <div
                      style={{ background: "#959595" }}
                      className="color-palette-single cursor-pointer"
                      onClick={() => onChangeColor("rgb(149, 149, 149)")}>
                    </div>
                  </li>

                  <li>
                    <div
                      style={{ background: "#E8C9FF" }}
                      className="color-palette-single cursor-pointer"
                      onClick={() => onChangeColor("rgb(232, 201, 255)")}>
                    </div>
                  </li>

                  <li>
                    <div
                      style={{ background: "#C87EFF" }}
                      className="color-palette-single cursor-pointer"
                      onClick={() => onChangeColor("rgb(200, 126, 255)")}>
                    </div>
                  </li>

                  <li>
                    <div
                      style={{ background: "#F34AFF" }}
                      className="color-palette-single cursor-pointer"
                      onClick={() => onChangeColor("rgb(243, 74, 255)")}>
                    </div>
                  </li>

                  <li>
                    <div
                      style={{ background: "#D19DF8" }}
                      className="color-palette-single cursor-pointer"
                      onClick={() => onChangeColor("rgb(209, 157, 248)")}>
                    </div>
                  </li>

                  <li>
                    <div
                      style={{ background: "#9B33E9" }}
                      className="color-palette-single cursor-pointer"
                      onClick={() => onChangeColor("rgb(155, 51, 233)")}>
                    </div>
                  </li>

                  <li>
                    <div
                      style={{ background: "#8133DB" }}
                      className="color-palette-single cursor-pointer"
                      onClick={() => onChangeColor("rgb(129, 51, 219)")}>
                    </div>
                  </li>

                  <li>
                    <div
                      style={{ background: "#7033D5" }}
                      className="color-palette-single cursor-pointer"
                      onClick={() => onChangeColor("rgb(112, 51, 213)")}>
                    </div>
                  </li>

                  <li>
                    <div
                      style={{ background: "#23803F" }}
                      className="color-palette-single cursor-pointer"
                      onClick={() => onChangeColor("rgb(35, 128, 63)")}>
                    </div>
                  </li>

                  <li>
                    <div
                      style={{ background: "#54C423" }}
                      className="color-palette-single cursor-pointer"
                      onClick={() => onChangeColor("rgb(84, 196, 35)")}>
                    </div>
                  </li>

                  <li>
                    <div
                      style={{ background: "#606888" }}
                      className="color-palette-single cursor-pointer"
                      onClick={() => onChangeColor("rgb(96, 104, 136)")}>
                    </div>
                  </li>

                  <li>
                    <div
                      style={{ background: "#7C83AC" }}
                      className="color-palette-single cursor-pointer"
                      onClick={() => onChangeColor("rgb(124, 131, 172)")}>
                    </div>
                  </li>

                  <li>
                    <div
                      style={{ background: "#5B69BE" }}
                      className="color-palette-single cursor-pointer"
                      onClick={() => onChangeColor("rgb(91, 105, 190)")}>
                    </div>
                  </li>

                  <li>
                    <div
                      style={{ background: "#30409B" }}
                      className="color-palette-single cursor-pointer"
                      onClick={() => onChangeColor("rgb(48, 64, 155)")}>
                    </div>
                  </li>

                  <li>
                    <div
                      style={{ background: "#223CDB" }}
                      className="color-palette-single cursor-pointer"
                      onClick={() => onChangeColor("rgb(34, 60, 219)")}>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="fontStyle-block-wrap">
              <h2>Font Background</h2>
              <div className="fontStyle-block">
                <div className="row">
                  <div className="col-6">
                    <div className="inpWrap">
                      <input
                        className="pl-5"
                        type="text"
                        value={styleState.backgroundColor !== "" ? styleState.backgroundColor : ''}
                        readOnly
                      />
                      <span
                        className="colorValue cursor-pointer"
                        style={{ backgroundColor: `${styleState.backgroundColor !== "" ? styleState.backgroundColor : '#fff'}` }}
                        onClick={() => setShowBgColor({
                          ...showBgColor,
                          enable: true
                        })}
                      ></span>
                      {showBgColor.enable ?
                        <>
                          <div className="swatch-cover" onClick={(e) => setShowBgColor({
                            ...showBgColor,
                            enable: false
                          })}
                          />

                          <SketchPicker color={styleState.backgroundColor !== "" ? styleState.backgroundColor : '#fff'}
                            onChange={(e) => onInputBgColor(e)}
                          />
                        </> : null}
                      <span className="label">HEX</span>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="inpWrap">
                      <input className="text-center" type="text" value={showBgColor.r} />
                      <span className="label">R</span>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="inpWrap">
                      <input className="text-center" type="text" value={showBgColor.g} />
                      <span className="label">G</span>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="inpWrap">
                      <input className="text-center" type="text" value={showBgColor.b} />
                      <span className="label">B</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="color-palette">
                <ul>
                  <li style={{ position: 'relative', overflow: 'hidden' }}>
                    <div
                      style={{ background: "#FFF" }}
                      className="color-palette-single cursor-pointer no-color"
                      onClick={() => removeBgColor()}
                    >
                    </div>
                    <span className='Font-line'></span>
                  </li>


                  <li>
                    <div
                      style={{ background: "#E1E1E1" }}
                      className="color-palette-single cursor-pointer"
                      onClick={() => onChangeBgColor("rgb(225, 225, 225)")}
                    >
                    </div>
                  </li>


                  <li>
                    <div
                      style={{ background: "#D7D7D7" }}
                      className="color-palette-single cursor-pointer"
                      onClick={() => onChangeBgColor("rgb(215, 215, 215)")}
                    >
                    </div>
                  </li>


                  <li>
                    <div
                      style={{ background: "#CCCCCC" }}
                      className="color-palette-single cursor-pointer"
                      onClick={() => onChangeBgColor("rgb(204, 204, 204)")}
                    >
                    </div>
                  </li>


                  <li>
                    <div
                      style={{ background: "#C2C2C2" }}
                      className="color-palette-single cursor-pointer"
                      onClick={() => onChangeBgColor("rgb(194, 194, 194)")}
                    >
                    </div>
                  </li>


                  <li>
                    <div
                      style={{ background: "#B7B7B7" }}
                      className="color-palette-single cursor-pointer"
                      onClick={() => onChangeBgColor("rgb(183, 183, 183)")}
                    >
                    </div>
                  </li>


                  <li>
                    <div
                      style={{ background: "#A1A1A1" }}
                      className="color-palette-single cursor-pointer"
                      onClick={() => onChangeBgColor("rgb(161, 161, 161)")}
                    >
                    </div>
                  </li>


                  <li>
                    <div
                      style={{ background: "#959595" }}
                      className="color-palette-single cursor-pointer"
                      onClick={() => onChangeBgColor("rgb(149, 149, 149)")}
                    >
                    </div>
                  </li>


                  <li>
                    <div
                      style={{ background: "#E8C9FF" }}
                      className="color-palette-single cursor-pointer"
                      onClick={() => onChangeBgColor("rgb(232, 201, 255)")}
                    >
                    </div>
                  </li>


                  <li>
                    <div
                      style={{ background: "#C87EFF" }}
                      className="color-palette-single cursor-pointer"
                      onClick={() => onChangeBgColor("rgb(200, 126, 255)")}
                    >
                    </div>
                  </li>


                  <li>
                    <div
                      style={{ background: "#F34AFF" }}
                      className="color-palette-single cursor-pointer"
                      onClick={() => onChangeBgColor("rgb(243, 74, 255)")}
                    >
                    </div>
                  </li>


                  <li>
                    <div
                      style={{ background: "#D19DF8" }}
                      className="color-palette-single cursor-pointer"
                      onClick={() => onChangeBgColor("rgb(209, 157, 248)")}
                    >
                    </div>
                  </li>


                  <li>
                    <div
                      style={{ background: "#9B33E9" }}
                      className="color-palette-single cursor-pointer"
                      onClick={() => onChangeBgColor("rgb(155, 51, 233)")}
                    >
                    </div>
                  </li>


                  <li>
                    <div
                      style={{ background: "#8133DB" }}
                      className="color-palette-single cursor-pointer"
                      onClick={() => onChangeBgColor("rgb(129, 51, 219)")}
                    >
                    </div>
                  </li>


                  <li>
                    <div
                      style={{ background: "#7033D5" }}
                      className="color-palette-single cursor-pointer"
                      onClick={() => onChangeBgColor("rgb(112, 51, 213)")}
                    >
                    </div>
                  </li>


                  <li>
                    <div
                      style={{ background: "#23803F" }}
                      className="color-palette-single cursor-pointer"
                      onClick={() => onChangeBgColor("rgb(35, 128, 63)")}
                    >
                    </div>
                  </li>


                  <li>
                    <div
                      style={{ background: "#54C423" }}
                      className="color-palette-single cursor-pointer"
                      onClick={() => onChangeBgColor("rgb(84, 196, 35)")}
                    >
                    </div>
                  </li>


                  <li>
                    <div
                      style={{ background: "#606888" }}
                      className="color-palette-single cursor-pointer"
                      onClick={() => onChangeBgColor("rgb(96, 104, 136)")}
                    >
                    </div>
                  </li>


                  <li>
                    <div
                      style={{ background: "#7C83AC" }}
                      className="color-palette-single cursor-pointer"
                      onClick={() => onChangeBgColor("rgb(124, 131, 172)")}
                    >
                    </div>
                  </li>


                  <li>
                    <div
                      style={{ background: "#5B69BE" }}
                      className="color-palette-single cursor-pointer"
                      onClick={() => onChangeBgColor("rgb(91, 105, 190)")}
                    >
                    </div>
                  </li>


                  <li>
                    <div
                      style={{ background: "#30409B" }}
                      className="color-palette-single cursor-pointer"
                      onClick={() => onChangeBgColor("rgb(48, 64, 155)")}
                    >
                    </div>
                  </li>


                  <li>
                    <div
                      style={{ background: "#223CDB" }}
                      className="color-palette-single cursor-pointer"
                      onClick={() => onChangeBgColor("rgb(34, 60, 219)")}
                    >
                    </div>
                  </li>

                </ul>
              </div>
            </div>

            <div className="fontStyle-block-wrap">
              <div className="itemDisp">
                <div className="itemDisp-left">
                  Apply to all slides
                </div>
                <div className="itemDisp-right">
                  <div className="switch-single">
                    <label class="switch">
                      <input
                        type="checkbox"
                        checked={slideAll}
                        disabled={slideAll}
                        onChange={(e) => applyChanges()}
                      />
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

          </div> : "" : ""}
    </div>
  );
};

export default Text;
