import React, { useState, useEffect } from "react";
import ContentEditable from "react-contenteditable";
import { Invoice1style } from "./style";
import { VscDiffAdded, VscDiffRemoved } from "react-icons/vsc";
import { IoCloseCircleOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { onSetInvoice, onSetInvoiceRow, onSetInvoiceTable, onSetInvoiceTableData, onUpdateInvoiceData } from "../../../Actions/ResourceAction";
import { onFecthCards } from "../../../Actions/ResourceAction";

const Invoice_2 = () => {

  const invoice = useSelector(state => state.resource.invoice)
  const dispatch = useDispatch();
  const location = useLocation()
  const invoiceId = queryString.parse(location.search).id
  const aId = queryString.parse(location.search).aId
  const cId = queryString.parse(location.search).cId

  const [loader, setLoader] = useState(false)
  const [dataVal, setDataVal] = useState(false)
  const [state, setState] = useState({
    address1: " Arboshiki",
    address2: "455 Foggy Heights, AZ 85004, US",
    address3: "796 Silver Harbour, TX 79273, US",
    name: "John Doe",
    date1: "Date of Invoice: 01/10/2018",
    date2: "Due Date: 30/10/2018",
    phoneNo: "123-456-789",
    heading: "INVOICE 3-2-1",
    notice: "NOTICE:",
    footer1:
      "A finance charge of 1.5% will be made on unpaid balances after 30 days.",
    footer2:
      "Invoice was created on a computer and is valid without the signature and seal.",
    thankYou: "Thank You",
    tax: "0.00",
    taxPercent: "0%",
    taxActive: true,
    currency: "$",
  });

  const [table, setTable] = useState([
    {
      heading: "Youtube Channel",
      description:
        "Useful videosto improve your Javascript skills. Subscribe and stay tuned",
      unit: "100",
      quantity: "1",
      total: "100",
    },
  ]);

  const [total, setTotal] = useState({
    subTotal: 0,
    grandTotal: 0,
  });

  const [btn, setBtn] = useState({
    add: false,
    minus: null,
    taxShow: false,
  });

  const handleChange = (e, type) => {
    const { value } = e.target;
    setState({
      ...state,
      [type]: value,
    });
    dispatch(onUpdateInvoiceData(type, value,))

  };

  const handleTable = (e, index, type) => {
    // let index = 0;
    const { value } = e.target;
    if (index !== undefined) {
      let newData = [...table];
      // newData[index][type] = value;
      newData[index] = {
        ...newData[index],
        [type]: value,
    }

      if (type === "quantity" || type === "unit") {
        newData[index] = {
          ...newData[index],
          total: (
            parseFloat(newData[index].unit) * +newData[index].quantity
          ).toFixed(2)
        };
      }

      setTable(newData);
      dispatch(onSetInvoiceTable(newData));
    }
  };


  const handleAddrow = () => {
    setTable([
      ...table,
      {
        sNo: "01",
        heading: "Youtube Channel",
        description:
          "Useful videosto improve your Javascript skills. Subscribe and stay tuned",
        unit: "100",
        quantity: "1",
        total: "100",
      },
    ]);
  };

  const handleRemoveRow = (ind) => {
    const updateRow = table.filter((item, id) => {
      return ind !== id;
    });
    setTable(updateRow);
  };

  const addMouseHover = (id) => {
    setBtn({
      ...btn,
      add: true,
      minus: false,
    });
  };

  const addMouseLeave = () => {
    setBtn({
      ...btn,
      add: false,
      minus: false,
    });
  };

  const removeMouseHover = (id) => {
    setBtn({
      ...btn,
      add: false,
      minus: id,
    });
  };

  const removeMouseLeave = () => {
    setBtn({
      ...btn,
      add: false,
      minus: null,
    });
  };

  const handleTax = (val, type) => {
    setState({
      ...state,
      [type]: val,
    });
  };

  useEffect(() => {
    let grandtotal = 0
    let subtotal = 0;
    table.forEach((curElem) => {
      subtotal = subtotal + parseFloat(curElem.total);
    });
    grandtotal = subtotal + parseFloat(state.tax);
    if (!state.taxActive) {
      grandtotal = grandtotal - parseFloat(state.tax);
    }

    setTotal({
      subTotal: subtotal,
      grandTotal: grandtotal,
    });
  }, [table, state]);

  const fetchData = () => {
    let data = {
      businessCardId: invoiceId
    }
    dispatch(onFecthCards(data, setLoader, setDataVal))
  }

  useEffect(() => {
    if (invoiceId) {
      fetchData()
    }
  }, [invoiceId])

  useEffect(() => {
    if (invoice.data === "") {
      dispatch(onSetInvoice({ ...state, table }))
    } else {
      setState(invoice.data)
      setTable(invoice.data.table)
    }
  }, [invoice])


  return (
    <Invoice1style>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div id="invoice">
              <div className="invoice_bg">
                <div className="row">
                  <div className="col-lg-12 text-right">
                    <h1 className="heading">INVOICE</h1>
                  </div>
                </div>
              </div>

              <div className="invoice overflow-auto">
                <div style={{ minWidth: "600px" }}>
                  <header>
                    <div className="row">
                      <div className="col">
                        <a href="#">
                          {/* <img src="assets/images/logo-icon.png" width="80" alt="" /> */}
                        </a>
                      </div>
                      <div className="col company-details">
                        <h2 className="name">
                          <a target="_blank">
                            <ContentEditable
                              html={state.address1}
                              tagName="address1"
                              onChange={(e) => handleChange(e, "address1")}
                            />
                          </a>
                        </h2>
                        <div>
                          <ContentEditable
                            html={state.address2}
                            tagName="address2"
                            onChange={(e) => handleChange(e, "address2")}
                          />
                        </div>
                        <div>
                          <ContentEditable
                            html={state.phoneNo}
                            tagName="phoneNo"
                            onChange={(e) => handleChange(e, "phoneNo")}
                          />
                        </div>
                      </div>
                    </div>
                  </header>
                  <div className="container">
                    <div className="col-lg-12">
                      <main>
                        <div className="row contacts">
                          <div className="col invoice-to">
                            <div className="text-gray-light">INVOICE TO:</div>
                            <h2 className="to">
                              <ContentEditable
                                html={state.name}
                                tagName="name"
                                onChange={(e) => handleChange(e, "name")}
                              />
                            </h2>
                            <div className="address">
                              <ContentEditable
                                html={state.address3}
                                tagName="address3"
                                onChange={(e) => handleChange(e, "address3")}
                              />
                            </div>
                          </div>
                          <div className="col invoice-details">
                            <h1 className="invoice-id">
                              <ContentEditable
                                html={state.heading}
                                tagName="heading"
                                onChange={(e) => handleChange(e, "heading")}
                              />
                            </h1>
                            <div className="date">
                              <ContentEditable
                                html={state.date1}
                                tagName="date1"
                                onChange={(e) => handleChange(e, "date1")}
                              />
                            </div>
                            <div className="date">
                              <ContentEditable
                                html={state.date2}
                                tagName="date2"
                                onChange={(e) => handleChange(e, "date2")}
                              />
                            </div>
                          </div>
                        </div>
                        <table>
                          <thead
                            onMouseOver={addMouseHover}
                            onMouseLeave={addMouseLeave}
                          >
                            <tr>
                              <th>#</th>
                              <th className="text-left">DESCRIPTION</th>
                              <th className="text-right">HOUR PRICE</th>
                              <th className="text-right">HOURS</th>
                              <th className="text-right">TOTAL</th>
                              <th className="text-right">
                                {btn.add ? (
                                  <VscDiffAdded
                                    style={{
                                      position: "absolute",
                                      cursor: "pointer",
                                      margin: "0px",
                                    }}
                                    onClick={handleAddrow}
                                  />
                                ) : (
                                  ""
                                )}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {table.length > 0 ?
                              table.map((elem, index) => {
                                return (
                                  <>
                                    <tr
                                      key={index}
                                      onMouseOver={() => removeMouseHover(index)}
                                      onMouseLeave={() => removeMouseLeave(index)}
                                    >
                                      <td className="no">{index + 1}</td>
                                      <td className="text-left">
                                        <h3>
                                          <ContentEditable
                                            html={elem.heading}
                                            onChange={(e) =>
                                              handleTable(e, index, "heading")
                                            }
                                          />
                                        </h3>

                                        <ContentEditable
                                          html={elem.description}
                                          onChange={(e) =>
                                            handleTable(e, index, "description")
                                          }
                                        />
                                      </td>
                                      <td className="unit">
                                        <ContentEditable
                                          html={state.currency}
                                          tagName="span"
                                          onChange={(e) =>
                                            handleChange(e, "currency")
                                          }
                                        />
                                        <ContentEditable
                                          html={elem.unit}
                                          tagName="span"
                                          onChange={(e) =>
                                            handleTable(e, index, "unit")
                                          }
                                        />
                                      </td>
                                      <td className="qty">
                                        <ContentEditable
                                          html={elem.quantity}
                                          tagName="span"
                                          onChange={(e) =>
                                            handleTable(e, index, "quantity")
                                          }
                                        />
                                        HRS
                                      </td>
                                      <td className="total">
                                        <ContentEditable
                                          html={state.currency}
                                          tagName="span"
                                          onChange={(e) =>
                                            handleChange(e, "currency")
                                          }
                                        />
                                        {elem.total}
                                      </td>
                                      <td className="text-end">
                                        {btn.minus === index ? (
                                          <VscDiffRemoved
                                            style={{
                                              position: "absolute",
                                              cursor: "pointer",
                                              margin: "0px",
                                            }}
                                            onClick={() => handleRemoveRow(index)}
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </td>
                                    </tr>
                                  </>
                                );
                              }) : ""}


                          </tbody>
                          <tfoot>
                            <tr>
                              <td colSpan={2}></td>
                              <td colSpan={2}>SUBTOTAL</td>
                              <td>
                                <ContentEditable
                                  html={state.currency}
                                  tagName="span"
                                  onChange={(e) => handleChange(e, "currency")}
                                />
                                {total.subTotal.toFixed(2)}
                              </td>
                            </tr>
                            {state.taxActive ? (
                              <tr
                                onMouseEnter={() =>
                                  setBtn({
                                    ...btn,
                                    taxShow: true,
                                  })
                                }
                                onMouseLeave={() =>
                                  setBtn({
                                    ...btn,
                                    taxShow: false,
                                  })
                                }
                              >
                                <td colSpan={2}></td>
                                <td colSpan={2}>
                                  TAX (
                                  <ContentEditable
                                    html={state.taxPercent}
                                    tagName="taxPercent"
                                    onChange={(e) =>
                                      handleChange(e, "taxPercent")
                                    }
                                  />
                                  )
                                </td>
                                <td>
                                  <ContentEditable
                                    html={state.currency}
                                    tagName="span"
                                    onChange={(e) =>
                                      handleChange(e, "currency")
                                    }
                                  />
                                  <ContentEditable
                                    html={state.tax}
                                    tagName="tax"
                                    onChange={(e) => handleChange(e, "tax")}
                                  />
                                  {btn.taxShow ? (
                                    <IoCloseCircleOutline
                                      style={{
                                        position: "absolute",
                                        cursor: "pointer",
                                        marginLeft: "10px",
                                      }}
                                      size={16}
                                      onClick={() =>
                                        handleTax(false, "taxActive")
                                      }
                                      title="Remove Tex"
                                    />
                                  ) : (
                                    ""
                                  )}
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                            <tr>
                              <td colSpan={2}></td>
                              <td colSpan={2}>GRAND TOTAL</td>
                              <td>
                                <ContentEditable
                                  html={state.currency}
                                  tagName="span"
                                  onChange={(e) => handleChange(e, "currency")}
                                />
                                {total.grandTotal.toFixed(2)}
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                        <div className="thanks">
                          <ContentEditable
                            html={state.thankYou}
                            tagName="thankYou"
                            onChange={(e) => handleChange(e, "thankYou")}
                          />
                        </div>
                        <div className="notices">
                          <div>
                            NOTICE:
                            <ContentEditable
                              html={state.notice}
                              tagName="notice"
                              onChange={(e) => handleChange(e, "notice")}
                            />
                          </div>
                          <div className="notice">
                            <ContentEditable
                              html={state.footer1}
                              tagName="footer1"
                              onChange={(e) => handleChange(e, "footer1")}
                            />
                          </div>
                        </div>
                      </main>
                    </div>
                  </div>
                  <footer>
                    <ContentEditable
                      html={state.footer2}
                      tagName="footer2"
                      onChange={(e) => handleChange(e, "footer2")}
                    />
                  </footer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </Invoice1style>
  );
};
export default Invoice_2;
